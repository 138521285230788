import React, { Component } from 'react';
import config from '../config';

class ShopHeader extends Component {
	
	constructor(props){
		super(props);

		this.state = {
			company: undefined
		}
	}

	componentDidMount(){
		$('.back-to-top').trigger('click');
	}

    componentWillReceiveProps(nextProps){
    	this.setState({
    		company: nextProps.company
    	})
    }

	getHeaderStyle(color1, color2){
		return {
			backgroundImage: ['linear-gradient(to right, ', color1, ', ', color2, ')'].join('')
		}
	}

	getImageUrl(id, options){
		return [config.cdnUrl, 'img/', id, options].join('')
	}

	renderSocialLink(link){
		let socialRegexes = [{ name: 'facebook', regex: /facebook/i }, 
		{ name: 'instagram', regex: /instagram/i }, 
		{ name: 'google', regex: /google/i }, 
		{ name: 'pinterist', regex: /pinterist/i }, 
		{ name: 'twitter', regex: /twitter/i }, 
		{ name: 'behance', regex: /behance/i }, 
		{ name: 'vk', regex: /vk/i }, 
		{ name: 'youtube', regex: /youtube/i }, 
		{ name: 'disqus', regex: /disqus/i }, 
		{ name: 'yahoo', regex: /yahoo/i }, 
		{ name: 'vimeo', regex: /vimeo/i }, 
		{ name: 'blogger', regex: /blogger/i }, 
		{ name: 'linkedin', regex: /linkedin/i }, 
		{ name: 'flicker', regex: /flicker/i }]

		for(var i = 0; i < socialRegexes.length; i++)
			if(link.match(socialRegexes[i].regex))
				return (
					<a href={link} target="_blank" className={socialRegexes[i].name}>
						<i className={`zmdi zmdi-${socialRegexes[i].name}`}></i>
					</a>
				)

	}

	renderCoverPhoto(coverPhoto){
		return !coverPhoto ? null : (
			<div className="cover-photo">
	            <img src={this.getImageUrl(coverPhoto, '?h=350')} alt={coverPhoto}/>
	        </div>
		) 
	}

	renderSocial(company){
		if(!(company.social && company.social.length) || !(company.web && company.web.length))
			return null;

		let validAddress = address => {
			if(address.indexOf('http') == 0 || address.indexOf('//') == 0)
				return address
			else '//' + address
		}
		
		return (
			<div className="shop-social">
        		{
        			company.social && company.social.length ? 
        			this.renderSocialLink(company.social)  : null
        		}
                {
                	company.web && company.web.length ? 
                	<a href={validAddress(company.web)} target="_blank">
                		<i className="zmdi zmdi-open-in-new"></i>
                	</a> : 
                	null
                }
        	</div>
		)
	}

    render(){
    	let {company} = this.state;

    	if(!company) return null;

    	return (<div className = "shop-header">
                    { this.renderCoverPhoto(company.coverPhoto) }
			        <div className="shop-info" style={this.getHeaderStyle(company.color1, company.color2)}>
			            <div className="row">
			                <div className="col-xs-12 col-sm-6 col-md-3">
			                	<div className="profile-photo">
			                		<img src={this.getImageUrl(company.profilePhoto, '?w=180')} 
			                			alt={company.profilePhoto}/>
			                	</div>
			                </div>			                
			                <div className="col-xs-12 col-sm-6 col-md-3">
			                    <h3>{company.companyName}</h3>
			                    <h5>{company.shortDesc}</h5>
			                    <h5>{company.phone}</h5>
			                </div>
			                <div className="col-xs-12 col-sm-12 col-md-6">
			                	{ this.renderSocial(company) }
			                    <div dangerouslySetInnerHTML={{__html: company.companyDesc || '<p></p>'}}></div>
			                </div>
			            </div>
			        </div>
                 </div>)
    }
}

export default ShopHeader;
