import React, { Component, PropTypes } from 'react';
import toastr from 'toastr';
import {Link} from 'react-router';
import Rating from 'react-rating';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';

import { formatCurrency } from '../helpers';
import config from '../config';

class Product extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            isSaved: props.product.isSaved
        }
    }

    /**
    * Барааг хадгалах
    */
    handleSave(){
        if(this.context.isLogged){
            this.props.saveProduct(this.props.product.prodVarId, !this.state.isSaved, ((result) => {
                this.setState({
                    isSaved : result
                });
                this.props.onUnsave && this.props.onUnsave(this.props.product.prodVarId, result);
            }).bind(this));
        } else {
            toastr.error('Нэвтэрсэн хэрэглэгч бүтээгдэхүүнийг хадгалах боломжтой!');
        }
    }

    shouldComponentUpdate(nextProps, nextState){
        return this.props.product.prodVarId != nextProps.product.prodVarId
            || this.state.isSaved != nextState.isSaved;
    }

    /**
    * Сагсанд барааг нэмэх
    */
    handleAddToCart(event) {
        event.preventDefault();
        this.props.addCartItem(this.props.product.prodVarId, 1);
    }

    handleLoadProduct(id) {
        this.props.viewProduct(id, true);
    }

    renderBadge(product){
        return <div className="product-labels">
                {product.disPercent && product.disPercent > 0 ? <div className="product-label product-label-sale"><span>{formatCurrency(product.disPercent, 0)}</span>{`%`}</div> : null}
                {product.bfield && product.bfield.toUpperCase() === 'BDISCOUNT' ? <div className="product-label product-label-bdiscount">Discount</div> : null}
                {product.bfield && product.bfield.toUpperCase() === 'BCOUPON' ? <div className="product-label product-label-bcoupon">Coupon</div> : null}                
              </div>
    }

    renderSizes(product) {
      if(!product.sizeList || (product.sizeList && product.sizeList.length == 1 && (product.sizeList[0].text === 'ширхэг' || !product.sizeList[0].text.trim().length)))
        return null;
      return <div className="product-options"><strong>ХЭМЖЭЭ</strong><div>{(product.sizeList || []).map((size) => <span key={size.id}>{size.text}</span>)}</div></div>
    }

    renderColors(product) {
      if(!product.colors  || !product.colors.length)
        return null;
      return <div className="colors">
                {
                  product.colors.split(';').map(color => <div key={color}><span style={{backgroundColor: color}}></span></div>)
                }
             </div>
    }

    render(){
        let product = this.props.product,
            imgURL = [config.cdnUrl, 'img/', product.photoPath, '?w=262'].join(''),
            disPrice;

        if(!product.photoPath){
            imgURL = [config.baseUrl, '/images/empty.jpg'].join('')
        }

        if(product.disPrice && product.disPrice > 0) {
          disPrice = <del>{formatCurrency(product.price) + '₮'}</del>
        }

        let { savePrev } = this.props;
        if(savePrev === undefined) savePrev = true;

        return (
            <div className="product">
                <div className="product-front">
                    <img src={imgURL} alt={product.name} />
                    <Link to={{pathname: `${config.context}product/${product.prodVarId}/${encodeURIComponent(product.name.replace(/\//g, '-'))}`}}
                        className="product-overlay">
                        { this.renderBadge(product) }
                    </Link>
                    <Link to={{pathname: `${config.context}product/${product.prodVarId}/${encodeURIComponent(product.name.replace(/\//g, '-'))}`}}
                        className="product-stats">
                        {
                          false && this.renderColors(product)
                        }
                        <span className="product-name">{product.name}</span>
                        <span className="product-rating">
                            <Rating initialRate={parseInt(product['rate']) || 0}
                                    readonly={true} 
                                    step={1}
                                    max={5}
                                    fractions={2}
                                    empty="zmdi zmdi-star"
                                    placeholder="zmdi zmdi-star rated"
                                    full="zmdi zmdi-star rated" />
                        </span>
                        <span className="product-price">
                            { disPrice }
                            {`${product.disPrice && product.disPrice > 0 ? formatCurrency(product.disPrice) : formatCurrency(product.price)}₮`}
                        </span>
                    </Link>
                    <div className="product-actions">
                        <OverlayTrigger
                            overlay={<Tooltip id="add-cart">{`Сагсанд нэмэх`}</Tooltip>} placement="top" delayShow={300}>
                            <button className="btn btn-default product-action" onClick={this.handleAddToCart.bind(this)}>
                                <i className="zmdi zmdi-shopping-cart"></i>
                            </button>
                        </OverlayTrigger>                      
                        <OverlayTrigger
                            overlay={<Tooltip id="quick-view">{`Товч мэдээлэл харах`}</Tooltip>} placement="top" delayShow={300}>
                            <button className="btn btn-default product-action" onClick={this.handleLoadProduct.bind(this, product.prodVarId)}>
                                <i className="zmdi zmdi-eye"></i>
                            </button>
                        </OverlayTrigger> 
                        <OverlayTrigger
                            overlay={<Tooltip id="add-favorite">{`Миний дуртайд нэмэх`}</Tooltip>} placement="top" delayShow={300}>
                            <button className="btn btn-default product-action" onClick={this.handleSave.bind(this)} style={{'padding': '6px 0'}}>
                                <i className={'heart' + (this.state.isSaved ? ' hearted' : '')}></i>
                            </button>
                        </OverlayTrigger>
                    </div> 
                </div>
           </div>
        )
    }
}

Product.contextTypes = {
    isLogged: PropTypes.bool.isRequired,
    id: PropTypes.string
}

export default Product;
