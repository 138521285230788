import React, { Component } from 'react';
import moment from 'moment';
import Helmet from 'react-helmet';
import { Link } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ReactPlayer from 'react-player'
import Slider from 'react-slick';

import * as contentActions from '../actions/bcontent';
import * as bannerActions from '../actions/banner';
import {saveProduct, quickViewProduct} from '../actions/product'; 
import {showCart, addCartItem} from '../actions/cart';
import config from '../config';

import ProductReview from '../components/ProductReview';
import Preloader from '../components/Preloader';
import Product from '../components/Product';

class ContentContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            content: undefined,
        }
    }

    componentWillReceiveProps(nextProps){
        this.setState({content : nextProps.content});
    }

    componentWillMount() {
        this.loadContent(this.props.params.id)
        this.props.loadBanners('BABY-B1');
    }

    componentWillUpdate(nextProps){
        if(this.props.params.id && nextProps.params.id && this.props.params.id !== nextProps.params.id)
        {
            this.setState({
                content: undefined
            })
            this.loadContent(nextProps.params.id)
        }
    }

    loadContent(id){
        this.props.loadContent(id);  
        this.props.loadSideContents(id);
    }

    getImageURL(contentItem){
        return contentItem ? [config.cdnUrl, 'img/', contentItem].join('') : [config.baseUrl, '/images/empty.jpg'].join('')
    }

    /**
    * Их үнэлгээтэй, Их сэтгэгдэлтэй, төстэй контентийн жагсаалтыг харуулах
    */
    renderNavList(headerText, contents){
        if(!contents.length) return null

        return (
            <div className="content-nav-list">
                <h2>{headerText}</h2>
                <ul>
                    {
                        contents.map(content => (
                                <li key={content.bcontentId}>
                                    <Link to={{pathname: `${config.context}bcontent/${content.bcontentId}/${encodeURIComponent(content.bcontentTitle.replace(/\//g, '-'))}`}}
                                        className="clearfix">
                                        <figure>
                                            <img src={this.getImageURL(content.contentItem)} alt={content.bcontentTitle}/>
                                        </figure>
                                        <h3>{content.bcontentTitle}</h3>
                                        <span>{content.createdDate ? moment(content.createdDate).format('YYYY/MM/DD'): null}</span>
                                    </Link>
                                </li>   
                            )
                        )
                    }
                </ul>
            </div>
        );
    }

    /**
    * Banner-уудыг харуулах
    */
    renderBanners(){
        return (
            <div className="banner-b1"> 
                {
                    this.props.banners.map(banner => (
                            <a href={banner.bannerUrl && banner.bannerUrl.length ? banner.bannerUrl : 'javascript:;'} key={banner.bannerId}>
                                <img src={`${config.cdnUrl}img/${banner.fileId}`}/>
                            </a>  
                        )
                    )
                }
            </div>
        );
    }

    /**
    * Хамааралтай бүтээгдэхүүнүүдийг харуулах
    */
    renderCarouselItems(products){
        return products.map(item => (
            <div className="col-xs-1 col-sm-2 col-md-3" key={item.id}>
                <Product 
                    product={item} 
                    addCartItem={this.props.addCartItem}
                    saveProduct={this.props.saveProduct}
                    viewProduct={this.props.quickViewProduct}
                    returnTo={this.props.location.pathname} />
            </div>
        ))

    }

    renderContent(content){
        let src = [config.cdnUrl, 'img/', content.contentItem].join('');
        if(content.contentType === 'VIDEO'){
            src = [config.cdnUrl, 'v/', content.contentItem].join('');
            return (
                <ReactPlayer url={src} playing={true} width='100%' height='480px'/>
            )
        }

        return <img src={src} alt={content.contentTitle}/>
    }

    render() {
        let content = this.state.content;

        if(!content || content === {} )
            return <Preloader/>;

        let mostRated, mostCommented, same, {sideContents} = this.props;

        mostRated = sideContents[0] || [];
        mostCommented = sideContents[1] || [];
        same = sideContents[2] || [];

        let options = {
            arrows: true,
            dots: false,
            infinite: false,
            slidesToShow: 3,
            slidesToScroll: 1,
            className: 'product-carousel',
            responsive: [
                {
                  breakpoint: 940,
                  settings: {
                    slidesToShow: 2
                  }
                },
                {
                  breakpoint: 768,
                  settings: {
                    slidesToShow: 2,
                    arrows: false
                  }
                },
                {
                  breakpoint: 480,
                  settings: {
                    slidesToShow: 1,
                    arrows: false
                  }
                }
            ]
        };

        let title = content.contentTitle;
        return (
            <div className="margin-bottom">
                <Helmet title={title}
                    titleTemplate={config.titleTemplate}/>
                <div className="row">
                    <div className="col-sm-12 col-md-9">
                        <div className="product-detail">
                            <div className="content-media text-center">
                                <h1>{content.bcontentTitle}</h1>
                                { this.renderContent(content) }
                                <div className="text-justify" dangerouslySetInnerHTML={{__html: content.bcontentDesc}}/>
                            </div>
                            {
                                content.products && content.products.length ? 
                                <Slider {...options}>
                                    { this.renderCarouselItems(content.products) }
                                </Slider> : null
                            }                            
                            <ProductReview id={content.bcontentId}
                               reviews={content.comments}
                               saveReview={this.props.saveReview} 
                               type="content"/>
                        </div>
                    </div>
                    <div className="col-md-3 hidden-sm hidden-xs">
                        <div className="content-side-nav">
                            { this.renderNavList('Төстэй', same)}
                            { this.renderNavList('Их үнэлгээтэй', mostRated)}
                            { this.renderNavList('Их сэтгэгдэлтэй', mostCommented)}
                            { this.renderBanners() }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default connect(
    (state, props) => {
        return {
            content: typeof state.bcontent.content === 'string' ? JSON.parse(state.bcontent.content) : state.bcontent.content,
            sideContents: state.bcontent.sideContents,            
            banners: typeof state.banner.banners === 'string' ? JSON.parse(state.banner.banners) : state.banner.banners,
        } 
    },
    (dispatch) => bindActionCreators(Object.assign({}, contentActions, bannerActions, {saveProduct, quickViewProduct}, {showCart, addCartItem}), dispatch)
)(ContentContainer);