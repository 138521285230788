import { combineReducers } from 'redux';
import {reducer as formReducer} from 'redux-form';
import {routeReducer } from 'redux-simple-router';
import cart from './cart';
import products from './product';
import btag from './btag';
import bcontent from './bcontent';
import company from './company';
import account from './account';
import banner from './banner';

export default combineReducers({
    cart,
    products,
    btag,
    bcontent,
    company,
    account,
    banner,
    form: formReducer,
    routing: routeReducer
});
