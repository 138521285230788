import React, {Component} from 'react';

class StepIndicator extends Component{

    getStepClass(step) {
        if(step < this.props.step) {
            return "complete";
        } else if (step == this.props.step) {
            return "active";
        } else {
            return "disabled";
        }
    }

    render() {
        var steps = [
            {title: 'Захиалга', step: 1},
            {title: 'Хүргэлт', step: 2},
            {title: 'Төлбөр', step: 3}
        ];

        return (
            <div className="row bs-wizard">
                {steps.map((step, index) => {
                    return (
                        <div className={"col-xs-4 bs-wizard-step " + this.getStepClass(step.step)} key={index}>
                            <div className="text-center bs-wizard-stepnum">{step.title}</div>
                            <div className="progress">
                                <div className="progress-bar"></div>
                            </div>
                            <a href="javascript:;" className="bs-wizard-dot"/>
                        </div>
                    )
                })}
            </div>
        )
    }
}

export default StepIndicator;