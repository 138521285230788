import React, { Component } from 'react';
import config from '../config';

class PampersBanner extends Component {
    render(){
    	return (<div className = "product">
                    <a href={this.props.href}>
                        <div className="shop-banner"> 
                            <img className="img-responsive" src={this.props.src} alt=""/>
                        </div>
                    </a>
                 </div>)
    }
}

export default PampersBanner;
