import React, { Component } from 'react';
import { Link } from 'react-router';
import { DropdownButton, MenuItem } from 'react-bootstrap';
import config from '../config';

class SortControl extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sort: {
                text: 'Эрэмбэлэх'
            }
        };

        this.sorts = [{
            field: 'createdDate',
            dir: 'desc',
            text: 'Шинэ нь эхэндээ'
        },{
            field: 'createdDate',
            dir: 'asc',
            text: 'Хуучин нь эхэндээ'
        },{
            field: 'sortPrice',
            dir: 'desc',
            text: 'Үнэтэй нь эхэндээ'
        },{
            field: 'sortPrice',
            dir: 'asc',
            text: 'Хямд нь эхэндээ'
        },{
            field: 'rate',
            dir: 'desc',
            text: 'Үнэлгээ өндөр нь эхэндээ'
        },{
            field: 'sellNumber',
            dir: 'desc',
            text: 'Их худалдагсан нь эхэндээ'
        }]
    }

    handleSort(sort) {
      this.setState({
        sort: sort
      });
      
      this.props.handleSort(sort);
    }

    render(){
        return (
            <DropdownButton title={this.state.sort.text} 
              pullRight
              id="sort-control">
            {
              this.sorts.map((item, i) => (
                  <MenuItem key={i} 
                    eventKey={i} 
                    onSelect={this.handleSort.bind(this, item)}                    
                    active={this.state.sort.field === item.field && this.state.sort.dir === item.dir}>
                      {item.text}
                  </MenuItem>)
                )
            }
            </DropdownButton>
        )
    }
}

export default SortControl;