import {
    LOAD_BANNERS_SUCCESS,
    LOAD_CATEGORIZED_BANNERS_SUCCESS,
    LOAD_BRANDWEEK_BANNERS_SUCCESS
} from '../constants/ActionTypes';

const initialState = {
    banners: []
};

function btag(state = initialState, action) {
    switch (action.type) {

        case LOAD_BANNERS_SUCCESS: {
            return Object.assign({}, state, {
                banners: action.banners
            });
        }

        case LOAD_CATEGORIZED_BANNERS_SUCCESS: {
            return Object.assign({}, state, {
                categorizedBanners: action.categorizedBanners
            });
        }

        case LOAD_BRANDWEEK_BANNERS_SUCCESS: {
            return Object.assign({}, state, {
                brandWeekBanners: action.brandWeekBanners
            });
        }
        
        default:
            return state;
    }
}

export default btag;
