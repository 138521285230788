import {
    LOAD_SAVED_PRODUCTS_SUCCESS,
    LOAD_USER_SUCCESS,
    CHECK_LOGGED_USER_SUCCESS,
    LOAD_USER_PHOTO_SUCCESS,
    LOAD_ORDER_LIST_SUCCESS
} from '../constants/ActionTypes';

const initialState = {
    products: [],
    orders: [],
    user: undefined,
    photo: undefined,
    id: undefined,
    isLogged: false
};

function btag(state = initialState, action) {
    switch (action.type) {
        
        case LOAD_SAVED_PRODUCTS_SUCCESS: {
            return Object.assign({}, state, {
                products: action.products
            });
        }

        case LOAD_USER_SUCCESS: {
            return Object.assign({}, state, {
                user: action.user
            });
        }

        case LOAD_ORDER_LIST_SUCCESS: {
            return Object.assign({}, state, {
                orders: action.orders
            });
        }

        case LOAD_USER_PHOTO_SUCCESS: {
            return Object.assign({}, state, {
                photo: action.photo
            });
        }

        case CHECK_LOGGED_USER_SUCCESS: {
            return Object.assign({}, state, {
                id: action.data.id || undefined,
                isLogged: action.data.isLogged
            });
        }

        default:
            return state;
    }
}

export default btag;
