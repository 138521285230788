import React, { Component } from 'react';
import { Link } from 'react-router';
import config from '../config';

class Category extends Component {
    constructor(props) {
        super(props);
        this.state = {
            categories: [],
            currentId: ''
        }
    }

    componentDidMount(){
        this.props.loadCategoryFilter(this.props.params.id);
    }

    componentWillReceiveProps(nextProps){
        let nextState = {
            categories: nextProps.categoryFilter,
            currentId: nextProps.params.id
        }        

        this.setState(nextState);
    }

    shouldComponentUpdate(nextProps, nextState){
        return this.props.categoryFilter != nextProps.categoryFilter
            || this.state.categories != nextState.categories;
    }

    // Category дотроос ижил parent-тай category-г ялгаж авах
    parseChildCategory(categories, currentId) {
        let result = [];
        if(currentId === 'all')
            return categories;
        categories.forEach(category => {
            if(category.id === currentId || category.childMenu.filter(c => c.id === currentId).length > 0)
                return result = category.childMenu
        })

        return result;
    }

    renderCategories() {
        let path = this.props.location.pathname.indexOf('/search/') == 0 ? 'search/:id/' + this.props.params.query : 'category/:id';
        if (this.props.location.pathname.indexOf('/brandweek/') == 0) {
            path = 'brandweek/:id';
        }
        return this.state.categories.map(category => {
            let href = config.context + path.replace(':id', category.id) + '/' + category.name
            return (
                <li key={category.id} className={this.state.currentId == category.id ? 'active': ''}>
                    <a href={href}>
                        {category.name}
                        <span className="count">{category.itemCount}</span>
                    </a>
                </li>
            )
        })
    }

    render(){
        if(typeof this.state.categories != 'undefined' && this.state.categories.length > 0) {
            return (
                <div className="app-side-filter">
                    <h4>Ангилал</h4>
                    <ul>
                        {this.renderCategories()}
                    </ul>
                </div>
            )
        }

        return false;
    }
}

export default Category;