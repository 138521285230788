import React, {Component} from 'react';
import {reduxForm} from 'redux-form';
import Helmet from 'react-helmet';
import toastr from 'toastr';
import {httpRequest} from '../helpers';

import CartItemContainer from './CartItemContainer';
import {getPaymentOptions, setBuilding, getShippingPrice} from '../actions/cart';
import config from '../config';
import {formatCurrency, countItems} from '../helpers';
import BankChooser from '../components/BankChooser';
import FieldError from '../components/FieldError';
import AddressChooser from '../components/AddressChooser';
import PathMenu from '../components/PathMenu';
import {getFormValues, startPersisting, stopPersisting} from '../persistCart';

import StepIndicator from '../components/checkout/StepIndicator';
import Cart from '../components/checkout/Cart';
import ShippingFields from '../components/checkout/ShippingFields';
import PaymentFields from '../components/checkout/PaymentFields';
var assign        = require('object-assign');

function validate(values, props) {
    const errors = {};

    if (values.miCode === '') {
        errors.miCode = 'Хаягаа сонгоно уу.';
    }

    if (values.addressDescription === '') {
        errors.addressDescription = 'Хаягаа сонгоно уу.';
    }

    if (values.entrance === '') {
        errors.entrance = 'Орцны дугаараа оруулна уу.';
    }

    if (values.apartment === '') {
        errors.apartment = 'Хаалганы дугаараа оруулна уу.';
    }

    if (values.phoneNumber == null || values.phoneNumber === '') {
        errors.phoneNumber = 'Утасны дугаараа оруулна уу.';
    } else if (!/^\s*\d{6,8}\s*$/.test(values.phoneNumber)) {
        errors.phoneNumber = 'Утасны дугаар 6-8 оронтой тоо байна.';
    }

    if (values.additionalPhoneNumber === '') {
        errors.additionalPhoneNumber = 'Нэмэлт утасны дугаараа оруулна уу.';
    } else if (values.phoneNumber === values.additionalPhoneNumber) {
        errors.additionalPhoneNumber = 'Утасны дугаарууд адилхан байж болохгүй.';
    } else if (!/^\s*\d{6,8}\s*$/.test(values.additionalPhoneNumber)) {
        errors.additionalPhoneNumber = 'Утасны дугаар 6-8 оронтой тоо байна.';
    }

    if (values.paymentTypeId === '') {
        errors.paymentTypeId = 'Төлбөрийн төрлөө сонгоно уу';
    }

    if (values.paymentTypeId === '1' && values.bankId == null) {
        errors.bankId = 'Банкаа сонгоно уу.';
    }

    let totalPrice = 0;
    let totalPriceWithoutBcouponItems = 0;
    for (let item of props.cart.items) {
        totalPrice += item.unitPriceSold * item.productQuantity;
        if (item.barcode === null) {
            totalPriceWithoutBcouponItems += item.unitPriceSold * item.productQuantity;
        }
    }
    if (totalPriceWithoutBcouponItems === 0) {
        if (totalPrice === 0) {
            errors._error = 'Та ямар нэгэн худалдан авалт хийнэ үү.';
        }
    } else if (totalPriceWithoutBcouponItems < 10000) {
        errors._error = 'Таны захиалгын нийт төлбөр багадаа 10,000₮ байх хэрэгтэй.';
        if (totalPrice !== totalPriceWithoutBcouponItems) {
            errors._error += ' B-Coupon-той бараанууд үүнд тооцогдохгүй болно.';
        }
    } else errors._error = '';

    return errors;
}

class CartContainer extends Component {

    constructor(props){
        super(props);

        this.nextStep = this.nextStep.bind(this);
        this.previousStep = this.previousStep.bind(this);
        this.saveValues = this.saveValues.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.state = {
            step: 1
        }

        this.fieldValues = {
            miCode: '',
            addressDescription: '',
            additionalAddressDescription: '',
            entrance: '',
            apartment: '',
            phoneNumber: '',
            additionalPhoneNumber: '',
            paymentTypeId: '',
            deliveryTypeId: '1',
            bankId: '3',
            ...getFormValues()
        }
    }

    componentWillMount() {
        if (!this.props.paymentOptions) {
            this.props.getPaymentOptions();
        }
    }

    componentDidMount() {
        this.persisterId = startPersisting();
    }

    componentDidUpdate(props, state) {
        if (typeof this.props.shippingPrice == 'undefined' && this.props.cart.ordersId && this.props.cart.building && this.props.cart.building.delivery.scopeId && props.cart.items !== this.props.cart.items) {
            this.props.getShippingPrice(this.props.cart.ordersId, '1', this.props.cart.building.delivery.scopeId);
        }
    }

    componentWillUnmount() {
        stopPersisting(this.persisterId);
    }

    handleSubmit(data) {
        let hidden = {};
        let building = this.props.cart.building;
        hidden._token = config.csrfToken;
        hidden.ordersId = this.props.cart.ordersId;
        hidden.buildingId = building ? building.buildingId : '';
        hidden.deliveryScopeId = building ? building.delivery.scopeId : '';
        hidden.lat = building ? building.lat : '';
        hidden.lng = building ? building.lng : '';
        let form = $(`<form action="${config.baseUrl}/confirm-checkout" method="post"></form>`);
        let formData = Object.assign({}, data, hidden);
        for(var key in formData){
            if(formData.hasOwnProperty(key)) {
                form.append(`<input type="hidden" value="${formData[key]}" name="${key}"/>`)
            }
        }
        form.submit();
    }

    handleClick() {
        this.refs.orderForm.submit()
    }

    buildingChosen(building) {
        this.props.setBuilding(building);

        if (this.props.cart.building && this.props.cart.ordersId) {
            this.props.getShippingPrice(this.props.cart.ordersId, '1', building.delivery.scopeId);
        } else {
            this.props.cart.shippingPrice = 5000;
        }

        const fields = this.props.fields;
        if (building) {
            fields.miCode.onChange(building.miCode);
            fields.addressDescription.onChange(building.address);
        } else {
            fields.miCode.onChange('');
            fields.addressDescription.onChange('');
        }
    }

    renderCartItems(cart) {
        if(!cart.items.length)
            return (
                <tr className="no-items">
                    <td colSpan="5" className="text-center">
                        <h5>Таны сагс хоосон байна</h5>
                    </td>
                </tr>
            )
        return cart.items.map((item, index) => <CartItemContainer key={item.orderDtlId} item={item} />)
    }

    /*renderOrderForm(cart){
         const {
            fields: {
                miCode,
                addressDescription,
                additionalAddressDescription,
                entrance,
                apartment,
                phoneNumber,
                additionalPhoneNumber,
                paymentTypeId,
                bankId,
                deliveryTypeId
            },
            paymentOptions,
            error
        } = this.props;
        const building = cart.building;

        let deliveryPrice = this.props.cart.shippingPrice;
        if (building) {
            if(cart.ordersId) {


                //httpRequest({
                //    url: config.baseUrl + '/api/cart/get-shipping-price',
                //    method: 'GET',
                //    data: {
                //        ordersId: cart.ordersId,
                //        deliveryTypeId: '1', // regular shipping
                //        deliveryScopeId: building.delivery.scopeId
                //    }
                //})
                //    .then((data) => {
                //        deliveryPrice = data;
                //        $("#shipping-price").text(formatCurrency(deliveryPrice));
                //        $("#total-price").text(formatCurrency(cart.totalPayAmount + deliveryPrice));
                //    }, (err) => {
                //        deliveryPrice = 5000;
                //        $("#shipping-price").text(formatCurrency(deliveryPrice));
                //        $("#total-price").text(formatCurrency(cart.totalPayAmount + deliveryPrice));
                //    });
            }
            //for (let price of building.delivery.prices) {
            //    if (deliveryTypeId.value === price.deliveryTypeId &&
            //        cart.totalPayAmount >= price.startPrice && cart.totalPayAmount <= price.endPrice)
            //    {
            //        deliveryPrice = price.price;
            //    }
            //}
        }
        //if(deliveryPrice>0){
        //    let brandWeekAmount = 0;
        //    for (let item of cart.items){
        //        if(item.isDiscount && item.isBrandWeek){
        //            brandWeekAmount += item.unitPriceSold * item.productQuantity;
        //        }
        //    }
        //    if(brandWeekAmount>=20000){
        //        deliveryPrice= 0;
        //    }
        //}

        return (
            <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12">
                    <div className="order-form">
                        <h3>2. Хаягийн мэдээлэл</h3>
                        <form onSubmit={this.handleSubmit.bind(this)} 
                            action={config.baseUrl + '/confirm-checkout'} 
                            method="post"
                            ref="orderForm">
                            <input type="hidden" name="_token" value={config.csrfToken} />
                            <input type="hidden" name="ordersId" value={cart.ordersId} />
                            <input type="hidden" name="buildingId" value={building ? building.buildingId : ''} />
                            <input type="hidden" name="deliveryScopeId" value={building ? building.delivery.scopeId : ''} />
                            <input type="hidden" name="lat" value={building ? building.lat : ''} />
                            <input type="hidden" name="lng" value={building ? building.lng : ''} />
                            <div className="form-section">
                                <AddressChooser id="cart-address-chooser" buildingChosen={this.buildingChosen.bind(this)}
                                                building={building} />
                                <div className="form-horizontal">
                                    <div className="form-group">
                                        <label htmlFor="cart-mi-code" className="control-label col-sm-3">Виртуал хаяг:</label>
                                        <div className="col-sm-3">
                                            <input type="text" className="form-control" id="cart-mi-code"
                                                    {...miCode} />
                                        </div>
                                        <div className="col-sm-6">
                                            Энэхүү дугаарлалт нь таны сонгосон байршлын Виртуал хаяг юм. (эхний хоёр үсэг дүүргийн
                                            товчлол, дараагийн хоёр тоо тухайн хороо)
                                        </div>
                                    </div>
                                    <FieldError field={miCode} />
                                </div>
                                <div className="form-horizontal">
                                    <div className="form-group">
                                        <label htmlFor="cart-address-description" className="control-label col-sm-3">
                                            Дэлгэрэнгүй:</label>
                                        <div className="col-sm-9">
                                            <textarea type="text" className="form-control" id="cart-address-description"
                                                      {...addressDescription} />
                                        </div>
                                    </div>
                                    <FieldError field={addressDescription} />
                                </div>
                                <div className="form-horizontal row">
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label htmlFor="cart-entrance" className="control-label col-sm-6">Орц:</label>
                                            <div className="col-sm-6">
                                                <input type="text" className="form-control" id="cart-entrance"
                                                       placeholder="Энд бичнэ үү" {...entrance} />
                                            </div>
                                        </div>
                                        <FieldError field={entrance} />
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label htmlFor="cart-apartment" className="control-label col-sm-6">Хаалга:</label>
                                            <div className="col-sm-6">
                                                <input type="text" className="form-control" id="cart-apartment"
                                                       placeholder="Энд бичнэ үү" {...apartment} />
                                            </div>
                                        </div>
                                        <FieldError field={apartment} />
                                    </div>
                                </div>
                                <div className="form-horizontal row">
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label htmlFor="cart-phone1" className="control-label col-sm-6">Утасны дугаар:</label>
                                            <div className="col-sm-6">
                                                <input type="text" className="form-control" id="cart-phone1"
                                                       placeholder="Энд бичнэ үү" {...phoneNumber} />
                                            </div>
                                        </div>
                                        <FieldError field={phoneNumber} />
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label htmlFor="cart-phone2" className="control-label col-sm-6">
                                                Нэмэлт утасны дугаар:</label>
                                            <div className="col-sm-6">
                                                <input type="text" className="form-control" id="cart-phone2"
                                                       placeholder="Энд бичнэ үү" {...additionalPhoneNumber} />
                                            </div>
                                        </div>
                                        <FieldError field={additionalPhoneNumber} />
                                    </div>
                                </div>
                                <div className="form-horizontal">
                                    <div className="form-group">
                                        <label htmlFor="cart-additional-address-description" className="control-label col-sm-3">
                                            Нэмэлт хаягийн мэдээлэл:</label>
                                        <div className="col-sm-9">
                                            <input type="text" id="cart-additional-address-description"
                                                   className="form-control" {...additionalAddressDescription}
                                                   placeholder="Орцны кодтой бол энд бичнэ үү" />
                                        </div>
                                    </div>
                                    <FieldError field={additionalAddressDescription} />
                                </div>
                            </div>
                            <div className="form-section">
                                {paymentOptions != null && (
                                    <div className="form-horizontal row">
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label htmlFor="cart-delivery-type" className="control-label col-sm-6">
                                                    Хүргэлт:</label>
                                                <div className="col-sm-6">
                                                    <select className="form-control" {...deliveryTypeId}
                                                            id="cart-delivery-type">
                                                        {paymentOptions.deliveryTypes.map(type =>
                                                            <option key={type.deliveryTypeId} value={type.deliveryTypeId}>
                                                                {type.deliveryTypeName}</option>)}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <input type="hidden" name="deliveryScopeId"
                                                   value={building != null && building.delivery.scopeId} />
                                            <div className="form-group">
                                                <label htmlFor="cart-delivery-area" className="control-label col-sm-6">Бүс:</label>
                                                <div className="col-sm-6">
                                                    <input type="text" className="form-control" id="cart-delivery-area"
                                                           value={building ? building.delivery.scopeName : ''} readOnly />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {paymentOptions == null && <div className="loading small" />}
                            </div>
                            <div className="form-section">
                                {paymentOptions != null && (
                                    <div className="form-horizontal row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="deliverytype" className="control-label col-sm-6">
                                                    Төлбөрийн төрөл:</label>
                                                <div className="col-sm-6">
                                                    <select className="form-control" {...paymentTypeId}>
                                                        <option disabled>Сонгох</option>
                                                        {paymentOptions.paymentTypeList.map(type =>
                                                            <option key={type.paymentTypeId} value={type.paymentTypeId}>
                                                                {type.paymentTypeName}</option>)}
                                                    </select>
                                                    <FieldError field={paymentTypeId} />
                                                </div>
                                            </div>
                                        </div>
                                        { paymentTypeId.value === "1" && (
                                            <div className="col-md-6">
                                                <input type="hidden" name="bankId" value={bankId.value} />
                                                <BankChooser banks={paymentOptions.bankList} {...bankId} />
                                                <FieldError field={bankId} />
                                            </div>
                                        )}                                        
                                    </div>                                    
                                )}
                                { paymentTypeId.value === '5' && 
                                    <div className="row">
                                        <div className="col-md-12">
                                            <h4>Дансны мэдээлэл</h4>
                                            <p>Та дараах данснуудаас сонгон гүйлгээгээ хийнэ үү.</p>
                                            <table className="table table-bordered">
                                                <tbody>
                                                    <tr>
                                                        <td>Дансны дугаар</td>
                                                        <td>Худалдаа Хөгжлийн Банк - 470009648 <br/>
                                                            Голомт банк - 2010008834 <br/>
                                                            Хаан банк - 5031677015 <br/>
                                                            Төрийн банк - 102200401270
                                                        </td>                                                            
                                                    </tr>
                                                    <tr>
                                                        <td>Хүлээн авагч</td>
                                                        <td><strong>Монгол Айдия Групп ХХК</strong></td>
                                                    </tr>
                                                    <tr>
                                                        <td>Гүйлгээний утга</td>
                                                        <td><strong>{this.props.cart.orderNumber}</strong></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                }
                                {paymentOptions == null && <div className="loading small" />}
                            </div>
                        </form>
                    </div>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-12">
                    <div className="order-form">
                        <div className="total">
                            <h3>3. Төлбөрийн мэдээлэл</h3>
                            <div className="row">                                
                                <div className="col-md-12">
                                    <table className="table table-bordered">
                                        <tbody>
                                            <tr>
                                                <td>Үндсэн үнэ</td>
                                                <td>{`${formatCurrency(cart.totalAmount)}₮`}</td>
                                            </tr>
                                            <tr>
                                                <td>Хүргэлт</td>
                                                <td id="shipping-price">{`${formatCurrency(deliveryPrice)}₮`}</td>
                                            </tr>
                                            {
                                                cart.totalDiscount > 0 ?
                                                <tr>
                                                    <td>Хөнгөлөлт</td>
                                                    <td>{`${formatCurrency(-cart.totalDiscount)}₮`}</td>
                                                </tr> : null                                                
                                            }
                                            <tr>
                                                <td>Нийт үнэ</td>
                                                <td className="text-bold" id="total-price">{`${formatCurrency(cart.totalPayAmount + deliveryPrice)}₮`}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                {
                                    error && 
                                    <div className="col-md-12 cart-error">
                                        <span>{error}</span>
                                    </div>
                                }
                                <div className="col-md-12 margin-top text-center">
                                    <button type="submit" className="btn btn-primary btn-lg " onClick={this.handleClick.bind(this)}>
                                        Захиалга хийх
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }*/

    saveValues(field_value) {
        return function() {
            this.fieldValues = assign({}, this.fieldValues, field_value)
        }.bind(this)()
    }

    nextStep() {
        this.setState({
            step: this.state.step + 1
        })
    }

    previousStep() {
        this.setState({
            step: this.state.step -1
        })
    }


    renderStep1(cart) {
        return (
                <Cart cart={cart} nextStep={this.nextStep.bind(this)}/>
        );
    }

    renderStep2(cart) {
        return (
            <ShippingFields cart={cart}
                            nextStep={this.nextStep}
                            previousStep={this.previousStep}
                            saveValues={this.saveValues}
                            setBuilding={this.props.setBuilding}
                            getShippingPrice={this.props.getShippingPrice}
                            onSubmit={this.nextStep}
            />
        );
    }

    renderStep3(cart) {
        return (
            <PaymentFields cart={cart}
                           previousStep={this.previousStep}
                           paymentOptions={this.props.paymentOptions}
                           onSubmit={this.handleSubmit}
            />
        );
    }

    render() {
        const { cart } = this.props;

        let title =
            this.state.step == 1 ? 'Захиалга'
                : (this.state.step == 2 ? 'Захиалга - Хүргэлтийн мэдээлэл'
                : (this.state.step == 3 ? 'Захиалга - Төлбөрийн мэдээлэл' : 'Захиалга')),
            paths = [title];

        return (
            <div className="cart">
                <Helmet title={title}
                    titleTemplate={config.titleTemplate}/>
                <div className="row">
                    <div className="col-md-8">
                        <PathMenu paths={paths}/>
                    </div>
                </div>
                <StepIndicator step={this.state.step} />
                {
                    this.state.step == 1 ? this.renderStep1(cart)
                        : (this.state.step == 2 ? this.renderStep2(cart)
                        : (this.state.step == 3 ? this.renderStep3(cart) : ''))
                }
            </div>
        );
    }
}

export default reduxForm(
    {
        form: 'cartForm',
        fields: [
            'receiver', 'miCode', 'email', 'addressDescription', 'additionalAddressDescription', 'entrance', 'apartmentSelect', 'apartmentInput', 'door',
            'phoneNumber', 'additionalPhoneNumber', 'deliveryTypeId', 'district', 'khoroo',
            'paymentTypeId', 'bankId'
        ],
        initialValues: {
            miCode: '',
            receiver: '',
            email: '',
            phoneNumber: '',
            additionalPhoneNumber: '',
            district: '',
            khoroo: '',
            apartmentSelect: '',
            apartmentInput: '',
            entrance: '',
            door: '',
            addressDescription: '',
            additionalAddressDescription: '',
            deliveryTypeId: '1',
            paymentTypeId: '',
            bankId: '3',
            ...getFormValues()
        },
        destroyOnUnmount: false,
        validate
    },
    state => {
        return {
            cart: state.cart,
            paymentOptions: state.cart.paymentOptions
        };
    },
    {getPaymentOptions, setBuilding, getShippingPrice}
)(CartContainer);
