import React, { Component, PropTypes } from 'react';
import ReactDOM from 'react-dom';
import {Pagination} from 'react-bootstrap';

import config from '../config';
import {uniqueArray} from '../helpers';
import Product from './Product';
import Preloader from './Preloader';
import Masonry from 'react-masonry-component';
import PampersBanner from './PampersBanner';
let InfiniteScroll = require('./react-infinite-scroll')(React, ReactDOM);

class ProductList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            page: 1,
            products: [],
            sort: undefined,
            filter: undefined,
            hasMore: true
        }
    }

    componentDidMount() {
        if(!this.props.isInfinite)
            this.loadProducts(this.state.page);
    }

    componentWillReceiveProps(nextProps) {
        let products = this.state.products;
        if(nextProps.sort.field !== this.props.sort.field 
            || nextProps.sort.dir !== this.props.sort.dir )
        {
            products = [];
            this.setState({
                page: 1,
                sort: nextProps.sort,
                filter: nextProps.filter,
                products: products
            })

            this.props.sortChanged();

            if(this.props.isInfinite)
                this.refs.infiniteScroll.pageLoaded = 0;
            this.fetchProducts(1, nextProps.sort, this.props.fetchCount);
        }
        else if(nextProps.filter !== this.props.filter)
        {
            products = [];
            this.setState({
                page: 1,
                sort: nextProps.sort,
                filter: nextProps.filter,
                products: products
            })

            if ( typeof nextProps.filter.price != 'undefined' &&
                 typeof this.props.filter.price != 'undefined' &&
                (nextProps.filter.price.min != this.props.filter.price.min
                || nextProps.filter.price.max != this.props.filter.price.max)
                && this.props.products.length > 0) {
                this.props.filterChanged();
            }

            if(this.props.isInfinite && this.refs.infiniteScroll)
                this.refs.infiniteScroll.pageLoaded = 0;
            this.fetchFilteredProducts(1, nextProps.sort, nextProps.filter, this.props.fetchCount);
        }
        else {
            let nextState = {}
            if(this.props.isInfinite){
                nextState = {
                    products: uniqueArray(products.concat(nextProps.products), 'prodVarId'),
                    hasMore: nextProps.products.length === this.props.fetchCount
                }
            }
            else {
                nextState = {
                    products: nextProps.products
                }
            }
            
            this.setState(nextState);
        }

    }

    fetchFilteredProducts(page, sort, filter, fetchCount, callback){
        this.props.loadProducts(page - 1, fetchCount, this.props.params.id, sort, callback, this.props.params.query,
            Object.assign(this.props.location.query,{lp:filter.price.min, hp: filter.price.max}));
    }

    fetchProducts(page, sort, fetchCount, callback){
        this.props.loadProducts(page - 1, fetchCount, this.props.params.id, sort, callback, this.props.params.query, this.props.location.query);
    }

    loadProducts(page){
        this.fetchProducts(page, this.state.sort, this.props.fetchCount, this.onCallback.bind(this));
    }

    onCallback(page){
        this.setState({
            page: page + 1
        })
    }

    handlePagination(event, selectedEvent) {
        this.loadProducts(selectedEvent.eventKey)
    }

    renderProducts(){
        let renderedProducts = [],
            products = [];
        for (var i = 0; i < this.state.products.length; i++) {
            let product = this.state.products[i];
            products.push(<div key={product.prodVarId} className={this.props.column+" product-list-item"}>
                <Product
                    product={product}
                    saveProduct={this.props.saveProduct}
                    viewProduct={this.props.quickViewProduct}
                    addCartItem={this.props.addCartItem}
                    returnTo={this.props.location.pathname}/>
            </div>);
            {
                i == 2 && this.props.location.query.brand_id == '201464253659957' &&
                products.push(<div className="col-xs-12 product-list-item" key="rothobanner">
                    <div className="product">
                        <a href={this.props.href}>
                            <div className="shop-banner">
                                <img className="img-responsive" src="/images/rotho_banner_gift.jpg"
                                     alt="Rotho"/>
                            </div>
                        </a>
                    </div>
                </div>)
            }
        }
        return products;
    }

    renderPagination(){
        let pageCount = Math.ceil(this.props.total/this.props.fetchCount);

        if(pageCount < 1)
            return null;
        return (
            <div className="text-center margin-top">
                 <Pagination 
                    prev={<i className="zmdi zmdi-chevron-left"></i>}
                    next={<i className="zmdi zmdi-chevron-right"></i>}
                    ellipsis
                    items={pageCount}
                    maxButtons={5}
                    activePage={this.state.page}
                    onSelect={this.handlePagination.bind(this)}/>
            </div>
        )
    }

    renderInfinite(options){
        return (
            <InfiniteScroll 
                ref="infiniteScroll"
                pageStart={this.state.page - 1} 
                loadMore={this.props.isLoading ? (page) => {this.refs.infiniteScroll.pageLoaded = page - 1} : this.loadProducts.bind(this)}
                hasMore={this.state.hasMore}
                loader={<Preloader/>}>
                <Masonry className="row" options={options}>
                    {this.renderProducts()}
                </Masonry>
            </InfiniteScroll>
        )
    }

    render(){
        let options = {
          itemSelector: '.product-list-item',
          gutter: 0,
          transitionDuration: 0
        };

        if(this.state.products.length == 0 && this.props.isLoading == false && this.state.hasMore == false) {
            return (
                <div>Бүтээгдэхүүн байхгүй</div>
            )
        }

        // if(this.props.isLoading)
        //     return <Preloader/>

        /**
        * Infinite Scroll
        */
        if(this.props.isInfinite)
            return this.renderInfinite(options);

        return (
            <div>
                <Masonry className="row" options={options}>
                    {this.renderProducts()}
                </Masonry>
                { this.renderPagination() }
            </div>
        )
    }
}

ProductList.displayName = "ProductList";

ProductList.propTypes = {
    fetchCount: PropTypes.number,
    isInfinite: PropTypes.bool,
    showBanner: PropTypes.bool
}

ProductList.defaultProps = {
    fetchCount: 9,
    isInfinite: true,
    showBanner: false,
    column: 'col-xs-6 col-sm-4 col-md-4'
}

export default ProductList;
