var React = require('react');
import {getPaymentOptions} from '../../actions/cart';
import config from '../../config';
import {formatCurrency} from '../../helpers';
import FieldError from '../../components/FieldError';
import BankChooser from '../../components/BankChooser';
import CartItemContainer from '../../containers/CartItemContainer';
import { reduxForm } from 'redux-form'
import toastr from 'toastr';
import {getFormValues} from '../../persistCart';

const demoOptions = [
    {
        paymentTypeId: '9',
        paymentTypeName: 'QPay'
    }
];

export const fields = [
    'receiver', 'email', 'miCode', 'addressDescription', 'additionalAddressDescription', 'entrance', 'apartmentSelect', 'apartmentInput', 'door',
    'phoneNumber', 'additionalPhoneNumber', 'deliveryTypeId', 'district', 'khoroo', 'paymentTypeId','bankId'];

const validate = (values, props) => {
    const errors = {};

    if (values.paymentTypeId == '') {
        errors.paymentTypeId = 'Төлбөрийн төрөл сонгоно уу.';
    }
    if (values.paymentTypeId === "1" && values.bankId == '') {
        errors.bankId = 'Төлбөр төлөх банк сонгоно уу.';
    }
    let totalPrice = 0;
    let totalPriceWithoutBcouponItems = 0;
    for (let item of props.cart.items) {
        totalPrice += item.unitPriceSold * item.productQuantity;
        if (item.barcode === null) {
            totalPriceWithoutBcouponItems += item.unitPriceSold * item.productQuantity;
        }
    }
    if (totalPriceWithoutBcouponItems === 0) {
        if (totalPrice === 0) {
            errors._error = 'Та ямар нэгэн худалдан авалт хийнэ үү.';
        }
    } else if (totalPriceWithoutBcouponItems < 10000) {
        errors._error = 'Таны захиалгын нийт төлбөр багадаа 10,000₮ байх хэрэгтэй.';
        if (totalPrice !== totalPriceWithoutBcouponItems) {
            errors._error += ' B-Coupon-той бараанууд үүнд тооцогдохгүй болно.';
        }
    } else errors._error = '';

    return errors;
};

var PaymentFields = React.createClass({
    render: function () {
        const {
            fields: {
                paymentTypeId,
                bankId
            },
            paymentOptions,
            error,
            handleSubmit,
            submitting
        } = this.props;
        const building = this.props.cart.building;

        var cart = this.props.cart;
        var address = '';
        var formValues = global.store.getState().form.cartForm;
        if(formValues)
        address = formValues.addressDescription.value + (formValues.entrance.value ? ', ' +formValues.entrance.value+' орц':'')
            + (', ' + formValues.door.value +' тоот');

        return (
            <div className="row">
                <div className="col-md-8 col-xs-12">
                    <div className="order-form">
                        <h3>3. Төлбөрийн сонголт</h3>
                        <form onSubmit={handleSubmit}
                              action={config.baseUrl + '/confirm-checkout'}
                              method="post"
                              id="cartForm">
                            <input type="hidden" name="deliveryTypeId" value="1"/>
                            <input type="hidden" name="deliveryScopeName" value={building ? building.delivery.scopeName : ''}/>
                            <div className="form-section">
                                {paymentOptions != null && (
                                    <div className="form-horizontal row">
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label htmlFor="deliverytype" className="control-label col-sm-4">
                                                    Төлбөрийн төрөл:</label>
                                                <div className="col-sm-8">
                                                    {paymentOptions.paymentTypeList.concat(demoOptions).map(type =>
                                                    <div key={type.paymentTypeId} className="radio">
                                                        <label htmlFor={'paymentTypeId-'+type.paymentTypeId} className="control-label">
                                                            <input type="radio" value={type.paymentTypeId}
                                                                   onChange={(evt)=>{
                                                                    paymentTypeId.onChange(evt)
                                                                   }}
                                                                   checked={type.paymentTypeId === paymentTypeId.value}
                                                                   id={'paymentTypeId-'+type.paymentTypeId} />
                                                            {type.paymentTypeName}
                                                        </label>
                                                    </div>)}
                                                    <FieldError field={paymentTypeId} />
                                                </div>
                                            </div>
                                        </div>
                                        { paymentTypeId.value === "1" && (
                                            <div className="col-md-12">
                                                <input type="hidden" name="bankId" value={bankId.value} />
                                                <BankChooser banks={paymentOptions.bankList} {...bankId} />
                                                <FieldError field={bankId} />
                                            </div>
                                        )}
                                    </div>
                                )}
                                { paymentTypeId.value === '5' &&
                                <div className="row">
                                    <div className="col-md-12">
                                        <h4>Дансны мэдээлэл</h4>
                                        <p>Та дараах данснуудаас сонгон гүйлгээгээ хийнэ үү.</p>
                                        <table className="table table-bordered">
                                            <tbody>
                                            <tr>
                                                <td>Дансны дугаар</td>
                                                <td>Худалдаа Хөгжлийн Банк - 470009648 <br/>
                                                    Голомт банк - 2010008834 <br/>
                                                    Хаан банк - 5031677015 <br/>
                                                    Төрийн банк - 102200401270
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Хүлээн авагч</td>
                                                <td><strong>Монгол Айдия Групп ХХК</strong></td>
                                            </tr>
                                            <tr>
                                                <td>Гүйлгээний утга</td>
                                                <td><strong>{this.props.cart.orderNumber}</strong></td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                }
                                {paymentOptions == null && <div className="loading small" />}
                            </div>
                            <div className="col-md-12 margin-top margin-bottom text-center">
                                {
                                    error &&
                                    <div className="col-md-12 cart-error">
                                        <span>{error}</span>
                                    </div>
                                }
                                <button type="button" className="btn btn-default " onClick={this.props.previousStep} disabled={submitting}>
                                    Буцах
                                </button>
                                <button type="submit" className="btn btn-primary" disabled={submitting}>
                                    Захиалга хийх
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="col-md-4 hidden-xs sticky cart-items-mini">
                    <div className="sidebar">
                        <table className="table table-bordered">
                            <thead>
                            <tr>
                                <th colSpan="3">Захиалгын мэдээлэл</th>
                                <th><button type="button" className="btn btn-info btn-xs btn-expand" onClick={(evt)=>{
                                    this.setState({hideCartDetail: !this.state.hideCartDetail})
                                }}>{this.state.hideCartDetail?'Дэлгэрэнгүй':'Хураангуй'}</button></th>
                            </tr>
                            </thead>
                            <tbody hidden={this.state.hideCartDetail}>
                            {this.props.cart.items.map((item,index) => <CartItemContainer key={item.orderDtlId} item={item} simple={true} />)}
                            </tbody>
                            <tfoot>
                            <tr>
                                <td colSpan="3">Барааны нийт үнэ:</td>
                                <td>{formatCurrency(this.props.cart.totalAmount) + '₮'}</td>
                            </tr>
                            {this.props.cart.totalDiscount > 0 &&
                            <tr>
                                <td colSpan="3">Хөнгөлөлт:</td>
                                <td>{formatCurrency(-this.props.cart.totalDiscount) + '₮'}</td>
                            </tr>
                            }
                            <tr>
                                <td colSpan="3">Хүргэлт:</td>
                                <td>{this.props.cart.shippingPrice === 0 ? 'Үнэгүй' : formatCurrency(this.props.cart.shippingPrice) + '₮'}</td>
                            </tr>
                            <tr className="items-total">
                                <th colSpan="3">Нийт үнэ:</th>
                                <th>{formatCurrency(parseInt(this.props.cart.totalPayAmount) + this.props.cart.shippingPrice) + '₮'}</th>
                            </tr>
                            </tfoot>
                        </table>
                    </div>
                    <div className="sidebar">
                        <table className="table table-bordered">
                            <thead>
                            <tr>
                                <th>Хүргэлтийн хаяг</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>{address}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        )
    },


    componentWillMount: function () {
        if (!this.props.paymentOptions) {
            this.props.getPaymentOptions();
        }
        if (this.state == null) {
            this.setState({
                hideCartDetail: true
            })
        }
    },

    handleSubmit: function(event) {
        this.props.touchAll();

        if (global.baby.userId == null) {
            toastr.info('Та худалдан авалт хийхийн тулд нэвтэрч орсон байх хэрэгтэй.', null, {"positionClass": "toast-top-center"});
            event.preventDefault();
        }

        if (this.props.invalid || this.props.cart.ongoingUpdates > 0) {
            event.preventDefault();
        }
    }


});

export default reduxForm(
    {
        form: 'cartForm',
        fields,
        initialValues: {
            paymentTypeId: '',
            bankId: '3',
            ...getFormValues()
        },
        destroyOnUnmount: false,
        validate: validate
    },
    state => {
        return {
            cart: state.cart
        }
    },
    {getPaymentOptions}
)(PaymentFields)
