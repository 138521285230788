import {
    LOAD_COMPANY_SUCCESS,
    LOAD_COMPANY_ERROR,
    LOAD_COMPANY_PRODUCTS_SUCCESS,
    LOAD_COMPANYS_SUCCESS,
    LOAD_BRANDS_SUCCESS,
    LOAD_BRAND_PRODUCTS_SUCCESS,
    LOAD_COMPANY_PRODUCTS_ERROR,
    LOAD_BRAND_PRODUCTS,
    BRAND_SORT_CHANGED,
    BRAND_CHANGED
} from '../constants/ActionTypes';

const initialState = {
    products: [],
    companies: [],
    brands: [],
    company: undefined,
    error: null,
    total: 0,
    isLoading: false,
    clearProducts: false
};

function company(state = initialState, action) {
    switch (action.type) {
        case LOAD_COMPANY_SUCCESS: {
            return Object.assign({}, state, {
                company: action.company
            });
        } 

        case LOAD_COMPANY_PRODUCTS_SUCCESS: {
            return Object.assign({}, state, {
                products: action.products
            });
        }

        case LOAD_COMPANYS_SUCCESS: {
            return Object.assign({}, state, {
                companies: action.companies
            });
        }

        case LOAD_BRANDS_SUCCESS: {
            return Object.assign({}, state, {
                brands: action.brands
            });
        }

        case BRAND_CHANGED:
        case BRAND_SORT_CHANGED: {
            return Object.assign({}, state, {
                products: [],
                clearProducts: true
            });
        }

        case LOAD_BRAND_PRODUCTS: {
            return Object.assign({}, state, {
                isLoading: true
            });
        }

        case LOAD_BRAND_PRODUCTS_SUCCESS: {
            return Object.assign({}, state, {
                products: action.products.data,
                total: action.products.recordsTotal,
                isLoading: false,
                clearProducts: false
            });
        }

        case LOAD_COMPANY_PRODUCTS_ERROR:
        case LOAD_COMPANY_ERROR: {
            return Object.assign({}, state, {
                error: action.error
            });
        }

        default:
            return state;
    }
}

export default company;
