import React, { Component } from 'react';
import config from '../../config';
import Product from '../Product';
import Masonry from 'react-masonry-component';

class LikedProduct extends Component {
	
	constructor(props){
		super(props);
    this.state = {
      products: []
    }
	}

  componentWillReceiveProps(nextProps){
    this.setState({
      products: nextProps.products
    })
  }

  componentWillMount(){
      this.props.loadSavedProducts();
  }

  onUnsave(productVarId, isSaved){
    if(isSaved) return;
    let products = this.state.products.filter(product => product.prodVarId !== productVarId);
    this.setState({
      products: products
    })
  }

  renderProducts(products){
     return products.map(product => 
                    <div key={product.prodVarId} className="col-xs-12 col-sm-6 col-md-4">
                      <Product product={product} 
                          saveProduct={this.props.saveProduct} 
                          viewProduct={this.props.quickViewProduct} 
                          checkIsLogged={this.props.checkIsLogged} 
                          addCartItem={this.props.addCartItem}
                          returnTo={this.props.location.pathname}
                          onUnsave={this.onUnsave.bind(this)}/>
                    </div>);
  }

  renderEmptyMessage(){
      return <h3 style={{color: '#cecece'}} className="text-center">Бүтээгдэхүүний жагсаалт хоосон байна!</h3>
  }

  render(){
      let options = {
        itemSelector: '.col-md-4',
        gutter: 0,
        transitionDuration: 0
      };

      return (<div className = "info-container info-main-container">
                <div className="row">
                	<div className="col-md-8 text-left">
                		<h3>Миний дуртай</h3>
                	</div>
                </div>
                <hr/>
                <div className="row">
                    <div className="col-md-12">
                         {  
                            this.state.products.length ? 
                            <Masonry className="itemlist" options={options}>
                            {  this.renderProducts(this.state.products) }
                            </Masonry> : 
                            this.renderEmptyMessage() 
                        }
                    </div>
                </div>
             </div>)
    }
}

export default LikedProduct;
