// account
export const LOAD_SAVED_PRODUCTS_SUCCESS = 'LOAD_SAVED_PRODUCTS_SUCCESS';
export const LOAD_SAVED_PRODUCTS_ERROR = 'LOAD_SAVED_PRODUCTS_ERROR';
export const LOAD_USER_SUCCESS = 'LOAD_USER_SUCCESS';
export const LOAD_USER_ERROR = 'LOAD_USER_ERROR';
export const LOAD_USER_PHOTO_SUCCESS = 'LOAD_USER_PHOTO_SUCCESS';
export const LOAD_USER_PHOTO_ERROR = 'LOAD_USER_PHOTO_ERROR';
export const CHECK_LOGGED_USER_SUCCESS = 'CHECK_LOGGED_USER_SUCCESS';
export const CHECK_LOGGED_USER_ERROR = 'CHECK_LOGGED_USER_ERROR';
export const LOAD_ORDER_LIST_SUCCESS = 'LOAD_ORDER_LIST_SUCCESS';
export const LOAD_ORDER_LIST_ERROR = 'LOAD_ORDER_LIST_ERROR';
//banner
export const LOAD_BANNERS_SUCCESS = 'LOAD_BANNERS_SUCCESS';
export const LOAD_BANNERS_ERROR = 'LOAD_BANNERS_ERROR';
export const LOAD_CATEGORIZED_BANNERS_SUCCESS = 'LOAD_CATEGORIZED_BANNERS_SUCCESS';
export const LOAD_CATEGORIZED_BANNERS_ERROR = 'LOAD_CATEGORIZED_BANNERS_ERROR';
export const LOAD_BRANDWEEK_BANNERS_SUCCESS = 'LOAD_BRANDWEEK_BANNERS_SUCCESS';
export const LOAD_BRANDWEEK_BANNERS_ERROR = 'LOAD_BRANDWEEK_BANNERS_ERROR';
//bcontent
export const LOAD_BCONTENTS_REQUEST = 'LOAD_BCONTENTS_REQUEST';
export const LOAD_BCONTENT_REQUEST = 'LOAD_BCONTENT_REQUEST';
export const LOAD_BCONTENTS_SUCCESS = 'LOAD_BCONTENTS_SUCCESS';
export const LOAD_BCONTENTS_ERROR = 'LOAD_BCONTENTS_ERROR';
export const LOAD_BCONTENT_SUCCESS = 'LOAD_BCONTENT_SUCCESS';
export const LOAD_BCONTENT_ERROR = 'LOAD_BCONTENT_ERROR';
export const LOAD_BCONTENT_REVIEW_SUCCESS = 'LOAD_BCONTENT_REVIEW_SUCCESS';
export const LOAD_SIDE_BCONTENTS_REQUEST = 'LOAD_SIDE_BCONTENTS_REQUEST';
export const LOAD_SIDE_BCONTENTS_SUCCESS = 'LOAD_SIDE_BCONTENTS_SUCCESS';
export const LOAD_SIDE_BCONTENTS_ERROR = 'LOAD_SIDE_BCONTENTS_ERROR';
//btag
export const LOAD_BTAG_PRODUCTS_SUCCESS = 'LOAD_BTAG_PRODUCTS_SUCCESS';
export const LOAD_BTAG_PRODUCTS_ERROR = 'LOAD_BTAG_PRODUCTS_ERROR';
export const LOAD_BTAGS_SUCCESS = 'LOAD_BTAGS_SUCCESS';
export const LOAD_BTAGS_ERROR = 'LOAD_BTAGS_ERROR';
export const LOAD_BTAG_SPECIAL_SUCCESS = 'LOAD_BTAG_SPECIAL_SUCCESS';
export const LOAD_BTAG_SPECIAL_ERROR = 'LOAD_BTAG_SPECIAL_ERROR';
//cart
export const CART_UPDATING = 'CART_UPDATING';
export const UPDATE_CART = 'UPDATE_CART';
export const CART_UPDATE_FAILED = 'CART_UPDATE_FAILED';
export const GET_PAYMENT_OPTIONS = 'GET_PAYMENT_OPTIONS';
export const CART_ITEM_UPDATING = 'CART_ITEM_UPDATING';
export const CART_ITEM_UPDATE_FAILED = 'CART_ITEM_UPDATE_FAILED';
export const SHOW_CART = 'SHOW_CART';
export const HIDE_CART = 'HIDE_CART';
export const SET_BUILDING = 'SET_BUILDING';
export const GET_SHIPPING_PRICE = 'GET_SHIPPING_PRICE';
export const GET_DISTRICT_LIST = 'GET_DISTRICT_LIST';
export const GET_KHOROO_LIST = 'GET_KHOROO_LIST';
export const GET_BUILDING_LIST = 'GET_BUILDING_LIST';
//company
export const LOAD_COMPANY_SUCCESS = 'LOAD_COMPANY_SUCCESS';
export const LOAD_COMPANY_ERROR = 'LOAD_COMPANY_ERROR';
export const LOAD_COMPANY_PRODUCTS_SUCCESS = 'LOAD_COMPANY_PRODUCTS_SUCCESS';
export const LOAD_COMPANY_PRODUCTS_ERROR = 'LOAD_COMPANY_PRODUCTS_ERROR';
export const LOAD_COMPANYS_SUCCESS = 'LOAD_COMPANYS_SUCCESS';
export const LOAD_COMPANYS_ERROR = 'LOAD_COMPANYS_ERROR';
export const LOAD_BRANDS_SUCCESS = 'LOAD_BRANDS_SUCCESS';
export const LOAD_BRANDS_ERROR = 'LOAD_BRANDS_ERROR';
export const LOAD_BRAND_PRODUCTS = 'LOAD_BRAND_PRODUCTS';
export const LOAD_BRAND_PRODUCTS_SUCCESS = 'LOAD_BRAND_PRODUCTS_SUCCESS';
export const LOAD_BRAND_PRODUCTS_ERROR = 'LOAD_BRAND_PRODUCTS_ERROR';
export const BRAND_SORT_CHANGED = 'BRAND_SORT_CHANGED';
export const BRAND_CHANGED = 'BRAND_CHANGED';
//product
export const ADD_PRODUCTS = 'ADD_PRODUCTS';
export const LOAD_PRODUCTS_SUCCESS = 'LOAD_PRODUCTS_SUCCESS';
export const LOAD_PRODUCTS_ERROR = 'LOAD_PRODUCTS_ERROR';
export const VIEW_PRODUCT_SUCCESS = 'VIEW_PRODUCT_SUCCESS';
export const VIEW_PRODUCT_ERROR = 'VIEW_PRODUCT_ERROR';
export const SAVE_PRODUCT_SUCCESS = 'SAVE_PRODUCT_SUCCESS';
export const SAVE_PRODUCT_ERROR = 'SAVE_PRODUCT_ERROR';
export const SAVE_REVIEW_SUCCESS = 'SAVE_REVIEW_SUCCESS';
export const SAVE_REVIEW_ERROR = 'SAVE_REVIEW_ERROR';
export const LOAD_CATEGORIES_SUCCESS = 'LOAD_CATEGORIES_SUCCESS';
export const LOAD_CATEGORIES_ERROR = 'LOAD_CATEGORIES_ERROR';
export const QUICK_VIEW_PRODUCT_SUCCESS = 'QUICK_VIEW_PRODUCT_SUCCESS';
export const QUICK_VIEW_PRODUCT_ERROR = 'QUICK_VIEW_PRODUCT_ERROR';
export const LOAD_PRODUCT_BRANDS_SUCCESS = 'LOAD_PRODUCT_BRANDS_SUCCESS';
export const LOAD_PRODUCT_BRANDS_ERROR = 'LOAD_PRODUCT_BRANDS_ERROR';
export const LOAD_PRICE_FILTER_SUCCESS = 'LOAD_PRICE_FILTER_SUCCESS';
export const LOAD_PRICE_FILTER_ERROR = 'LOAD_PRICE_FILTER_ERROR';
export const LOAD_CATEGORY_FILTER_SUCCESS = 'LOAD_CATEGORY_FILTER_SUCCESS';
export const LOAD_CATEGORY_FILTER_ERROR = 'LOAD_CATEGORY_FILTER_ERROR';

export const REQUEST_START = 'REQUEST_START';
export const VIEW_PRODUCT_START = 'VIEW_PRODUCT_START';
export const QUICK_VIEW_PRODUCT_START = 'QUICK_VIEW_PRODUCT_START';
export const SORT_CHANGED = 'SORT_CHANGED';
export const FILTER_CHANGED = 'FILTER_CHANGED';