import React, { Component, PropTypes } from 'react';
import ReactDOM from 'react-dom';
import { Link } from 'react-router';
import { Pagination, Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Masonry from 'react-masonry-component';
import Helmet from "react-helmet";
let InfiniteScroll = require('../components/react-infinite-scroll')(React, ReactDOM);

import * as productActions from '../actions/product';
import * as companyActions from '../actions/company';
import {addCartItem} from '../actions/cart';
import config from '../config';
import {uniqueArray} from '../helpers';

import Product from '../components/Product';
import PathMenu from '../components/PathMenu';
import SortControl from '../components/SortControl';
import ProductDetail from '../components/ProductDetail';
import Preloader from '../components/Preloader';

class BrandContainer extends Component {
    constructor(props){
        super(props);

        this.state = {
            brandId: undefined,
            products: [],
            page: 1,
            sort: undefined,
            hasMore: true
        }
    }

    componentWillMount(){      
        let { query } = this.props.location;
        this.setState({
            name: decodeURIComponent(query.name || '')
        })

        this.props.brandChanged();
    }

    componentDidMount() {
        if(!this.props.isInfinite)
            this.loadProducts(this.state.page)     
    }

    componentWillReceiveProps(nextProps){
        let nextState = {}
        if(this.props.isInfinite){
            let products = !nextProps.clearProducts ? this.state.products : [];

            nextState = {
                products: uniqueArray(products.concat(nextProps.products), 'prodVarId'),
                hasMore: nextProps.products.length === this.props.fetchCount
            }
        }
        else {
            nextState = {
                products: nextProps.products
            }
        }

        this.setState(nextState);
    }

    fetchProducts(page, sort, fetchCount, callback){
        this.props.loadBrandProducts(page - 1, fetchCount, this.props.params.id, sort, callback);
    }

    loadProducts(page){
        this.fetchProducts(page, this.state.sort, this.props.fetchCount, this.onCallback.bind(this));
    }

    onCallback(page){
        this.setState({
            page: page + 1
        })
    }

    handleSort(sort) {
        this.setState({
            sort: sort,
            page: 1,
            products: []
        });

        this.props.brandSortChanged();

        if(this.props.isInfinite)
            this.refs.infiniteScroll.pageLoaded = 0;
        
        this.fetchProducts(1, sort, this.props.fetchCount)
    }

    handlePagination(event, selectedEvent) {
        this.loadProducts(selectedEvent.eventKey)
    }

    renderProducts(){
        var products = [];
        for (var i = 0; i < this.state.products.length; i++) {
            let product = this.state.products[i];
            products.push(<div key={product.prodVarId} className="col-xs-6 col-sm-4 col-md-3 product-list-item">
                <Product product={product}
                         saveProduct={this.props.saveProduct}
                         viewProduct={this.props.quickViewProduct}
                         checkIsLogged={this.props.checkIsLogged}
                         addCartItem={this.props.addCartItem}
                         returnTo={this.props.location.pathname} />
            </div>);
            {
                i == 3 && this.props.params.id == '201464253659957' &&
                products.push(<div className="col-xs-12 product-list-item" key="rothobanner">
                    <div className="product">
                        <a href={this.props.href}>
                            <div className="shop-banner">
                                <img className="img-responsive" src="/images/rotho_banner_gift.jpg"
                                     alt="Rotho"/>
                            </div>
                        </a>
                    </div>
                </div>)
            }
        }
        return products;
    }

    renderPagination(){

        let pageCount = Math.ceil(this.props.total/this.props.fetchCount);
        if(pageCount < 1)
            return null;
        return (
            <div className="text-center margin-top">
                 <Pagination 
                    prev={<i className="zmdi zmdi-chevron-left"></i>}
                    next={<i className="zmdi zmdi-chevron-right"></i>}
                    ellipsis
                    items={pageCount}
                    maxButtons={5}
                    activePage={this.state.page}
                    onSelect={this.handlePagination.bind(this)}/>
            </div>
        )
    }

    renderInfinite(options){
        return (
            <InfiniteScroll 
                ref="infiniteScroll"
                pageStart={this.state.page - 1} 
                loadMore={this.props.isLoading ? (page) => {this.refs.infiniteScroll.pageLoaded = page - 1} : this.loadProducts.bind(this)}
                hasMore={this.state.hasMore}
                loader={<Preloader/>}>
                <Masonry className="row" options={options}>
                    {this.renderProducts()}
                </Masonry>
            </InfiniteScroll>
        )
    }

    renderNonInfinite(options){
        return (            
            <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12">
                    <Masonry className="row" options={options}>
                      {this.renderProducts()}
                    </Masonry>
                </div>
                { this.renderPagination() }
            </div>
        )
    }

    render(){
        let options = {
          itemSelector: '.product-list-item',
          gutter: 0,
          transitionDuration: 0
        },
        title = this.props.location.query.name || '',
        paths = [{
            href: config.context + 'brand',
            text: 'Брэнд'
        }, title];

        localStorage.pathName = JSON.stringify([{href: config.context + 'brand', text: 'Брэнд'}, {text: title, href: this.props.location.pathname + '?name=' + title}])

        return (
            <div className="margin-bottom">
                <Helmet title={title}
                    titleTemplate={config.titleTemplate}/>
                <div className="row">
                    <div className="col-xs-12 col-md-8">
                        <PathMenu paths={paths}/>
                    </div>
                    <div className="col-xs-12 col-md-4 text-right margin-bottom">
                        <SortControl handleSort={this.handleSort.bind(this)}/>
                    </div>
                </div>
                { this.props.isInfinite ? this.renderInfinite(options) : this.renderNonInfinite(options) }
            </div>
        )
    }
}

BrandContainer.propTypes = {
    fetchCount: PropTypes.number,
    isInfinite: PropTypes.bool
}

BrandContainer.defaultProps = {
    fetchCount: 8,
    isInfinite: true
}

export default connect(
    (state, props) => {
        return {
            products: typeof state.company.products === 'string' ? JSON.parse(state.company.products) : state.company.products,
            total: state.company.total,
            isLoading: state.company.isLoading,
            clearProducts: state.company.clearProducts
        } 
    },
    (dispatch) => bindActionCreators(Object.assign({}, productActions, companyActions, {addCartItem}), dispatch)
)(BrandContainer);