import React, { Component, PropTypes } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as productActions from '../actions/product';
import {showCart, addCartItem} from '../actions/cart';
import config from '../config';
import ProductDetail from '../components/ProductDetail';

class ProductModalContainer extends Component {
    constructor(props, context){
        super(props, context)
    }

    render() {
        return (
            <ProductDetail {...this.props} />
        );
    }
}

ProductModalContainer.contextTypes = {
    router: PropTypes.object.isRequired
}

export default connect(
    (state, props) => {
        return {
            product: typeof state.products.product === 'string' ? JSON.parse(state.products.product) : state.products.product
        } 
    },
    {
        ...productActions,
        addCartItem,
        showCart
    }
)(ProductModalContainer);
