import config from '../config';
import * as ActionTypes from '../constants/ActionTypes';
import { httpRequest } from '../helpers';

export function loadContents(page = 0, fetchCount = 10, callback) {
    return dispatch => {
        dispatch({type: ActionTypes.LOAD_BCONTENTS_REQUEST});
        httpRequest({
            url: config.baseUrl + '/api/bcontents',
            method: 'GET',
            data:  {
                page: page,
                fetchCount: fetchCount
            }
        }).then(function (contents) {
            dispatch({type: ActionTypes.LOAD_BCONTENTS_SUCCESS, contents: contents});
            callback && callback(page);
        }, function (error) {
            dispatch({type: ActionTypes.LOAD_BCONTENTS_ERROR, error: error});
        });
    };
}

export function loadContent(id) {
    return dispatch => {
        dispatch({type: ActionTypes.LOAD_BCONTENT_REQUEST});
        httpRequest({
            url: config.baseUrl + '/api/bcontent/' + id,
            method: 'GET',
        }).then(function (content) {
            dispatch({type: ActionTypes.LOAD_BCONTENT_SUCCESS, content: content});
        }, function (error) {
            dispatch({type: ActionTypes.LOAD_BCONTENT_ERROR, error: error});
        });
    };
}

export function saveReview(review, cb) {
    return dispatch => {
        httpRequest({
            url: config.baseUrl + '/api/bcontent/review',
            method: 'POST',
            data: review
        }).then(function (review) {
            dispatch({type: ActionTypes.LOAD_BCONTENT_REVIEW_SUCCESS});
            cb(review);
        }, function (error) {
            dispatch({type: ActionTypes.LOAD_BCONTENT_REVIEW_ERROR, error: error});
        });
    };
}

export function loadSideContents(id) {
    return dispatch => {
        dispatch({type: ActionTypes.LOAD_SIDE_BCONTENTS_REQUEST});
        httpRequest({
            url: config.baseUrl + '/api/bcontents/side',
            method: 'GET',
            data: {id}
        }).then(function (contents) {
            dispatch({type: ActionTypes.LOAD_SIDE_BCONTENTS_SUCCESS, contents: contents});
        }, function (error) {
            dispatch({type: ActionTypes.LOAD_SIDE_BCONTENTS_ERROR, error: error});
        });
    };
}