import React, {Component} from 'react';
import { Link } from 'react-router';
import toastr from 'toastr';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import config from '../../config';
import { httpRequest, formatCurrency } from '../../helpers';
import Preloader from '../Preloader';

class OrderList extends Component {
	constructor(props) {
		super(props);

        this.state = {
            loading: true,
            orders: [],
            current: null
        };
	}

    componentDidMount() {
        this.props.loadOrderList();
    }

    componentWillReceiveProps(nextProps) {  
        this.setState({
            orders: nextProps.orders,
            loading: false
        });
    }

    handleAddCart(productVarId, qty) {
        this.props.addCartItem(productVarId, qty);
    }

    productTile(product) {
        if (product == null) {
            return;
        }

        return product != null && (
            <div className="product-tile" key={product.productVarId}>
                <div className="row">
                    <div className="col-md-3">
                        <img src={config.cdnUrl + 'img/' + product.photoId + '?w=100'} 
                            alt={product.productName}
                            style={{width: '100%'}}/>
                    </div>
                    <div className="col-md-9">
                        <h4 className="product-name">{product.productName}</h4>
                        <table className="table table-condensed">
                            <tbody>
                                <tr>
                                    <td className="text-bold vertical-middle">Код</td>
                                    <td className="vertical-middle">{product.productCode}</td>
                                </tr>
                                <tr>
                                    <td className="text-bold vertical-middle">Нийлүүлэгч</td>
                                    <td className="vertical-middle">{product.customerName}</td>
                                </tr>
                                <tr>
                                    <td className="text-bold vertical-middle">Хэмжээ</td>
                                    <td className="vertical-middle">{product.sizeText}</td>
                                </tr>
                                <tr>
                                    <td className="text-bold vertical-middle">Тоо</td>
                                    <td className="vertical-middle">{product.productQuantity}</td>
                                </tr>
                                <tr>
                                    <td className="text-bold vertical-middle">Үнэ</td>
                                    <td className="vertical-middle">{formatCurrency(product.unitPriceSold) + '₮'}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="text-center margin-top">
                    <a href="javascript:;" className="btn btn-primary" 
                        onClick={this.handleAddCart.bind(this, product.productVarId, product.productQuantity)}>
                        <i className="zmdi zmdi-shopping-cart"></i> Сагсанд нэмэх
                    </a>
                    <Link to={`${config.context}product/${product.productVarId}`} 
                        className="btn btn-default"
                        style={{marginLeft: 10}}>
                        Дэлгэрэнгүй
                    </Link>
                </div>
            </div>
        );
    }

    toggleDetails(index, event) {
        event.preventDefault();
        this.setState({current: this.state.current === index ? null : index});
    }

	render() {
        const {loading, orders, current} = this.state;

        let content;
        if (loading) {
            content = (
                <tbody>
                    <tr>
                        <td colSpan="6">
                            <Preloader/>
                        </td>
                    </tr>
                </tbody>
            );
        } else {
            if (orders.length === 0) {
                content = (
                    <tbody>
                    <tr>
                        <td colSpan="6">
                            <div className="text-center">Захиалга байхгүй байна.</div>
                        </td>
                    </tr>
                    </tbody>
                );
            } else {
                content = (
                    <tbody>
                    {orders.map((order, index) => {
                        let content = [
                            <tr>
                                <td className="col-1">{order.statusName}</td>
                                <td className="col-2">
                                    {order.orderedDate.replace(/^(\d+)\/(\d+)\/(\d+) (\d+:\d+).*/, '$1.$2.$3 $4')}
                                </td>
                                <td className="col-3">{order.orderNumber}</td>
                                <td className="col-4">{order.paymentStatusName}</td>
                                <td className="col-6">
                                    <a href="" onClick={this.toggleDetails.bind(this, index)}>
                                        <i className={current === index ? 'zmdi zmdi-chevron-up' : 'zmdi zmdi-chevron-down'}></i>
                                    </a>
                                </td>
                            </tr>
                        ];

                        let details = [];
                        if (current === index) {
                            details.push(
                                <td colSpan="6" key={0} className="details">
                                    <div className="row">
                                        { order.orderDtls.map(orderDtl => (
                                            <div className="col-xs-12 col-md-6 margin-bottom">
                                                { this.productTile(orderDtl) }
                                            </div>
                                            ))
                                        }
                                    </div>
                                </td>
                            );
                        }

                        content.push(
                            <ReactCSSTransitionGroup component="tr" transitionName="collapse"
                                                     transitionEnterTimeout={500} transitionLeaveTimeout={300}>
                                {details}
                            </ReactCSSTransitionGroup>
                        );

                        return content;
                    }).reverse()}
                    </tbody>
                );
            }
        }

        return (
            <div className = "info-container info-main-container">
                <div className="row">
                    <div className="col-md-8 text-left">
                        <h3>Захиалга хянах</h3>
                    </div>
                </div>
                <hr/>
                <div className="table-responsive">
                    <table className="order-list">
                        <thead>
                        <tr>
                            <th className="col-1">Төлөв</th>
                            <th className="col-2">Захиалга өгсөн огноо</th>
                            <th className="col-3">Захиалгын дугаар</th>
                            <th className="col-4">Төлбөрийн төлөв</th>
                            <th className="col-6" />
                        </tr>
                        </thead>
                        {content}
                    </table>
                </div>
            </div>
        );
    }
}

export default OrderList;
