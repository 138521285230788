import {getBuilding} from '../persistCart';

import {
    CART_UPDATING,
    UPDATE_CART,
    SHOW_CART,
    HIDE_CART,
    CART_UPDATE_FAILED,
    GET_PAYMENT_OPTIONS,
    CART_ITEM_UPDATING,
    CART_ITEM_UPDATE_FAILED,
    SET_BUILDING,
    GET_SHIPPING_PRICE,
    GET_DISTRICT_LIST,
    GET_KHOROO_LIST,
    GET_BUILDING_LIST
} from '../constants/ActionTypes';

const initialState = {
    orderNumber: undefined,
    ordersId: undefined,
    totalAmount: 0,
    totalPayAmount: 0,
    totalDiscount: 0,
    show: false,
    ongoingUpdates: 0,
    items: [],
    paymentOptions: null,
    building: getBuilding(),
    shippingPrice: null
};

function cart(state = initialState, action) {
    switch (action.type) {
        case CART_UPDATING:
            return {
                ...state,
                ongoingUpdates: state.ongoingUpdates + 1
            };

        case UPDATE_CART:
            return {
                ...state,
                ...action.data,
                ongoingUpdates: state.ongoingUpdates - 1
            };

        case SHOW_CART:
            return {
                ...state,
                show: true
            };

        case HIDE_CART:
            return {
                ...state,
                show: false
            };

        case CART_UPDATE_FAILED:
            return {
                ...state,
                ongoingUpdates: state.ongoingUpdates - 1
            } ;

        case GET_PAYMENT_OPTIONS:
            return {
                ...state,
                paymentOptions: action.paymentOptions
            };

        case CART_ITEM_UPDATING: {
            let index = state.items.findIndex(item => item.orderDtlId === action.orderDtlId);
            if (index !== -1) {
                return {
                    ...state,
                    items: [
                        ...state.items.slice(0, index),
                        {
                            ...state.items[index],
                            updating: true
                        },
                        ...state.items.slice(index + 1)
                    ],
                    ongoingUpdates: state.ongoingUpdates + 1
                };
            } else {
                return {
                    ...state,
                    ongoingUpdates: state.ongoingUpdates + 1
                };
            }
        }

        case CART_ITEM_UPDATE_FAILED: {
            let index = state.items.findIndex(item => item.orderDtlId === action.orderDtlId);
            if (index !== -1) {
                return {
                    ...state,
                    items: [
                        ...state.items.slice(0, index),
                        {
                            ...state.items[index],
                            updating: false
                        },
                        ...state.items.slice(index + 1)
                    ],
                    ongoingUpdates: state.ongoingUpdates - 1
                };
            } else {
                return {
                    ...state,
                    ongoingUpdates: state.ongoingUpdates - 1
                };
            }
        }


        case SET_BUILDING:
            return {
                ...state,
                building: action.building
            };

        case GET_SHIPPING_PRICE: {
            return {
                ...state,
                shippingPrice: action.shippingPrice
            };
        }

        case GET_DISTRICT_LIST: {
            return {
                ...state,
                districts: action.districts
            };
        }

        case GET_KHOROO_LIST: {
            return {
                ...state,
                khoroos: action.khoroos
            };
        }

        case GET_BUILDING_LIST: {
            return {
                ...state,
                buildings: action.buildings
            };
        }

        default:
            return state;
    }
}

export default cart;
