var React = require('react');
import {setBuilding, getShippingPrice, getDeliveryScope, getDistrictList, getKhorooList, getBuildingList} from '../../actions/cart';
import config from '../../config';
import {formatCurrency} from '../../helpers';
import FieldError from '../../components/FieldError';
import AddressChooser from '../../components/AddressChooser';
import CartItemContainer from '../../containers/CartItemContainer';
import {getFormValues} from '../../persistCart';
import { reduxForm, getValues, change } from 'redux-form'
import toastr from 'toastr';
export const fields = [
    'email', 'receiver', 'miCode', 'addressDescription', 'additionalAddressDescription', 'entrance', 'apartmentSelect', 'apartmentInput', 'door',
    'phoneNumber', 'additionalPhoneNumber', 'deliveryTypeId', 'district', 'khoroo' ];

const validate = values => {
    const errors = {};

    if (typeof values.receiver == 'undefined' || values.receiver === '') {
        errors.receiver = 'Захиалагчын нэр оруулна уу.';
    }

    if (typeof values.email == 'undefined' || values.email === '') {
        errors.email = 'Имэйл хаяг оруулна уу.';
    } else if (!/^[+a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(values.email)) {
        errors.email = 'Имэйл хаягаа зөв оруулна уу.';
    }

    if (values.addressDescription === '') {
        errors.addressDescription = 'Хаягаа сонгоно уу.';
    }

    if (typeof values.district == 'undefined' || values.district === '') {
        errors.district = 'Дүүрэг сонгоно уу.';
    }

    if (typeof values.khoroo == 'undefined' || values.khoroo === '') {
        errors.khoroo = 'Хороо сонгоно уу.';
    }

    if (values.apartmentSelect === '' && values.apartmentInput === '') {
        errors.apartmentSelect = 'Байр сонгоно уу.';
    }

    if (values.apartmentSelect === '' && values.apartmentInput === '') {
        errors.apartmentInput = 'Байрны дугаараа оруулна уу.';
    }

    /*if (values.entrance === '') {
        errors.entrance = 'Орцны дугаараа оруулна уу.';
    }*/

    if (typeof values.door == 'undefined' || values.door === '') {
        errors.door = 'Хаалганы дугаараа оруулна уу.';
    }

    if (values.phoneNumber == null || values.phoneNumber === '') {
        errors.phoneNumber = 'Утасны дугаараа оруулна уу.';
    } else if (!/^\s*\d{6,8}\s*$/.test(values.phoneNumber)) {
        errors.phoneNumber = 'Утасны дугаар 6-8 оронтой тоо байна.';
    }

    if (values.additionalPhoneNumber === '') {
        // errors.additionalPhoneNumber = 'Нэмэлт утасны дугаараа оруулна уу.';
    } else if (values.phoneNumber === values.additionalPhoneNumber) {
        errors.additionalPhoneNumber = 'Утасны дугаарууд адилхан байж болохгүй.';
    } else if (!/^\s*\d{6,8}\s*$/.test(values.additionalPhoneNumber)) {
        errors.additionalPhoneNumber = 'Утасны дугаар 6-8 оронтой тоо байна.';
    }

    return errors;
};

var ShippingFields = React.createClass({
    render: function () {
        const {
            fields: {
                miCode,
                receiver,
                email,
                phoneNumber,
                additionalPhoneNumber,
                district,
                khoroo,
                apartmentSelect,
                apartmentInput,
                entrance,
                door,
                addressDescription,
                additionalAddressDescription,
                deliveryTypeId
            },
            handleSubmit
        } = this.props;
        const building = this.props.cart.building;

        let deliveryPrice = this.props.cart.shippingPrice;
        var cart = this.props.cart;
        return (
            <div className="row">
                <div className="col-md-8 col-xs-12">
                    <div className="order-form">
                        <h3>2. Хаягийн мэдээлэл</h3>
                        <form onSubmit={handleSubmit}
                              ref="orderForm">
                            <input type="hidden" name="_token" value={config.csrfToken}/>
                            <input type="hidden" name="ordersId" value={cart.ordersId}/>
                            <input type="hidden" name="buildingId" value={building ? building.buildingId : ''}/>
                            <input type="hidden" name="deliveryScopeId"
                                   value={building ? building.delivery.scopeId : ''}/>
                            <input type="hidden" name="lat" value={building ? building.lat : ''}/>
                            <input type="hidden" name="lng" value={building ? building.lng : ''}/>
                            <input type="hidden" name="deliveryTypeId" {...deliveryTypeId} />
                            <div className="form-section">
                                <div className="form-horizontal">
                                    <div className={"form-group"+(miCode.touched && miCode.error ? ' has-error':'')}>
                                        <label htmlFor="cart-mi-code" className="control-label col-sm-3">Виртуал хаяг:</label>
                                        <div className="col-sm-3">
                                            <input type="text" className="form-control" id="cart-mi-code"
                                                {...miCode} ref="miCode"/>
                                            <FieldError field={miCode} />
                                        </div>
                                        <div className="col-sm-6">
                                            Энэхүү дугаарлалт нь таны сонгосон байршлын Виртуал хаяг юм. (эхний хоёр үсэг дүүргийн
                                            товчлол, дараагийн хоёр тоо тухайн хороо)
                                        </div>
                                    </div>
                                </div>
                                <AddressChooser id="cart-address-chooser" buildingChosen={this.buildingChosen}
                                                building={building} />


                                <div className="form-horizontal">
                                    <div className={"form-group"+(receiver.touched && receiver.error ? ' has-error':'')}>
                                        <label htmlFor="cart-receiver" className="control-label col-sm-3">Захиалагч <span className="field-error">*</span>:</label>
                                        <div className="col-sm-5">
                                            <input type="text" className="form-control" id="cart-receiver"
                                                   placeholder="Захиалагчийн нэр оруулна уу" {...receiver} />
                                        </div>
                                        <div className="col-sm-4">
                                            <FieldError field={receiver} />
                                        </div>
                                    </div>
                                    <div className={"form-group"+(email.touched && email.error ? ' has-error':'')}>
                                        <label htmlFor="cart-receiver" className="control-label col-sm-3">Имэйл хаяг <span className="field-error">*</span>:</label>
                                        <div className="col-sm-5">
                                            <input type="text" className="form-control" id="cart-receiver"
                                                   placeholder="Таны оруулсан имэйл хаягаар НӨАТ-н сугалааны баримт илгээнэ" {...email} />
                                        </div>
                                        <div className="col-sm-4">
                                            <FieldError field={email} />
                                        </div>
                                    </div>
                                    <div className={"form-group"+(phoneNumber.touched && phoneNumber.error ? ' has-error':'')}>
                                        <label htmlFor="cart-phone1" className="control-label col-sm-3">Утасны дугаар <span className="field-error">*</span>:</label>
                                        <div className="col-sm-5">
                                            <input type="text" className="form-control" id="cart-phone1"
                                                   placeholder="Энд бичнэ үү" {...phoneNumber} />
                                        </div>
                                        <div className="col-sm-4">
                                            <FieldError field={phoneNumber} />
                                        </div>
                                    </div>
                                    <div className={"form-group"+(additionalPhoneNumber.touched && additionalPhoneNumber.error ? ' has-error':'')}>
                                        <label htmlFor="cart-phone2" className="control-label col-sm-3">
                                            Нэмэлт утасны дугаар:</label>
                                        <div className="col-sm-5">
                                            <input type="text" className="form-control" id="cart-phone2"
                                                   placeholder="Энд бичнэ үү" {...additionalPhoneNumber} />
                                        </div>
                                        <div className="col-sm-4">
                                            <FieldError field={additionalPhoneNumber} />
                                        </div>
                                    </div>
                                </div>

                                <div className="form-horizontal">
                                    <div className={"form-group"+(district.touched && district.error ? ' has-error':'')}>
                                        <label htmlFor="cart-address-district" className="control-label col-sm-3">
                                            Дүүрэг <span className="field-error">*</span>:</label>
                                        <div className="col-sm-5">
                                            <select className="form-control" id="cart-address-district"
                                                value={building ? building.districtId : ''}
                                                {...district} onChange={(evt) => {
                                                    district.onChange(evt);
                                                    if(evt.target.value != '') {
                                                        this.props.getKhorooList(evt.target.value);
                                                    }
                                                }} ref="duureg">
                                                <option value="">Сонгоно уу</option>
                                                {
                                                    typeof this.props.districts != 'undefined' &&
                                                    this.props.districts.map(d => <option key={d.district_id} value={d.district_id}>{d.name}</option>)
                                                }
                                            </select>
                                        </div>
                                        <div className="col-sm-4">
                                            <FieldError field={district} />
                                        </div>
                                    </div>
                                    <div className={"form-group"+(khoroo.touched && khoroo.error ? ' has-error':'')}>
                                        <label htmlFor="cart-address-khoroo" className="control-label col-sm-3">
                                            Хороо <span className="field-error">*</span>:</label>
                                        <div className="col-sm-5">
                                            <select type="text" className="form-control" id="cart-address-khoroo"
                                                value={building ? building.khorooId : ''}
                                                {...khoroo} onChange={(evt) => {
                                                    khoroo.onChange(evt);
                                                    if(evt.target.value != '') {
                                                        this.props.getBuildingList(evt.target.value);
                                                    }
                                                }} ref="khoroo">
                                                <option value="">Сонгоно уу</option>
                                                {
                                                    typeof this.props.khoroos != 'undefined' &&
                                                        this.props.khoroos.map(kh =>
                                                        <option key={kh.khoroo_id} value={kh.khoroo_id}>{parseInt(kh.micode)}-р хороо</option>)
                                                }
                                            </select>
                                        </div>
                                        <div className="col-sm-4">
                                            <FieldError field={khoroo} />
                                        </div>
                                    </div>
                                    <div className={"form-group"+(apartmentSelect.touched && apartmentSelect.error ? ' has-error':'')} style={{marginBottom:0}}>
                                        <label htmlFor="cart-apartment-select" className="control-label col-sm-3">Байр <span className="field-error">*</span>:</label>
                                        <div className="col-sm-5">
                                            <select type="text" className="form-control" id="cart-apartment-select"
                                                    value={building ? building.buildingId : ''}
                                                    {...apartmentSelect} onChange={(evt) => {
                                                        apartmentSelect.onChange(evt);
                                                        if(evt.target.value != '') {
                                                            let selectedBuilding = this.props.buildings.filter(b => b.building_id === evt.target.value)[0];
                                                            let selectedDistrict = this.props.districts.filter(d=>d.district_id === this.refs.duureg.value)[0];
                                                            let selectedKhoroo = this.props.khoroos.filter(d=>d.khoroo_id === this.refs.khoroo.value)[0];
                                                            let point = JSON.parse(selectedBuilding.geojson);
                                                            let addr = selectedDistrict.name + ', '
                                                                     + parseInt(selectedKhoroo.micode) + '-р хороо, '
                                                                     + selectedBuilding.display_name
                                                            ;
                                                            this.props.dispatch(change('cartForm', 'addressDescription', addr));
                                                            this.props.dispatch(change('cartForm', 'miCode', selectedBuilding.micode));
                                                            this.props.cart.building = Object.assign({},building,{
                                                                address: addr,
                                                                lat: point.coordinates[1],
                                                                lng: point.coordinates[0],
                                                                miCode: selectedBuilding.micode,
                                                                districtId: selectedDistrict.district_id,
                                                                khorooId: selectedKhoroo.khoroo_id,
                                                                buildingId: selectedBuilding.building_id,
                                                                delivery: {
                                                                    scopeName: selectedBuilding.delivery_scope_name,
                                                                    scopeId: selectedBuilding.delivery_scope_id,
                                                                    prices: []
                                                                }
                                                            });
                                                        }
                                                    }} ref="apartmentSelect">
                                                <option value="">Сонгоно уу</option>
                                                {
                                                    typeof this.props.buildings != 'undefined' &&
                                                    this.props.buildings.map(b =>
                                                        <option key={b.building_id} value={b.building_id}>{b.building_number ? b.building_number + ' - ' + b.building_name : b.display_name}</option>)
                                                }
                                            </select>
                                        </div>
                                        <div className="col-sm-4">
                                            <FieldError field={apartmentSelect} />
                                        </div>
                                    </div>
                                    <div className={"form-group"+(apartmentInput.touched && apartmentInput.error ? ' has-error':'')}>
                                        <div className="col-sm-3">
                                            &nbsp;
                                        </div>
                                        <div className="col-sm-5">
                                            <input type="text" className="form-control" id="cart-apartment-input"
                                                   placeholder="Сонголтонд байхгүй бол энд бичнэ үү" {...apartmentInput}
                                                    onBlur={(evt)=>{
                                                        apartmentInput.onBlur(evt);
                                                        if(evt.target.value != '') {
                                                            if(this.refs.apartmentSelect.value == '' && this.refs.duureg.value != '' && this.refs.khoroo.value != '') {
                                                                let selectedDistrict = this.props.districts.filter(d=>d.district_id === this.refs.duureg.value)[0];
                                                                let selectedKhoroo = this.props.khoroos.filter(d=>d.khoroo_id === this.refs.khoroo.value)[0];
                                                                let addr = selectedDistrict.name + ', '
                                                                         + parseInt(selectedKhoroo.micode) + '-р хороо, '
                                                                         + evt.target.value
                                                                ;
                                                                this.props.dispatch(change('cartForm', 'addressDescription', addr));
                                                                if (typeof selectedKhoroo.delivery_scope_id == 'undefined'
                                                                    || selectedKhoroo.delivery_scope_id == null
                                                                    || selectedKhoroo.delivery_scope_id == '') {
                                                                    toastr.info('Сонгосон хороонд хүргэлт хийх боломжгүй');
                                                                } else {
                                                                    this.props.getShippingPrice(this.props.cart.ordersId, '1', selectedKhoroo.delivery_scope_id);
                                                                    this.props.cart.building = Object.assign({},building,{
                                                                        address: addr,
                                                                        lat: 0,
                                                                        lng: 0,
                                                                        miCode: '',
                                                                        districtId: selectedDistrict.district_id,
                                                                        khorooId: selectedKhoroo.khoroo_id,
                                                                        buildingId: '',
                                                                        delivery: {
                                                                            scopeName: selectedKhoroo.delivery_scope_name,
                                                                            scopeId: selectedKhoroo.delivery_scope_id,
                                                                            prices: []
                                                                        }
                                                                    });
                                                                }
                                                            }
                                                        }
                                                    }}/>
                                        </div>
                                        <div className="col-sm-4">
                                            <FieldError field={apartmentInput} />
                                        </div>
                                    </div>
                                    <div className={"form-group"+(entrance.touched && entrance.error ? ' has-error':'')}>
                                        <label htmlFor="cart-entrance" className="control-label col-sm-3">Орц:</label>
                                        <div className="col-sm-5">
                                            <input type="text" className="form-control" id="cart-entrance"
                                                   placeholder="Энд бичнэ үү" {...entrance} />
                                        </div>
                                        <div className="col-sm-4">
                                            <FieldError field={entrance} />
                                        </div>
                                    </div>
                                    <div className={"form-group"+(door.touched && door.error ? ' has-error':'')}>
                                        <label htmlFor="cart-door" className="control-label col-sm-3">Хаалга <span className="field-error">*</span>:</label>
                                        <div className="col-sm-5">
                                            <input type="text" className="form-control" id="cart-door"
                                                   placeholder="Энд бичнэ үү" {...door} />
                                        </div>
                                        <div className="col-sm-4">
                                            <FieldError field={door} />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-horizontal">
                                    <div className={"form-group"+(addressDescription.touched && addressDescription.error ? ' has-error':'')}>
                                        <label htmlFor="cart-address-description" className="control-label col-sm-3">
                                            Дэлгэрэнгүй:</label>
                                        <div className="col-sm-5">
                                            <textarea type="text" className="form-control" id="cart-address-description"
                                                {...addressDescription} ref="addressDescription" />
                                        </div>
                                        <div className="col-sm-4">
                                            <FieldError field={addressDescription} />
                                        </div>
                                    </div>
                                    <div className={"form-group"+(additionalAddressDescription.touched && additionalAddressDescription.error ? ' has-error':'')}>
                                        <label htmlFor="cart-additional-address-description" className="control-label col-sm-3">
                                            Нэмэлт хаягийн мэдээлэл:</label>
                                        <div className="col-sm-5">
                                            <input type="text" id="cart-additional-address-description"
                                                   className="form-control" {...additionalAddressDescription}
                                                   placeholder="Орцны кодтой бол энд бичнэ үү" />
                                        </div>
                                        <div className="col-sm-4">
                                            <FieldError field={additionalAddressDescription} />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-horizontal">
                                    <div className="form-group text-center">
                                        <span className="field-error">*</span>-той хэсгийн заавал бөглөнө үү.
                                    </div>
                                </div>
                                <div className="form-horizontal">
                                    <div className="form-group text-center">
                                        Таны захиалга 24 цагийн дотор дээрх хаягаар хүргэгдэнэ.
                                    </div>
                                </div>
                            </div>
                        </form>
                        <div className="col-md-12 margin-top margin-bottom text-center">
                            <button type="submit" className="btn btn-default " onClick={this.previousStep}>
                                Буцах
                            </button>
                            <button type="submit" className="btn btn-primary" onClick={this.handleSubmit}>
                                Үргэлжлүүлэх
                            </button>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 hidden-xs sticky cart-items-mini">
                    <div className="sidebar">
                        { this.renderMiniCart(this.props.cart)}
                    </div>
                </div>
            </div>
        )
    },

    renderMiniCart: function(cart) {
        let discount = parseInt(cart.totalDiscount);
        return (
            <table className="table table-bordered">
                <thead>
                <tr>
                    <th colSpan="3">Захиалгын мэдээлэл</th>
                    <th><button type="button" className="btn btn-info btn-xs btn-expand" onClick={(evt)=>{
                                    this.setState({hideCartDetail: !this.state.hideCartDetail})
                                }}>{this.state.hideCartDetail?'Дэлгэрэнгүй':'Хураангуй'}</button></th>
                </tr>
                </thead>
                <tbody hidden={this.state.hideCartDetail}>
                {cart.items.map((item,index) => <CartItemContainer key={item.orderDtlId} item={item} simple={true} />)}
                </tbody>
                <tfoot>
                <tr>
                    <td colSpan="3">Барааны нийт үнэ:</td>
                    <td>{formatCurrency(cart.totalAmount) + '₮'}</td>
                </tr>
                { !isNaN(discount) && discount
                    ?
                    <tr>
                        <td colSpan="3">Хямдрал:</td>
                        <td>{'-' + formatCurrency(discount) + '₮'}</td>
                    </tr>
                    : null
                }
                <tr>
                    <td colSpan="3">Хүргэлт:</td>
                    <td>{ this.props.cart.shippingPrice !== null
                            ? (this.props.cart.shippingPrice === 0 ? 'Үнэгүй' :
                              formatCurrency(this.props.cart.shippingPrice) + '₮')
                            : '-'
                        }</td>
                </tr>
                <tr className="items-total">
                    <th colSpan="3">Нийт үнэ:</th>
                    <th>{formatCurrency(parseInt(this.props.cart.totalPayAmount) + this.props.cart.shippingPrice) + '₮'}</th>
                </tr>
                </tfoot>
            </table>
        )
    },

    handleSubmit: function(event) {
        this.props.touchAll();

        if (this.props.invalid || this.props.cart.ongoingUpdates > 0) {
            event.preventDefault();
        } else {
            this.props.nextStep(event);
        }
    },

    previousStep: function(e) {
        e.preventDefault();

        this.props.previousStep(e);
    },

    buildingChosen: function(building) {
        this.props.setBuilding(building);

        if (this.props.cart.building && this.props.cart.ordersId) {
            this.props.getShippingPrice(this.props.cart.ordersId, '1', building.delivery.scopeId);
        } else {
            this.props.cart.shippingPrice = 5000;
        }
        if (building) {
            this.props.dispatch(change('cartForm', 'miCode', building.miCode));
            this.props.dispatch(change('cartForm', 'addressDescription', building.address));
            this.props.dispatch(change('cartForm', 'district', building.districtId));
            this.props.dispatch(change('cartForm', 'khoroo', building.khorooId));
            this.props.dispatch(change('cartForm', 'apartmentSelect', building.buildingId));
        } else {
            this.props.dispatch(change('cartForm', 'miCode', ''));
            this.props.dispatch(change('cartForm', 'addressDescription', ''));
            this.props.dispatch(change('cartForm', 'district', ''));
            this.props.dispatch(change('cartForm', 'khoroo', ''));
            this.props.dispatch(change('cartForm', 'apartmentSelect', ''));
        }
    },

    componentWillMount: function() {
        if(!this.props.districts) {
            this.props.getDistrictList();
        }
        if (this.state == null) {
            this.setState({
                hideCartDetail: false
            })
        }
    },

    componentDidMount: function() {
        $('.sticky')
            .theiaStickySidebar({
                additionalMarginTop: 60
            });
        if(this.props.cart.building) {
            this.buildingChosen(this.props.cart.building);
        }
    }
});

export default reduxForm(
    {
        form: 'cartForm',
        fields,
        initialValues: {
            email: global.baby.userEmail ? global.baby.userEmail : '',
            miCode: '',
            receiver: '',
            phoneNumber: '',
            additionalPhoneNumber: '',
            district: '',
            khoroo: '',
            apartmentSelect: '',
            apartmentInput: '',
            entrance: '',
            door: '',
            addressDescription: '',
            additionalAddressDescription: '',
            deliveryTypeId: '1',
            ...getFormValues()
        },
        destroyOnUnmount: false,
        validate: validate
    },
    state => {
        return {
            cart: state.cart,
            districts: state.cart.districts,
            khoroos: state.cart.khoroos,
            buildings: state.cart.buildings
        }
    },
    {getDistrictList, getKhorooList, getBuildingList, getShippingPrice, getDeliveryScope, setBuilding}
)(ShippingFields)