import React, { Component } from 'react';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { Modal } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import * as btagActions from '../actions/btag';
import * as productActions from '../actions/product';
import {addCartItem} from '../actions/cart';
import config from '../config';
import Product from '../components/Product';
import PathMenu from '../components/PathMenu';
import Masonry from 'react-masonry-component';
import Category from '../components/Category';
import Brand from '../components/Brand';
import PriceFilter from '../components/PriceFilter';

class BtagProductListContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filter: {
                price: undefined
            }
        };
    }

    componentDidMount() {
        this.props.loadBtagProducts(this.props.params.id);
    }

    handlePriceFilterChange(e) {
        let prices = e.target.value;
        let filter = {price:{min: prices[0], max: prices[1]}};
        this.setState({
            filter: filter
        });

        this.props.filterChanged();

        this.props.loadBtagProducts(this.props.params.id, Object.assign(this.props.location.query,{lp:filter.price.min, hp: filter.price.max}));
    }

    renderProducts(){
        return this.props.products.map(product => (
            <div key={product.prodVarId} className="col-xs-6 col-sm-6 col-md-4 product-list-item">
                <Product key={product.id} 
                            product={product}
                            addCartItem={this.props.addCartItem}
                            saveProduct={this.props.saveProduct} 
                            viewProduct={this.props.quickViewProduct} 
                            checkIsLogged={this.props.checkIsLogged}
                            returnTo={this.props.location.pathname}/>
            </div>
                )
            )
    }

    render() {
        let options = {
          itemSelector: '.product-list-item',
          gutter: 0,
          transitionDuration: 0
        }, title = this.props.params.name || '',
        paths = [];

        if(title.length == 0) {
            let queryParams = this.props.location.query;
            if (typeof queryParams.name != 'undefined') {
                title = queryParams.name;
            }
        }
        paths.push(title);

        localStorage['pathName'] = JSON.stringify([{text: title, href: this.props.location.pathname + '/' + title}])

        return (
            <div>
                <Helmet title={title}
                    titleTemplate={config.titleTemplate}/>
                <PathMenu paths={paths}/>
                <div className="row">
                    <div className="col-xs-12 col-sm-3 col-md-3 product-filter-container hidden-xs" id="filterContainer">
                        <Category {...this.props}/>
                        <Brand {...this.props}/>
                        <PriceFilter handleChange={this.handlePriceFilterChange.bind(this)} {...this.props}/>
                    </div>
                    <div className="col-xs-12 col-sm-9 col-md-9 product-list-container">
                        <Masonry className="row" options={options}>
                            { this.renderProducts() }
                        </Masonry>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(
    (state, props) => {
        return {
            products: typeof state.btag.products === 'string' ? JSON.parse(state.btag.products) : state.btag.products,
            categories: [],
            brands: typeof state.products.brands === 'string' ? JSON.parse(state.products.brands) : state.products.brands,
            prices: typeof state.products.prices === 'string' ? JSON.parse(state.products.prices) : state.products.prices
        }
    },
    (dispatch) => bindActionCreators(Object.assign({}, productActions, btagActions, {addCartItem}), dispatch)
)(BtagProductListContainer);