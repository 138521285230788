import toastr from 'toastr';
import config from '../config';
import * as ActionTypes from '../constants/ActionTypes';
import { httpRequest } from '../helpers';

export function cartUpdating() {
    return {type: ActionTypes.CART_UPDATING};
}

export function cartUpdateFailed() {
    return {type: ActionTypes.CART_UPDATE_FAILED};
}

export function cartItemUpdateFailed(orderDtlId) {
    return {type: ActionTypes.CART_ITEM_UPDATE_FAILED, orderDtlId};
}

export function showCart() {
    return {type: ActionTypes.SHOW_CART};
}

export function hideCart() {
    return {type: ActionTypes.HIDE_CART};
}

export function loadCartData(subAction = false) {
    return dispatch => {
        if (!subAction) {
            dispatch(cartUpdating());
        }

        httpRequest({
            url: config.baseUrl + '/api/cart/get',
            method: 'GET'
        })
            .then(function (cart) {
                let data = {
                    orderNumber: undefined,
                    ordersId: undefined,
                    totalAmount: 0,
                    totalPayAmount: 0,
                    totalDiscount: 0,
                    items: []
                };

                if (cart.length > 0) {
                    let firstItem = cart[0];

                    data.orderNumber = firstItem.orderNumber;
                    data.ordersId = firstItem.ordersId;
                    data.totalAmount = firstItem.totalAmount;
                    data.totalPayAmount = firstItem.totalPayAmount;
                    data.totalDiscount = firstItem.discountAmount;

                    for (let item of cart) {
                        data.items.push(item);
                    }
                    data.items.sort((a, b) => a.orderDtlId - b.orderDtlId);
                }

                dispatch({type: ActionTypes.UPDATE_CART, data});
            }, function () {
                toastr.error('Сагсыг шинэчилж чадсангүй.');
                dispatch(cartUpdateFailed());
            });
    };
}

export function addCartItem(productVarId, quantity, barcode) {
    return (dispatch, getState) => {
        dispatch(cartUpdating());

        httpRequest({
            url: config.baseUrl + '/api/cart/add-item',
            method: 'POST',
            data: {
                productVarId,
                quantity,
                barcode
            }
        })
            .then(function (data) {
                if (data.responseResultType === 'FAILURE') {
                    toastr.info(data.message.messages[0].message);
                    dispatch(cartUpdateFailed());
                } else {
                    toastr.success('Сагсанд нэмэгдлээ.');
                    dispatch(loadCartData(true));
                }
            }, function () {
                toastr.error('Сагсанд барааг нэмж чадсангүй.');
                dispatch(cartUpdateFailed());
            });
    };
}

export function deleteCartItem(orderDtlId) {
    return dispatch => {
        dispatch(cartUpdating());

        httpRequest({
            url: config.baseUrl + '/api/cart/delete-item',
            method: 'POST',
            data: { orderDtlId,
                '_token': config.csrfToken }
        })
            .then(function () {
                dispatch(loadCartData(true));
            }, function () {
                toastr.error('Сагснаас барааг устгаж чадсангүй.');
                dispatch(cartUpdateFailed());
            });
    };
}

export function updateCartItem(orderDtlId, productVarId, productQuantity) {
    return dispatch => {
        dispatch({type: ActionTypes.CART_ITEM_UPDATING, orderDtlId});

        httpRequest({
            url: config.baseUrl + '/api/cart/update-item',
            method: 'POST',
            data: {
                orderDtlId,
                productVarId,
                productQuantity,
                '_token': config.csrfToken
            }
        })
            .then(function (response) {
                if (response.responseResultType === 'FAILURE') {
                    toastr.warning(response.message.messages[0].message);
                    dispatch(cartItemUpdateFailed(orderDtlId));
                } else {
                    dispatch(loadCartData(true));
                }
            }, function () {
                toastr.error('Сагсанд байгаа барааг шинэчилж чадсангүй.');
                dispatch(cartItemUpdateFailed(orderDtlId));
            });
    };
}

export function getPaymentOptions() {
    return dispatch => {
        httpRequest({
            url: config.baseUrl + '/api/cart/get-payment-options',
            method: 'GET'
        })
            .then(function (data) {
                dispatch({ type: ActionTypes.GET_PAYMENT_OPTIONS, paymentOptions: data });
            }, function () {
                toastr.error('Төлбөрийн мэдээллийг серверээс авч чадсангүй.');
            });
    };
}

export function setBuilding(building) {
    return dispatch => {
        dispatch({type: ActionTypes.SET_BUILDING, building});
        dispatch(getKhorooList(building.districtId));
        dispatch(getBuildingList(building.khorooId));
    };
}

export function getShippingPrice(ordersId, deliveryTypeId, deliveryScopeId) {
    return dispatch => {
        httpRequest({
            url: config.baseUrl + '/api/cart/get-shipping-price',
            method: 'GET',
            data: {
                ordersId: ordersId,
                deliveryTypeId: deliveryTypeId,
                deliveryScopeId: deliveryScopeId
            }
        })
            .then(function (data) {
                dispatch({ type: ActionTypes.GET_SHIPPING_PRICE, shippingPrice: data });
            }, function () {
                toastr.error('Хүргэлтийн үнийн мэдээллийг серверээс авч чадсангүй.')
            });
    };
}

export function getDistrictList() {
    return dispatch => {
        httpRequest({
            url: config.baseUrl + '/api/cart/get-districts',
            method: 'GET'
        })
            .then(function (data) {
                dispatch({ type: ActionTypes.GET_DISTRICT_LIST, districts: data });
            }, function () {
                toastr.error('Дүүргийн мэдээллийг серверээс авч чадсангүй.');
            });
    };
}

export function getKhorooList(districtId) {
    return dispatch => {
        httpRequest({
            url: config.baseUrl + '/api/cart/get-khoroos?district_id=' + districtId,
            method: 'GET'
        })
            .then(function (data) {
                dispatch({ type: ActionTypes.GET_KHOROO_LIST, khoroos: data });
            }, function () {
                toastr.error('Хорооны мэдээллийг серверээс авч чадсангүй.');
            });
    };
}

export function getBuildingList(khorooId) {
    return dispatch => {
        httpRequest({
            url: config.baseUrl + '/api/cart/get-buildings?khoroo_id=' + khorooId,
            method: 'GET'
        })
            .then(function (data) {
                dispatch({ type: ActionTypes.GET_BUILDING_LIST, buildings: data });
            }, function () {
                toastr.error('Байрны мэдээллийг серверээс авч чадсангүй.');
            });
    };
}
