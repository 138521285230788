import React, { Component } from 'react';
import config from '../../config';

class Password extends Component {
	
	constructor(props){
		super(props);
	}

	render(){
    	     return (<div className = "info-container info-main-container">
                    <div className="row">
                    	<div className="col-md-8 text-left">
                    		<h3>Нууц үг солих</h3>
                    	</div>
                    </div>
                    <hr/>
                    <div className="row">
                        <div className="col-md-6">
                            <form className="form-horizontal">
                              <div className="form-group">
                                <label htmlFor="oldPassword" className="col-sm-5 control-label">Одоогийн нууц үг</label>
                                <div className="col-sm-7">
                                  <input type="password" className="form-control" id="oldPassword"/>
                                </div>
                              </div>                          
                              <div className="form-group">
                                <label htmlFor="newPassword" className="col-sm-5 control-label">Шинэ нууц үг</label>
                                <div className="col-sm-7">
                                  <input type="password" className="form-control" id="newPassword"/>
                                </div>
                              </div>
                              <div className="form-group">
                                <label htmlFor="repeatPassword" className="col-sm-5 control-label">Шинэ нууц үг давтах</label>
                                <div className="col-sm-7">
                                  <input type="password" className="form-control" id="repeatPassword" />
                                </div>
                              </div>
                              <div className="form-group">
                                <div className="col-sm-offset-5 col-sm-7">
                                  <button type="submit" className="btn btn-default btn-primary">Хадгалах</button>
                                  <button type="reset" className="btn btn-default" style={{marginLeft: 10}}>Болих</button>
                                </div>
                              </div>
                            </form>
                        </div>
                    </div>
                 </div>)
    }
}

export default Password;
