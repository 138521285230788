import React, { Component } from 'react';
import {formatCurrency} from '../helpers';
import config from '../config';
import Preloader from './Preloader';

class CartItemSimple extends Component {
    render() {
        const {item, product} = this.props;

        if (item.updating || !product) {
            return (
                <tr>
                    <td colSpan="4">
                        <Preloader/>
                    </td>
                </tr>
            );
        } else {

            let discountAmount = item.unitPriceSold - item.unitPrice;

            return (
                <tr className="cart-item">
                    <td className="media-container simple" style={{borderRight: 0}}>
                        <img src={config.cdnUrl + 'img/' + product.productPhoto + '?w=150'} alt=""/>
                    </td>
                    <td colSpan="2" style={{borderLeft: 0}}>
                        <h4 className="product-name">{product.productName}</h4>
                    </td>
                    <td className="vertical-middle">
                        <span className="product-price">
                            { discountAmount !== 0 &&
                                <span className="old-price">{formatCurrency(item.unitPrice * item.productQuantity) + '₮'}</span>
                            }
                            { discountAmount
                                ? formatCurrency(item.unitPriceSold * item.productQuantity) + '₮'
                                : formatCurrency(item.unitPrice * item.productQuantity) + '₮'
                            }
                        </span>
                    </td>
                </tr>
            );
        }
    }
}

export default CartItemSimple;
