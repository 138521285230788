import React, { Component } from 'react';
import { Link } from 'react-router';
import config from '../config';

class Brand extends Component {
    constructor(props) {
        super(props);
        this.state = {
            brands: [],
            categoryId: props.params.id
        }
    }

    componentDidMount(){
        if(this.state.categoryId) {
            this.props.loadBrand(this.state.categoryId, this.props.params.query);
        }
    }

    componentWillReceiveProps(nextProps){
        let nextState = {
            brands: nextProps.brands,
            categoryId: nextProps.params.id
        };

        let queryParams = this.props.location.query;
        if (typeof queryParams.brand_id != 'undefined') {
            nextState.currentId = queryParams.brand_id;
        }

        this.setState(nextState);
    }

    shouldComponentUpdate(nextProps, nextState){
        return this.props.brands != nextProps.brands;
    }

    renderBrands() {
        let path = this.props.location.pathname.indexOf('/search/') == 0
            ? 'search/:id/' + this.props.params.query
            : 'category/:id';
        if (this.props.location.pathname.indexOf('/brandweek/') == 0) {
            path = 'brandweek/:id'.replace(':id', this.props.params.id );
        }
        if (this.props.location.pathname.indexOf('/search/') == 0) {
            path = path.replace(':id', this.props.params.id );
        }
        else {
            if (this.props.params.id === 'all') {
                // all category
                path = path.replace(':id', 'all');
            } else {
                // single category
                if(typeof this.props.categoryFilter != 'undefined' && this.props.categoryFilter.length > 0) {
                    var currentCategory = null;
                    this.props.categoryFilter.forEach(parent => {
                        if(parent.id === this.state.categoryId) {
                            currentCategory = parent;
                        }
                    });
                    if (typeof currentCategory != 'undefined' && currentCategory != null) {
                        path = path.replace(':id', currentCategory.id) + '/' + currentCategory.name;
                    } else {
                        path = path.replace(':id', this.props.params.id );
                    }
                }
            }
        }
        return this.state.brands && this.state.brands.map(brand => {
            let href = config.context + path + '?brand_id=' + brand.brand_id + '&brand_name=' + brand.brand_name;
            return (
                <li key={brand.brand_id} className={this.state.currentId == brand.brand_id ? 'active': ''}>
                    <a href={href}>
                        {brand.brand_name}
                        <span className="count">{brand.product_count}</span>
                    </a>
                </li>
            )
        })
    }

    render(){
        if (typeof this.state.brands != 'undefined' && this.state.brands.length > 0) {
            return (
                <div className="app-side-filter">
                    <h4>Брэнд</h4>
                    <ul>
                        {this.renderBrands()}
                    </ul>
                </div>
            )
        }

        return false;
    }
}

export default Brand;