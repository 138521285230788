import React, { Component } from 'react';
import { Link } from 'react-router';
import { Breadcrumb, BreadcrumbItem } from 'react-bootstrap';
import config from '../config';

class PathMenu extends Component {
    constructor(props) {
        super(props);
    }

    renderItems(){
      if(!this.props.paths)
        return null;
      return this.props.paths.map((path, i) => {
        if(typeof path === 'string')
          return (
            <BreadcrumbItem active key={i}>
              {path}
            </BreadcrumbItem>
          )
        else 
          return (            
              <BreadcrumbItem href={path.href} key={i}>
                {path.text}
              </BreadcrumbItem>
          )
      })
    }

    render(){
        return (
            <Breadcrumb>
              <BreadcrumbItem href={config.context}>
                Нүүр
              </BreadcrumbItem>
              { this.renderItems() }
            </Breadcrumb>
        )
    }
}

export default PathMenu;