import React, { Component } from 'react';

class Preloader extends Component {
    render(){
        return (
          <div className={'loader ' + (this.props.className || '')}>
            <div className="dots">
              <div className="dot"></div>
              <div className="dot"></div>
              <div className="dot"></div>
              <div className="dot"></div>
              <div className="dot"></div>
            </div>
          </div>
        )
    }
}

export default Preloader;