import config from '../config';
import * as ActionTypes from '../constants/ActionTypes';
import { httpRequest } from '../helpers';

export function loadSavedProducts() {
    return dispatch => {
        dispatch({type: ActionTypes.REQUEST_START})
		httpRequest({
            url: config.baseUrl + '/api/account/savedProducts',
            method: 'GET'
        }).then(function (products) {
            dispatch({type: ActionTypes.LOAD_SAVED_PRODUCTS_SUCCESS, products: products});
        }, function (error) {
            dispatch({type: ActionTypes.LOAD_SAVED_PRODUCTS_ERROR, error: error});
        });
    };
}

export function loadUser() {
    return dispatch => {
        dispatch({type: ActionTypes.REQUEST_START})
        httpRequest({
            url: config.baseUrl + '/api/account/user',
            method: 'GET'
        }).then(function (user) {
            dispatch({type: ActionTypes.LOAD_USER_SUCCESS, user: user});
        }, function (error) {
            dispatch({type: ActionTypes.LOAD_USER_ERROR, error: error});
        });
    };
}

export function loadUserPhoto() {
    return dispatch => {
        dispatch({type: ActionTypes.REQUEST_START})
        httpRequest({
            url: config.baseUrl + '/api/account/photo',
            method: 'GET'
        }).then(function (photo) {
            dispatch({type: ActionTypes.LOAD_USER_PHOTO_SUCCESS, photo: photo});
        }, function (error) {
            dispatch({type: ActionTypes.LOAD_USER_PHOTO_ERROR, error: error});
        });
    };
}

export function checkIsLogged() {
    return dispatch => {
        dispatch({type: ActionTypes.REQUEST_START})
        httpRequest({
            url: config.baseUrl + '/api/checkLoggedUser',
            method: 'GET'
        }).then(function (response) {
            dispatch({type: ActionTypes.CHECK_LOGGED_USER_SUCCESS, data: response});
        }, function (error) {
            dispatch({type: ActionTypes.CHECK_LOGGED_USER_ERROR, error: error});
        });
    };
}

export function loadOrderList() {
    return dispatch => {
        dispatch({type: ActionTypes.REQUEST_START})
        httpRequest({
            url: config.baseUrl + '/api/account/getOrderList',
            method: 'GET'
        }).then(function (response) {
            dispatch({type: ActionTypes.LOAD_ORDER_LIST_SUCCESS, orders: response.data});
        }, function (error) {
            dispatch({type: ActionTypes.LOAD_ORDER_LIST_ERROR, error: error});
        });
    };
}