import {
    LOAD_BTAG_PRODUCTS_SUCCESS,
    LOAD_BTAGS_SUCCESS,
    LOAD_BTAG_SPECIAL_SUCCESS,
    LOAD_PRODUCT_BRANDS_SUCCESS,
    LOAD_PRICE_FILTER_SUCCESS,
    FILTER_CHANGED,
} from '../constants/ActionTypes';

const initialState = {
    btags: [],
    products: [],
    specials: [],
    brands: [],
    prices: null
};

function btag(state = initialState, action) {
    switch (action.type) {

        case FILTER_CHANGED: {
            return Object.assign({}, state, {
                products: [],
                isQuickModal: false
            });
        }

        case LOAD_BTAGS_SUCCESS: {
            return Object.assign({}, state, {
                btags: action.btags
            });
        }

        case LOAD_BTAG_SPECIAL_SUCCESS: {
            return Object.assign({}, state, {
                specials: action.specials
            });
        }

        case LOAD_BTAG_PRODUCTS_SUCCESS: {
            return Object.assign({}, state, {
                products: action.products
            });
        }

        case LOAD_PRODUCT_BRANDS_SUCCESS: {
            return Object.assign({}, state, {
                brands: action.brands
            });
        }

        case LOAD_PRICE_FILTER_SUCCESS: {
            return Object.assign({}, state, {
                prices: action.prices
            });
        }

        default:
            return state;
    }
}

export default btag;
