import config from '../config';
import * as ActionTypes from '../constants/ActionTypes';
import { httpRequest } from '../helpers';

function normalizeProduct(product) {
    return product;
}

export function addProducts(products) {
    let normalizedProducts = [];
    for (let product of products) {
        normalizedProducts.push(normalizeProduct(product));
    }
    return { type: ActionTypes.ADD_PRODUCTS, products: normalizedProducts };
}

let productRequestHistory = {};

export function loadProduct(productVarId, productCode) {
    return dispatch => {
        if (productRequestHistory.hasOwnProperty(productVarId)) {
            // no-op
        } else {
            productRequestHistory[productVarId] = true;
            httpRequest({
                url: config.baseUrl + '/api/product/get',
                method: 'GET',
                data: {
                    id: productVarId,
                    code: productCode
                }
            }).then(function (product) {
                dispatch(addProducts([product]));
            }, function () {
                delete productRequestHistory[productVarId];
            });
        }
    };
}

export function loadProducts(page = 0, fetchCount = 10, categoryId, sortField, callback, query, filters = null) {
    let requestURL = config.baseUrl + '/api/products',
        data = {
            page: page,
            fetchCount: fetchCount
        };
    if(categoryId == 'homepage') {
        requestURL = config.baseUrl + '/api/products/homepage';
    } else if(categoryId && categoryId.length){
        requestURL = config.baseUrl + '/api/products/category';
        data.id = categoryId;
    }

    if(sortField && sortField.hasOwnProperty('field')){
        data.sortField = sortField.field;
        data.sortDirection = sortField.dir;        
    }

    if(query && query.length){
        data.searchWord = query
    }

    if(typeof filters == 'object' && filters != null) {
        if(typeof filters.brand_id != 'undefined' && filters.brand_id.length > 0) {
            data.brand_id = filters.brand_id
        }
        if(typeof filters.lp != 'undefined' && filters.lp != null && filters.lp) {
            data.lp = parseInt(filters.lp)
        }
        if(typeof filters.hp != 'undefined' && filters.hp != null && filters.hp) {
            data.hp = parseInt(filters.hp)
        }
    }

    return dispatch => {
            dispatch({type: ActionTypes.REQUEST_START})
            httpRequest({
                url: requestURL,
                method: 'GET',
                data: data
            }).then(function (products) {
                dispatch({type: ActionTypes.LOAD_PRODUCTS_SUCCESS, products: products});
                callback && callback(page);
            }, function (error) {
                dispatch({type: ActionTypes.LOAD_PRODUCTS_ERROR, error: error});
            });
    };
}

export function saveProduct(productId, isSaved, cb) {
    return dispatch => {
        httpRequest({
            url: config.baseUrl + '/api/saveVar',
            method: 'POST',
            data: {prodVarId: productId, isSaved : isSaved }
        }).then(function (response) {
            dispatch({
                type: ActionTypes.SAVE_PRODUCT_SUCCESS,
                productVarId: productId,
                isFavorite: isSaved
            });
            cb(response.result);
        }, function (error) {
            dispatch({type: ActionTypes.SAVE_PRODUCT_ERROR, error: error});
        });
    };
}

export function saveProductVar(productVarId, isSaved, cb) {
    return dispatch => {
        httpRequest({
            url: config.baseUrl + '/api/saveVar',
            method: 'POST',
            data: {prodVarId: productId, isSaved : isSaved }
        }).then(function (response) {
            dispatch({
                type: ActionTypes.SAVE_PRODUCT_SUCCESS,
                productVarId: productId,
                isFavorite: isSaved
            });
            cb(response.result);
        }, function (error) {
            dispatch({type: ActionTypes.SAVE_PRODUCT_ERROR, error: error});
        });
    };
}

export function viewProduct(productId, isQuick = false) {
    return dispatch => {
        dispatch({type: ActionTypes.VIEW_PRODUCT_START});
        httpRequest({
            url: config.baseUrl + '/api/product/get',
            method: 'GET',
            data: {id: productId } 
        }).then(function (product) {
            dispatch({type: ActionTypes.VIEW_PRODUCT_SUCCESS, product: product, isQuick: isQuick});
        }, function (error) {
            dispatch({type: ActionTypes.VIEW_PRODUCT_ERROR, error: error});
        });
    };
}

export function quickViewProduct(productId) {
    return dispatch => {
        dispatch({type: ActionTypes.QUICK_VIEW_PRODUCT_START});
        httpRequest({
            url: config.baseUrl + '/api/product/get',
            method: 'GET',
            data: {id: productId } 
        }).then(function (product) {
            dispatch({type: ActionTypes.QUICK_VIEW_PRODUCT_SUCCESS, product: product});
        }, function (error) {
            dispatch({type: ActionTypes.QUICK_VIEW_PRODUCT_ERROR, error: error});
        });
    };
}

export function saveReview(review, cb) {
    return dispatch => {
        httpRequest({
            url: config.baseUrl + '/api/review',
            method: 'POST',
            data: review
        }).then(function (review) {
            dispatch({type: ActionTypes.SAVE_REVIEW_SUCCESS});
            cb(review);
        }, function (error) {
            dispatch({type: ActionTypes.SAVE_REVIEW_ERROR, error: error});
        });
    };
}

export function loadCategory() {
    return dispatch => {
        httpRequest({
            url: config.baseUrl + '/api/category',
            method: 'GET'
        }).then(function (categories) {
            dispatch({type: ActionTypes.LOAD_CATEGORIES_SUCCESS, categories: categories});
        }, function (error) {
            dispatch({type: ActionTypes.LOAD_CATEGORIES_ERROR, error: error});
        });
    };
}

export function loadCategoryFilter(categoryId) {
    return dispatch => {
        httpRequest({
            url: config.baseUrl + '/api/products/filter/category',
            method: 'GET',
            data: {'id': categoryId}
        }).then(function (categories) {
            dispatch({type: ActionTypes.LOAD_CATEGORY_FILTER_SUCCESS, categoryFilter: categories});
        }, function (error) {
            dispatch({type: ActionTypes.LOAD_CATEGORY_FILTER_ERROR, error: error});
        });
    };
}

export function loadBrand(categoryId, searchWord = null) {
    let url = config.baseUrl + '/api/products/filter/brands';
    if (typeof searchWord == 'string')
        url += '?category_id='+categoryId+'&search_query='+searchWord;
    else
        url += '?category_id='+categoryId;
    return dispatch => {
        httpRequest({
            url: url,
            method: 'GET'
        }).then(function (brands) {
            dispatch({type: ActionTypes.LOAD_PRODUCT_BRANDS_SUCCESS, brands: brands});
        }, function (error) {
            dispatch({type: ActionTypes.LOAD_PRODUCT_BRANDS_ERROR, error: error});
        });
    };
}

export function loadPriceFilter(categoryId, searchWord = null) {
    let url = config.baseUrl + '/api/products/filter/price';
    if (typeof searchWord == 'string')
        url += '?category_id='+categoryId+'&search_query='+searchWord;
    else
        url += '?category_id='+categoryId;
    return dispatch => {
        httpRequest({
            url: url,
            method: 'GET'
        }).then(function (prices) {
            dispatch({type: ActionTypes.LOAD_PRICE_FILTER_SUCCESS, prices: prices});
        }, function (error) {
            dispatch({type: ActionTypes.LOAD_PRICE_FILTER_ERROR, error: error});
        });
    };
}

export function sendDiscount(varId, callback) {
    return dispatch => {
        httpRequest({
            url: config.baseUrl + '/api/sendDiscount',
            method: 'GET',
            data: {id: varId}
        }).then(function (response) {
            callback && callback(response);
        }, function (error) {
            callback && callback(error);
        });
    };
}

export function sortChanged() {
    return dispatch => {
        dispatch({type: ActionTypes.SORT_CHANGED});
    };
}

export function filterChanged() {
    return dispatch => {
        dispatch({type: ActionTypes.FILTER_CHANGED});
    };
}