'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.routeReducer = routeReducer;
exports.syncHistory = syncHistory;
// Constants

var TRANSITION = exports.TRANSITION = '@@router/TRANSITION';
var UPDATE_LOCATION = exports.UPDATE_LOCATION = '@@router/UPDATE_LOCATION';

var SELECT_STATE = function SELECT_STATE(state) {
  return state.routing;
};

function transition(method) {
  return function (arg) {
    return {
      type: TRANSITION,
      payload: { method: method, arg: arg }
    };
  };
}

var routeActions = exports.routeActions = {
  push: transition('push'),
  replace: transition('replace'),
  go: transition('go'),
  goBack: transition('goBack'),
  goForward: transition('goForward')
};

function updateLocation(location) {
  return {
    type: UPDATE_LOCATION,
    payload: location
  };
}

// Reducer

var initialState = {
  location: undefined
};

function routeReducer() {
  var state = arguments.length <= 0 || arguments[0] === undefined ? initialState : arguments[0];
  var _ref = arguments[1];
  var type = _ref.type;
  var location = _ref.payload;

  if (type !== UPDATE_LOCATION) {
    return state;
  }

  return _extends({}, state, { location: location });
}

// Syncing

function syncHistory(history) {
  var unsubscribeHistory = undefined,
      currentKey = undefined,
      unsubscribeStore = undefined;
  var connected = false,
      syncing = false;

  function middleware(store) {
    unsubscribeHistory = history.listen(function (location) {
      currentKey = location.key;
      if (syncing) {
        // Don't dispatch a new action if we're replaying location.
        return;
      }

      store.dispatch(updateLocation(location));
    });

    connected = true;

    return function (next) {
      return function (action) {
        if (action.type !== TRANSITION || !connected) {
          return next(action);
        }

        var _action$payload = action.payload;
        var method = _action$payload.method;
        var arg = _action$payload.arg;

        history[method](arg);
      };
    };
  }

  middleware.listenForReplays = function (store) {
    var selectRouterState = arguments.length <= 1 || arguments[1] === undefined ? SELECT_STATE : arguments[1];

    var getRouterState = function getRouterState() {
      return selectRouterState(store.getState());
    };

    var _getRouterState = getRouterState();

    var initialLocation = _getRouterState.location;

    unsubscribeStore = store.subscribe(function () {
      var _getRouterState2 = getRouterState();

      var location = _getRouterState2.location;

      // If we're resetting to the beginning, use the saved initial value. We
      // need to dispatch a new action at this point to populate the store
      // appropriately.

      if (!location) {
        history.transitionTo(initialLocation);
        return;
      }

      // Otherwise, if we need to update the history location, do so without
      // dispatching a new action, as we're just bringing history in sync
      // with the store.
      if (location.key !== currentKey) {
        syncing = true;
        history.transitionTo(location);
        syncing = false;
      }
    });
  };

  middleware.unsubscribe = function () {
    unsubscribeHistory();
    if (unsubscribeStore) {
      unsubscribeStore();
    }

    connected = false;
  };

  return middleware;
}
