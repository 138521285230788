import React, {Component} from 'react';
import {connect} from 'react-redux';
import config from '../config';
import {countItems, formatCurrency} from '../helpers';
import * as cartActions from '../actions/cart';
import CartItemContainer from './CartItemContainer'

class CartItemCountContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            show: false
        }
    }
    toggleCart() {
        this.props.show ? this.props.hideCart() : this.props.showCart();
    }

    renderItems() {
        return this.props.items.map(item => <CartItemContainer key={item.orderDtlId} item={item} quickCart={true}/>)
    }

    render() {
        return (
            <div className={'app-cart' + (this.props.show ? ' active': '')}>
              <a href="javascript:;" onClick={this.toggleCart.bind(this)}>
                <i className="zmdi zmdi-shopping-cart"></i>
                {this.props.itemCount ? <span className="badge">{this.props.itemCount}</span> : null}
              </a>
              {
                this.props.itemCount > 0 ? 
                    <div className="dropdown-menu dropdown-menu-right">
                        <h5 className="text-center">{`Таны сагсанд ${this.props.itemCount} бүтээгдэхүүн байна`}</h5>
                        <ul className="app-cart-items">
                            { this.renderItems() }
                        </ul>
                        <div className="app-cart-total" data-text="Нийт =">
                            {formatCurrency(this.props.totalPayAmount) + '₮'}
                        </div>
                        <div className="app-cart-actions">
                            <a href={`${config.context}cart`}
                                className="btn btn-primary btn-block">
                                Төлбөр төлөх
                            </a>
                        </div>
                    </div> : 
                    <div className="dropdown-menu dropdown-menu-right">
                        <h5 className="text-center">{`Таны сагс хоосон байна`}</h5>
                    </div>
              }
            </div>
        );
    }
}

export default connect(
    state => {
        return {
            itemCount: countItems(state.cart.items),
            items: state.cart.items,
            totalPayAmount: state.cart.totalPayAmount,
            show: state.cart.show
        };
    },
    {...cartActions}
)(CartItemCountContainer);
