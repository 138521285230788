import React, { Component } from 'react';
import { Link } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Helmet from "react-helmet";

import * as companyActions from '../actions/company';
import * as productActions from '../actions/product';
import config from '../config';
import {formatCurrency} from '../helpers';
import Masonry from 'react-masonry-component';
import PathMenu from '../components/PathMenu';

class BrandListContainer extends Component {
    constructor(props){
        super(props);

        this.state = {
            currentCategory: undefined,
            brands: []
        }
    }
    componentWillMount() {
        this.props.loadCategory();
        this.props.loadBrands();  
    }

    componentWillReceiveProps(nextProps){
        let brands = nextProps.brands;
        brands.forEach(brand => {
            try {
                if(typeof brand.categories === 'string')
                    brand.categories = (brand.categories || '').split(';');                
            }
            catch(e){
                console.log(e);                
            }
        })
        this.setState({
            brands: brands
        })
    }

    onSelectCategory(id){
        this.setState({
            currentCategory: id
        })
    }

    renderBrands(){
        let {brands} = this.state;
        if(this.state.currentCategory) {
            brands = brands.filter(brand => brand.categories.indexOf(this.state.currentCategory) > -1);
        }
        return (brands || []).map(brand => {
            let imgURL = [config.cdnUrl, 'img/', brand.brandPhotoId].join('');

            if(!brand.brandPhotoId){
                imgURL = [config.baseUrl, '/images/empty.jpg'].join('')
            }

            return (
                <div key={brand.brandId} className="col-xs-6 col-sm-4 col-md-4 brand-list-item">
                    <div className="product" >
                        <Link to={{pathname: `${config.context}brand/${brand.brandId}`, 
                            query: {name : brand.brandName} }}
                            className="product-front">
                            <img src={imgURL} alt={brand.brandName} />
                            <div className="product-stats text-center">
                                <span className="product-name">{brand.brandName}</span>
                                <span className="product-price">
                                  {`${formatCurrency(brand.soldItemCount)}`}
                                </span>
                                <span>бүтээгдэхүүн борлуулж байна</span>
                            </div> 
                        </Link>
                   </div>
               </div>
           )
        })
    }

    renderCategories(){
        return this.props.categories.map(category => (
            <li key={category.id} className={this.state.currentCategory == category.id ? 'active': ''}>
                <a href="javascript:;" 
                    onClick={this.onSelectCategory.bind(this, category.id)}>{category.name}</a>
            </li>
        ))
    }
                
    render() {
        let options = {
          itemSelector: '.brand-list-item',
          gutter: 0,
          transitionDuration: 0
        }, paths = ['Брэнд'];

        return (
            <div className="margin-bottom">
                <Helmet title="Брэнд"
                    titleTemplate={config.titleTemplate}/>
                <div className="row">
                    <div className="col-xs-8 col-md-8">
                        <PathMenu paths={paths}/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xs-12 col-sm-3 col-md-3">
                        <div className="app-side-filter">
                            <ul>
                                {this.renderCategories()}
                            </ul>
                        </div>
                    </div>
                    <div className="col-xs-12 col-sm-9 col-md-9">
                        <Masonry className="row" options={options}>
                          {this.renderBrands()}
                        </Masonry>
                    </div>
                </div>
            </div>
        )
    }
}

export default connect(
    (state, props) => {
        return {
            categories: typeof state.products.categories === 'string' ? JSON.parse(state.products.categories) : state.products.categories,
            brands: typeof state.company.brands === 'string' ? JSON.parse(state.company.brands) : state.company.brands
        } 
    },
    (dispatch) => bindActionCreators(Object.assign({}, productActions, companyActions), dispatch)
)(BrandListContainer);