import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';
import Helmet from 'react-helmet';
import { bindActionCreators } from 'redux';

import * as productActions from '../actions/product';
import {addCartItem} from '../actions/cart';

import config from '../config';

import ProductList from '../components/ProductList';
import Category from '../components/Category';
import Brand from '../components/Brand';
import PriceFilter from '../components/PriceFilter';
import PathMenu from '../components/PathMenu';
import SortControl from '../components/SortControl';
import ProductDetail from '../components/ProductDetail';

class ProductListContainer extends Component {
    constructor(props){
        super(props);
        this.state = {
            sort: {
                text: 'Эрэмбэлэх'
            },
            filter: {
                price: undefined
            }
        };

        // this.handlePriceFilterChange = this.handlePriceFilterChange.bind(this);
    }

    handleSort(sort) {
        this.setState({
            sort: sort
        })
    }

    handlePriceFilterChange(e) {
        let prices = e.target.value;
        this.setState({
            filter: {price:{min: prices[0], max: prices[1]}}
        })
    }

    toggleMobileFilter(e){
        let fc = $("#filterContainer");
        if(fc.hasClass('hidden-xs')) {
            fc.removeClass('hidden-xs');
        } else {
            fc.addClass('hidden-xs');
        }
    }

    render() {
        let title = this.props.params.name || '',
        paths = [title];

        localStorage.pathName = JSON.stringify([{text: paths[0], href: this.props.location.pathname}])
        return (
            <div className="margin-bottom">
                <Helmet title={title}
                    titleTemplate={config.titleTemplate}/>
                
                <div className="row">
                    <div className="col-xs-12 col-md-8">
                        <PathMenu paths={paths}/>
                    </div>
                    <div className="col-xs-12 col-md-4 margin-bottom">
                        <button id="filter-control" className="btn btn-default hidden-lg hidden-md hidden-sm" onClick={this.toggleMobileFilter}>
                            Филтер&nbsp;<span className="caret" />
                        </button>
                        <div className="sort-control-container">
                            <SortControl handleSort={this.handleSort.bind(this)}/>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xs-12 col-sm-3 col-md-3 product-filter-container hidden-xs" id="filterContainer">
                        <Category {...this.props}/>
                        <Brand {...this.props}/>
                        <PriceFilter handleChange={this.handlePriceFilterChange.bind(this)} {...this.props}/>
                    </div>
                    <div className="col-xs-12 col-sm-9 col-md-9 product-list-container">
                        <ProductList {...this.props} 
                            sort={this.state.sort}
                            filter={this.state.filter}
                            showBanner={false}
                            fetchCount={24} />
                    </div>
                </div>
            </div>
        );
    }
}

ProductListContainer.displayName = 'ProductListContainer'

export default connect(
    (state, props) => {
        return {
            prices: typeof state.products.prices === 'string' ? JSON.parse(state.products.prices) : state.products.prices,
            brands: typeof state.products.brands === 'string' ? JSON.parse(state.products.brands) : state.products.brands,
            categories: typeof state.products.categories === 'string' ? JSON.parse(state.products.categories) : state.products.categories,
            categoryFilter: typeof state.products.categoryFilter === 'string' ? JSON.parse(state.products.categoryFilter) : state.products.categoryFilter,
            products: typeof state.products.products === 'string' ? JSON.parse(state.products.products) : state.products.products,
            total: state.products.total,
            isLoading: state.products.isLoading,
        } 
    },
    {
        ...productActions,
        addCartItem
    }
)(ProductListContainer);
