import React from 'react';
import ReactDOM from 'react-dom';
import { createStore, applyMiddleware, compose } from 'redux';
import { Provider } from 'react-redux'
import thunk from 'redux-thunk';
import { Router, Route, IndexRoute, browserHistory } from 'react-router'
import { syncReduxAndRouter, pushPath } from 'redux-simple-router'
import reactGa from 'react-ga';
import toastr from 'toastr';
import createLogger from 'redux-logger';

import AppContainer from './containers/AppContainer';
import HomeContainer from './containers/HomeContainer';

import CartContainer from './containers/CartContainer';
import CartItemCountContainer from './containers/CartItemCountContainer';
import ProductListContainer from './containers/ProductListContainer';
import BrandWeekContainer from './containers/BrandWeekContainer';
import BtagListContainer from './containers/BtagListContainer';
import BtagProductListContainer from './containers/BtagProductListContainer';
import ProductModalContainer from './containers/ProductModalContainer';
import ShopContainer from './containers/ShopContainer';
import ShopListContainer from './containers/ShopListContainer';
import BrandListContainer from './containers/BrandListContainer';
import BrandContainer from './containers/BrandContainer';
import SearchListContainer from './containers/SearchListContainer';

import ContentListContainer from './containers/ContentListContainer';
import ContentContainer from './containers/ContentContainer';

import AccountContainer from './containers/AccountContainer';
import AccountInfo from './components/account/AccountInfo';
import LikedProduct from './components/account/LikedProduct';
import OrderList from './components/account/OrderList';
import Password from './components/account/Password';

import babyApp from './reducers/index';
import {loadCartData, hideCart} from './actions/cart';
import { viewProduct } from './actions/product';
import config from './config';

export default function () {

    let finalCreateStore;
    if(process.env.NODE_ENV === 'production') {
        finalCreateStore = compose(
            applyMiddleware(thunk)
        )(createStore);
    }
    else {
        const logger = createLogger({
            level: 'info',
            collapsed: true
        });
        finalCreateStore = compose(
            applyMiddleware(thunk, logger),
            window.devToolsExtension ? window.devToolsExtension() : f => f
        )(createStore);
    }
    

    const store = finalCreateStore(babyApp);
    global.store = store;

    store.dispatch(loadCartData());

    /**
    * Google Analytics
    */
    if(process.env.NODE_ENV === 'production') {
        reactGa.initialize('UA-66943821-1');
        ga('create', 'UA-76874326-1', 'auto', 'user2');
    }

    ReactDOM.render(
        <Provider store={store}>
            <CartItemCountContainer />
        </Provider>,
        document.getElementById('app-cart')
    );

    let target = document.getElementById('app-section');
    if(target) {
        let nextEl = $('header + *'),
            noSpace = (nextState, replace) => {
                nextEl.css('margin-top', 0)
            }, 
            withSpace = (nextState, replace) => {
                nextEl.css('margin-top', 20)
            }

        let logPageView = function(e) {
            let pathname = this.state.location.pathname;
            if(process.env.NODE_ENV === 'production') {
                reactGa.pageview(pathname);
                ga('user2.send', 'pageview', pathname);
            }
        }

        ReactDOM.render(
            <Provider store={store}>
                <Router history={browserHistory} onUpdate={logPageView}>
                  <Route path={config.context} component={AppContainer}>
                    <IndexRoute component={HomeContainer} onEnter={noSpace}/>
                    <Route path={config.context + 'cart'} component={CartContainer} onEnter={withSpace}/>
                    <Route path={config.context + 'category/:id(/:name)'} component={ProductListContainer} onEnter={withSpace}/>
                    <Route path={config.context + 'brandweek/:id(/:name)'} component={BrandWeekContainer} onEnter={withSpace}/>
                    <Route path={config.context + 'product/:id(/:name)'} component={ProductModalContainer} onEnter={withSpace}/>
                    <Route path={config.context + 'btag'} component={BtagListContainer} onEnter={withSpace}/>
                    <Route path={config.context + 'btag/products/:id(/:name)'} component={BtagProductListContainer} onEnter={withSpace}/>
                    <Route path={config.context + 'shop/:id(/:name)'} component={ShopContainer} onEnter={noSpace}/>
                    <Route path={config.context + 'shop'} component={ShopListContainer} onEnter={withSpace}/>
                    <Route path={config.context + 'brand'} component={BrandListContainer} onEnter={withSpace}/>
                    <Route path={config.context + 'brand/:id'} component={BrandContainer} onEnter={withSpace}/>
                    <Route path={config.context + 'search/:id/:query(/:name)'} component={SearchListContainer} onEnter={withSpace}/>
                    <Route path={config.context + 'bcontent'} component={ContentListContainer} onEnter={withSpace}/>
                    <Route path={config.context + 'bcontent/:id(/:title)'} component={ContentContainer} onEnter={withSpace}/>
                    <Route path={config.context + 'account'} component={AccountContainer} onEnter={withSpace}>
                        <IndexRoute component={AccountInfo}/>
                        <Route path='liked-product' component={LikedProduct}/>
                        <Route path='orders' component={OrderList}/>
                        <Route path='change-password' component={Password}/>
                    </Route>
                  </Route>
                </Router>
            </Provider>,
            target
        );
    }

    $(document).on('click touch', e => {
        // Сагс нээсэн бол сагснаас бусад газар дарахад сагсыг хаах
        if(!$(e.target).closest('#app-cart').length)
            store.dispatch(hideCart());
    })

    // Display notification toasters below the header
    toastr.options.positionClass = "toast-bottom-right";
};
