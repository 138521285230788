import React, { Component, PropTypes } from 'react';
import Rating from 'react-rating';
import config from '../config';

class ProductReview extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            reviews: [],
            rating: 5
        }
    }

    componentDidMount(){
        this.setState({
            reviews: this.props.reviews
        })
    }

    componentWillReceiveProps(nextProps) {      
        this.setState({
            reviews: nextProps.reviews
        })        
    }

    onSubmit(event){
        event.preventDefault();

        var $form = $(event.target),
            data = {
                '_token': config.csrfToken,
                'id': this.props.id,
                'rating': this.state.rating
            };
        $form.find('[name]').each((i, e) => {
            data[e.getAttribute('name')] = e.value;
        });

        this.props.saveReview(data, ((review) => {
            this.refs.review.reset();
            let reviews = this.state.reviews;
            reviews.unshift(JSON.parse(review));
            this.setState(Object.assign({}, this.state, {reviews: reviews}));
            // this.props.initRatings();
        }).bind(this));
    }

    onRate(rate){
        this.setState({
            rating: rate
        })
    }

    renderAlert(){
        return (
            <div className="row">
                <div className="col-md-12 text-center">
                    <div className="alert alert-warning" role="alert">
                        Зөвхөн нэвтэрсэн хэрэглэгч сэтгэгдэл үлдээх боломжтой!
                    </div>            
                </div>
            </div>
        )
    }

    renderForm(){
        return (
            <form onSubmit={this.onSubmit.bind(this)} ref="review">
                <div className="row">
                    { this.props.reviewFields && this.props.reviewFields.length ?
                        <div className="col-md-4">
                            {
                                this.props.reviewFields.map((field, i) => 
                                    <div key={i}>
                                        <input type="hidden" name={'fieldId[' + i + ']'} value={field['reviewFieldId']}/>
                                        <span className="wi7">{field['fieldName']}</span>
                                        {
                                            field['sizeList'].length == 0 ? 
                                                <input id={field['reviewFieldId']} defaultValue="5" type="number" name={field['reviewFieldId']} className="rating" min="0" max="5" step="1" 
                                                    data-size="xs" data-default-caption="({rating}/5)" data-star-captions="{}" data-symbol=" " data-glyphicon="false" data-rating-classname="rating-fa" /> : 
                                                (<div>
                                                    <div id="review-size" className="sizeslider" data-text={field['sizeList']}></div>
                                                    <input type="hidden" id="review-value" name={field['reviewFieldId']} value="1" />
                                                    <input className="rw7" type="text" id="review-text" value="Таарсан" readOnly style={{border:'none'}} />
                                                </div>)
                                        }
                                    </div>
                                )
                            }
                        </div> : null
                    }
                    <div className={this.props.reviewFields && this.props.reviewFields.length ? 'col-md-8' : 'col-md-12'}>
                        <div className="title">
                            <span className="pull-left">Үнэлгээ</span>
                            <div className="product-rating text-left pull-left" style={{marginLeft: 10}}>
                                <Rating initialRate={this.state.rating}
                                    step={1}
                                    max={5}
                                    empty="zmdi zmdi-star"
                                    placeholder="zmdi zmdi-star rated"
                                    full="zmdi zmdi-star rated"
                                    onChange={this.onRate.bind(this)}/>
                            </div>
                        </div>
                        <div className="form-group">
                            <textarea className="form-control" name="review" id="addComment"></textarea>
                        </div>
                        <div className="text-right">
                            <button type="submit" className="btn btn-default">Сэтгэгдэл илгээх</button>                    
                        </div>
                    </div>
                </div>
            </form> 
        )
    }

    render(){
        return (<div className="product-review clearfix">
                    { this.context.isLogged ? this.renderForm() : this.renderAlert() }                  
                    <div className="list">
                        <div className="title clearfix"><span>{!!this.state.reviews ? this.state.reviews.length : '0'}</span> Сэтгэгдэл байна</div>
                        {
                            (this.state.reviews || []).map((review) => <ProductReviewItem key={review.reviewHeaderId} review={review} type={this.props.type}/>)
                        }
                    </div>
                </div>)
    }
}

class ProductReviewItem extends Component {

    getFieldTextValue(field, value){
        let sizeList = field['sizeList'].split(', '),
            fieldValue = value >= 2 ? 2 : value;
        ;return sizeList[fieldValue]
    }

    render(){
        let review = this.props.review; 
        return (
            <div className="commentItem">
                <div className="main clearfix">
                    <div className="name col-md-2">                                                
                        <b>{review['userName']}</b>
                        {review['childCount'] > 0 ? <div>Хүүхэд { review['childCount'] }</div> : null}
                        {review['childCount'] > 0 ? <div>Хүүхдийн нас { review['childAge'] }</div> : null}
                        {review['createdDate']}
                        {
                            this.props.type === 'product' ?
                            <div className="product-rating text-left">
                                <Rating initialRate={review['averageRate'] || 0} 
                                    readonly={true} 
                                    step={1}
                                    max={5}
                                    fractions={2}
                                    empty="zmdi zmdi-star"
                                    placeholder="zmdi zmdi-star rated"
                                    full="zmdi zmdi-star rated" />
                            </div> : null
                        }
                    </div>
                    <div className="text col-md-7">
                        {review['reviewBody']}
                    </div>
                    {
                        review['reviewFieldList'] && review['reviewFieldList'].length ? (
                            <div className="userstar col-md-3">
                                <ul>
                                {
                                    review['reviewFieldList'].map((field) => 
                                        field['sizeList'] && field['sizeList'].length ? 
                                            (<li key={field.reviewFieldId}>
                                                <span className="wi7">{field['fieldName']}</span>
                                                <div data-value={field['fieldValue'] >= 2 ? 2 : field['fieldValue']} className="sizeslider"></div>
                                                <div>
                                                    <input className="rw7" type="text" value={this.getFieldTextValue(field, field['fieldValue'])} readOnly style={{border:'none'}}/>
                                                </div>
                                            </li>) : 
                                            (<li key={field.reviewFieldId}>
                                                <span className="wi7">{field['fieldName']}</span>
                                                <input type="number" readOnly className="rating db" min="0" max="5" step="1" data-size="xs" value={field['fieldValue']} data-default-caption="({rating}/5)" data-star-captions="{}" 
                                                    data-symbol=" " data-glyphicon="false" data-rating-classname="rating-fa"/>                                                   
                                            </li>)
                                    )
                                }
                                </ul>
                            </div>
                        ) : null
                    }
                </div>
            </div>
        )
    }
}

ProductReview.ProductReviewItem = ProductReviewItem;

ProductReview.contextTypes = {
    isLogged: PropTypes.bool.isRequired,
    id: PropTypes.string
}

export default ProductReview;    