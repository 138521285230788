import 'babel-polyfill';
import app from './main'
import config from './config'
import toastr from 'toastr'

// global._ = require('lodash');
// require('gridstack/dist/gridstack');

(function() {
    $(window).on("scroll", function() {
        var fromTop = $(window).scrollTop();
        // header-г fixed болгох
        $('header').toggleClass("animated slideInDown header--fixed", (fromTop > 250));
    });

    L.Icon.Default.imagePath = config.baseUrl + '/css/images';

    // $('.payment-carousel').owlCarousel({
    //     margin: 10,
    //     center: true,
    //     autoWidth: true,
    //     autoplay: true,
    // })
    app() // init React application
    
    var loader = $('#loader');
    setTimeout(() => {
        loader.addClass('fadeOut');
        setTimeout(() => {
            loader.hide();
        }, 1000)
    }, 1000)

    /*var defaultOptions = {
        center: [47.9131, 106.9358], // Center of UB
        zoom: 17,
        layers: [
            L.tileLayer.wms('https://api.minu.mn/wms', {
                layers: 'mongolid:mimap',
                format: 'image/png',
                transparent: true
            })
        ],        
        attributionControl: false
    }

    var map = L.map('company-location', defaultOptions); // creates Leaflet Element

    L.marker({
        lat: 47.9132,
        lng: 106.9358
    }).addTo(map);*/


    $('#chat .chat-icon').on('click touch', () => {
        $('#chat').toggleClass('open')
    });

    $('#open-header').on('click touch', () => {
        $('header').toggleClass('open');
        Tawk_API.toggle();
    })

    window.showNotify = function(msg, type){
        toastr[type](msg);
    }

    // browser window scroll (in pixels) after which the "back to top" link is shown
    var offset = 300,
        //browser window scroll (in pixels) after which the "back to top" link opacity is reduced
        offset_opacity = 1200,
        //duration of the top scrolling animation (in ms)
        scroll_top_duration = 700,
        //grab the "back to top" link
        $back_to_top = $('.back-to-top');

    //hide or show the "back to top" link
    $(window).scroll(function(){
        ( $(this).scrollTop() > offset ) ? $back_to_top.addClass('is-visible') : $back_to_top.removeClass('is-visible fade-out');
        if( $(this).scrollTop() > offset_opacity ) {
            $back_to_top.addClass('fade-out');
        }
    });

    //smooth scroll to top
    $back_to_top.on('click', function(event){
        event.preventDefault();
        $('body,html').animate({
            scrollTop: 0 ,
            }, scroll_top_duration
        );
    });

    // Facebook sdk
    window.fbAsyncInit = function() {
        FB.init({
            appId      : process.env.FB_APPID,
            xfbml      : true,
            version    : 'v2.5'
        });
    };

    (function(d, s, id){
        var js, fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) {return;}
        js = d.createElement(s); js.id = id;
        js.src = "//connect.facebook.net/en_US/sdk.js";
        fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));
})();