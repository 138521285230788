import React, {Component} from 'react';

class FieldError extends Component {
    render() {
        const {field} = this.props;
        return (
            <span className="field-error" style={{display: field.touched && field.error ? undefined : 'none'}}>
                {field.error}
            </span>
        );
    }
}

export default FieldError;
