import config from '../config';
import * as ActionTypes from '../constants/ActionTypes';
import { httpRequest } from '../helpers';

export function loadBtags() {
    return dispatch => {
            httpRequest({
                url: config.baseUrl + '/api/btags',
                method: 'GET'
            }).then(function (btags) {
                dispatch({type: ActionTypes.LOAD_BTAGS_SUCCESS, btags: btags});
            }, function (error) {
                dispatch({type: ActionTypes.LOAD_BTAGS_ERROR, error: error});
            });
    };
}

export function loadBtagProducts(id, filters) {
    let data = {
        id: id,
        page: 0,
        fetchCount: 48
    };
    if(typeof filters == 'object' && filters != null) {
        if(typeof filters.brand_id != 'undefined' && filters.brand_id.length > 0) {
            data.brand_id = filters.brand_id
        }
        if(typeof filters.lp != 'undefined' && filters.lp != null && filters.lp) {
            data.lp = parseInt(filters.lp)
        }
        if(typeof filters.hp != 'undefined' && filters.hp != null && filters.hp) {
            data.hp = parseInt(filters.hp)
        }
    }
    return dispatch => {
        httpRequest({
            url: config.baseUrl + '/api/btag/products',
            method: 'GET',
            data: data
        }).then(function (products) {
            dispatch({type: ActionTypes.LOAD_BTAG_PRODUCTS_SUCCESS, products: products});
        }, function (error) {
            dispatch({type: ActionTypes.LOAD_BTAG_PRODUCTS_ERROR, error: error});
        });
    };
}

export function loadBtagSpecials() {
    return dispatch => {
        httpRequest({
            url: config.baseUrl + '/api/btag/special',
            method: 'GET',
        }).then(function (specials) {
            dispatch({type: ActionTypes.LOAD_BTAG_SPECIAL_SUCCESS, specials: specials});
        }, function (error) {
            dispatch({type: ActionTypes.LOAD_BTAG_SPECIAL_ERROR, error: error});
        });
    };
}

export function loadBrand(btagId, searchWord = null) {
    let url = config.baseUrl + '/api/products/filter/brands';
    if (typeof searchWord == 'string')
        url += '?btag_id='+btagId+'&search_query='+searchWord;
    else
        url += '?btag_id='+btagId;
    return dispatch => {
        httpRequest({
            url: url,
            method: 'GET'
        }).then(function (brands) {
            dispatch({type: ActionTypes.LOAD_PRODUCT_BRANDS_SUCCESS, brands: brands});
        }, function (error) {
            dispatch({type: ActionTypes.LOAD_PRODUCT_BRANDS_ERROR, error: error});
        });
    };
}

export function loadPriceFilter(btagId, searchWord = null) {
    let url = config.baseUrl + '/api/products/filter/price';
    if (typeof searchWord == 'string')
        url += '?btag_id='+btagId+'&search_query='+searchWord;
    else
        url += '?btag_id='+btagId;
    return dispatch => {
        httpRequest({
            url: url,
            method: 'GET'
        }).then(function (prices) {
            dispatch({type: ActionTypes.LOAD_PRICE_FILTER_SUCCESS, prices: prices});
        }, function (error) {
            dispatch({type: ActionTypes.LOAD_PRICE_FILTER_ERROR, error: error});
        });
    };
}

export function filterChanged() {
    return dispatch => {
        dispatch({type: ActionTypes.FILTER_CHANGED});
    };
}