import React, { Component, PropTypes } from 'react';
import { Modal } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import config from '../config';

import * as productActions from '../actions/product';
import {addCartItem} from '../actions/cart';
import {checkIsLogged} from '../actions/account';
import ProductInfo from '../components/product/ProductInfo';

class AppContainer extends Component {

    constructor(props, context){
        super(props, context);

        this.state = {
            isShow : true,
            isQuickModal: false,
            product: undefined,

            user: {
                id: undefined,
                isLogged: false
            }
        }
    }

    componentDidMount(){
        this.props.checkIsLogged();
    }

    componentWillReceiveProps(nextProps){
        // if we changed routes...
        if ((
          nextProps.location.key !== this.props.location.key &&
          nextProps.location.state &&
          nextProps.location.state.modal && 
          nextProps.location.state.savePrev
        )) {
          // save the old children (just like animation)
          this.previousChildren = this.props.children

          this.setState({
            isShow: true
          })
        }

        this.setState({
            product: nextProps.productQ,
            isQuickModal: nextProps.isQuickModal,
            user: {
                id: nextProps.id,
                isLogged: nextProps.isLogged
            }
        })
    }

    getChildContext(){
        return this.state.user
    }

    handleModalClose(){
        let returnTo = (this.props.location.state && this.props.location.state.returnTo) || config.context;
        this.context.router.push(returnTo);
    }

    hideModal(){
        this.setState({
            isShow: false
        });
        this.handleModalClose()
    }

    hideQuickModal(){
        this.setState({
            product: undefined,
            isQuickModal: false
        })
    }

    renderQuickModal(){
        return (
            <Modal show={this.state.isQuickModal}
                    onHide={this.hideQuickModal.bind(this)}
                    style={{width: 'calc(100% - 50px)'}}
                    className="wide-modal">
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <ProductInfo {...this.props} 
                        product={this.state.product}
                        quickView={true}/>
                </Modal.Body>
            </Modal>
        )
    }

    render() {
        let { location } = this.props
        let isModal = (
          location.state &&
          location.state.modal &&
          this.previousChildren
        )
        
        return (
            <div>
                { 
                    isModal ?
                    this.previousChildren :
                    this.props.children
                }
                {   
                    isModal && <Modal show={this.state.isQuickModal}
                            onHide={this.hideQuickModal.bind(this)}
                            style={{width: 'calc(100% - 50px)'}}
                            className="wide-modal">
                        <Modal.Header closeButton></Modal.Header>
                        <Modal.Body>
                            {
                                React.cloneElement(this.props.children, {
                                    hideModal: this.hideModal.bind(this)
                                })
                            }
                        </Modal.Body>
                    </Modal>
                }
                {
                    this.state.isQuickModal && this.renderQuickModal()
                }
            </div>
        );
    }
}

AppContainer.contextTypes = {
    router: PropTypes.object.isRequired
}

AppContainer.childContextTypes = {
    isLogged: PropTypes.bool.isRequired,
    id: PropTypes.string
}

export default connect(
    (state, props) => {
        return {
            productQ: typeof state.products.productQ === 'string' ? JSON.parse(state.products.productQ) : state.products.productQ,
            isQuickModal: state.products.isQuickModal,
            isLogged: state.account.isLogged,
            id: state.account.id
        } 
    },
    (dispatch) => bindActionCreators(Object.assign({}, productActions, {addCartItem}, {checkIsLogged}), dispatch)
)(AppContainer);