import config from './config';
import CryptoJS from 'crypto-js';

export function range(size) {
    let array = [];
    for (let i = 1; i <= size; ++i) {
        array.push(i);
    }
    return array;
}

export function strCompare(a, b) {
    if (a > b) {
        return 1;
    }
    if (a < b) {
        return -1;
    }
    return 0;
}

export function productStatusToText(statusCode, stock) {
    if (statusCode === 'IN_STOCK' || statusCode === 'AVAILABLE') {
        return 'Барааны нөөц ' + stock;
    }

    return {
        'AVAILABLE': 'Бэлэн байгаа',
        'IN_STOCK': 'Агуулахад байгаа',
        'OUT_OF_STOCK': 'Дууссан',
        'ORDERED': 'Захиалсан байгаа, удахгүй ирнэ',
        'NOT_AVAILABLE': 'Зарагдахаа больсон, захиалга авахгүй болсон'
    }[statusCode];
}

export function formatCurrency(amount, precision = 0, decimalPoint = ',', thousandsSeparator = "'") {
    let rounded = Math.abs(+amount || 0).toFixed(precision),
        sign = amount < 0 ? "-" : "",
        intPart = parseInt(rounded, 10) + "",
        tmp = intPart.length > 3 ? intPart.length % 3 : 0;
    return sign + (tmp ? intPart.substr(0, tmp) + thousandsSeparator : "") +
        intPart.substr(tmp).replace(/(\d{3})(?=\d)/g, "$1" + thousandsSeparator) +
        (precision ? decimalPoint + rounded.slice(-2) : "");
}

export function countItems(items) {
    let sum = 0;
    for (let item of items) {
        sum += item.productQuantity;
    }
    return sum;
}

/**
 * Sends an AJAX request using jQuery.ajax(). What makes it different from calling the jQuery function
 * directly is that this function sends the request again if it times out.
 *
 * @param {Object} options
 * @returns {Promise}
 */
export function httpRequest(options) {
    if (options.method !== 'GET') {
        options.headers = Object.assign({
            'X-CSRF-TOKEN': config.csrfToken
        }, options.headers);
    }

    function foobar(data){
        let _foo = (data) => {
            try {
                var cipherText;
                if(typeof data === 'string')
                    cipherText = atob(data);
                else cipherText = atob(data.data);

                var decrypted = CryptoJS.AES.decrypt(cipherText, btoa(cipherText.substr(0, 8)), {format: getFormat()});
                return JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));
            }
            catch(e) {
                return data;
            }
            
        }, getFormat = () => {
            return {
                stringify: function (cipherParams) {
                    var j = {ct: cipherParams.ciphertext.toString(CryptoJS.enc.Base64)};
                    if (cipherParams.iv)
                        j.iv = cipherParams.iv.toString();
                    if (cipherParams.salt)
                        j.s = cipherParams.salt.toString();
                    return JSON.stringify(j);
                },
                parse: function (data) {
                    var cipherParams = CryptoJS.lib.CipherParams.create({ciphertext: CryptoJS.enc.Latin1.parse(data.substr(32))});
                    cipherParams.iv = CryptoJS.enc.Latin1.parse(data.substr(8, 16));
                    cipherParams.salt = CryptoJS.enc.Latin1.parse(data.substr(24, 8));
                    return cipherParams;
                }
            }
        }

        return _foo(data);
    }

    return new Promise(function (resolve, reject) {
        function makeRequest() {
            $.ajax(options)
                .done(function (response) {
                    if(Object.keys(response).length === 1 && "data" in response)
                        resolve(foobar(response.data));
                    else resolve(response)
                })
                .fail(function (xhr, textStatus, errorThrown) {
                    if (textStatus === 'timeout') {
                        makeRequest();
                    } else {
                        reject({message: errorThrown});
                    }
                });
        }
        makeRequest();
    });
}

export function uniqueArray(srcArray, sorter){
    let destArray = [],
        nonDuplicateArray = {};

    srcArray.forEach(item => {
        if(sorter)
            nonDuplicateArray[item[sorter]] = item;
        else nonDuplicateArray[item] = item
    });

    let i = 0;

    for(let item in nonDuplicateArray)
        destArray[i++] = nonDuplicateArray[item];

    return destArray;
}
