import config from '../config';
import * as ActionTypes from '../constants/ActionTypes';
import { httpRequest } from '../helpers';

export function loadBanners(type) {
    return dispatch => {
            httpRequest({
                url: config.baseUrl + '/api/banner/list',
                method: 'GET',
                data: {type: type}
            }).then(function (banners) {
                dispatch({type: ActionTypes.LOAD_BANNERS_SUCCESS, banners: banners});
            }, function (error) {
                dispatch({type: ActionTypes.LOAD_BANNERS_ERROR, error: error});
            });
    };
}

export function loadHomepageCategorizedBanners() {
    return dispatch => {
            httpRequest({
                url: config.baseUrl + '/api/category/homepage-banners',
                method: 'GET',
                data: {}
            }).then(function (banners) {
                dispatch({type: ActionTypes.LOAD_CATEGORIZED_BANNERS_SUCCESS, categorizedBanners: banners});
            }, function (error) {
                dispatch({type: ActionTypes.LOAD_CATEGORIZED_BANNERS_ERROR, error: error});
            });
    };
}
export function loadHomepageBrandWeekBanners() {
    return dispatch => {
            httpRequest({
                url: config.baseUrl + '/api/brand/homepage-banners',
                method: 'GET',
                data: {}
            }).then(function (banners) {
                dispatch({type: ActionTypes.LOAD_BRANDWEEK_BANNERS_SUCCESS, brandWeekBanners: banners});
            }, function (error) {
                dispatch({type: ActionTypes.LOAD_BRANDWEEK_BANNERS_ERROR, error: error});
            });
    };
}