import {getValues} from 'redux-form';

let cartForm = localStorage.getItem('cartForm');
if (cartForm !== null && cartForm.session === global.sessionIdHash) {
    cartForm = JSON.parse(cartForm);
}

export function getFormValues() {
    return cartForm && cartForm.values;
}

export function getBuilding() {
    return cartForm && cartForm.building;
}

export function startPersisting() {
    return setInterval(function () {
        let state = global.store.getState();
        localStorage.setItem('cartForm', JSON.stringify({
            session: global.sessionIdHash,
            values: getValues(state.form.cartForm),
            building: state.cart.building
        }));
    }, 1000);
}

export function stopPersisting(intervalId) {
    clearInterval(intervalId);
}
