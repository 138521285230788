import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as productActions from '../actions/product';
import {addCartItem} from '../actions/cart';
import config from '../config';
import ProductListContainer from './ProductListContainer';

class SearchListContainer extends Component {
    constructor(props){
        super(props);
    }

    renderLoading() {
        return (
            <div className="alert alert-info" style={{marginBottom:5}}>
                <strong>{this.props.params.query}</strong> хайлтад тохирох бүтээгдэхүүнүүдийг шүүж байна. Түр хүлээнэ үү...
            </div>
        )
    }

    renderResult() {
        if(!this.props.isLoading)
            if(!this.props.total)
                return <div className="alert alert-warning" style={{marginBottom:5}}><strong>{this.props.params.query}</strong> хайлтад тохирох бүтээгдэхүүн алга байна!</div>
            else return <div className="alert alert-success" style={{marginBottom:5}}><strong>{this.props.params.query}</strong> хайлтад тохирох ({this.props.total}) бүтээгдэхүүн байна.</div>
        
        return this.renderLoading()
    }

    render() {
        return (
            <div>
                { this.renderResult() }
                <ProductListContainer {...this.props} />
            </div>
        );
    }
}

export default connect(
    (state, props) => {
        return {
            isLoading: state.products.isLoading,
            total: state.products.total
        } 
    }
)(SearchListContainer);
