import React, { Component, PropTypes } from 'react';
import { Link } from 'react-router';
import toastr from 'toastr';
import Rating from 'react-rating';
import { Tabs, Tab, Carousel, CarouselItem, Modal } from 'react-bootstrap';

import ImageZoomer from '../ImageZoomer';
import { formatCurrency, uniqueArray } from '../../helpers';
import config from '../../config';
import Preloader from '../Preloader';

class ProductInfo extends Component {
    constructor(props, context) {
        super(props, context);

        this.color = '';
        this.state = {
            product: undefined,
            loading: true,
            available: true,
            selected: {
                productVarId : undefined,
                color: undefined,
                size: undefined,
                stock: 0
            },
            uniqueSizes: [],
            uniqueColors: [],
            activeIndex: 0
        }
    }

    componentDidMount(){
        this.init();
    }

    componentWillReceiveProps(nextProps) {
        if(!nextProps.product) return;
        
        this.init(nextProps.product);
    }

    init(product = this.props.product) {
        if(!product) return;
        let productVarId = product.productVarId,
            selectedVar = product.sizeList.find(s => s.prodVarId === productVarId),
            selected = {
                productVarId: productVarId,
                size: selectedVar.id,
                color: selectedVar.color.colorCode,
                stock: product.stock
            };
        this.color = selectedVar.color.colorCode;

        this.setState({
            product: product,
            selected: selected,
            loading: false,
        });

        this.prepareSizeColors(product);
    }

    prepareSizeColors(product){
        if(!product) return;
        let sizeList = product.sizeList.slice(0);
        sizeList.forEach(size => size.colorCode = size.color.colorCode);

        let uniqueSizes = uniqueArray(sizeList, 'id').slice(0);
        let uniqueColors = uniqueArray(sizeList, 'colorCode').slice(0);

        uniqueSizes.forEach(s1 => {
            s1.colors = sizeList.filter(s2 => s1.id === s2.id)
        });

        uniqueColors.forEach(s1 => {
            s1.sizes = sizeList.filter(s2 => s1.colorCode === s2.colorCode)
        });

        this.setState({
            uniqueColors: uniqueColors,
            uniqueSizes: uniqueSizes
        })
    }

    /**
    * Actions
    */

    /**
    * Барааг хадгалах
    * productVarId-р хадгалах
    */
    handleSave(){
        if(this.context.isLogged) 
            this.props.saveProduct(this.state.selected.productVarId, !this.state.product.isSaved, ((result) => {
                let product = this.state.product;
                product.isSaved = result;
                this.setState({
                    product : product
                })
            }).bind(this));
        else toastr.error('Нэвтэрсэн хэрэглэгч бүтээгдэхүүнийг хадгалах боломжтой!');
        
    }

    /**
    * Сагсанд барааг нэмэх
    */
    addToCart(barcode) {
        let qty = this.refs.qty.value;
        if(!/\d/.test(qty) || !qty.length){
            this.refs.qty.value = qty = 1;
        }
        this.props.addCartItem(this.state.selected.productVarId, qty, barcode);
    }

    /**
     * Сагсанд бараа нэмэх киноп дарагдах үед ажиллана
     */
    handleAddToCart(event) {
        event.preventDefault();
        this.addToCart();
    }

    /**
    * Тоо хэмжээ дээр зөвхөн тоо оруулах
    */
    handleKeyPress(event){
        var charCode = event.which || event.keyCode;
        var charStr = String.fromCharCode(charCode);
        if (!/\d/.test(charStr) )
            return event.preventDefault();        
    }

    onQtyChange(event) {
        let available = true
        if(Number(this.refs.qty.value) > Number(this.state.selected.stock) )
            available = false;
        this.setState({
            available: available
        })
    }

    handleSizeChange(){
        let sizeId = this.refs.size.value;

        let size = this.state.uniqueSizes.find(s => s.id === sizeId);

        let productVar = size.colors.find(c => c.colorCode === this.color);
        if(!productVar) return this.setState({available: false});

        let product = Object.assign({}, 
                        this.state.product, 
                        {
                            productCode: productVar.prodVarProductCode, 
                            price: productVar.price, 
                            oldPrice: productVar.oldPrice,
                            salePercent: productVar.salePercent,
                            hasGift: productVar.hasGift
                        });;

        this.setState({
            product: product,            
            available: true,
            selected: Object.assign({}, this.state.selected, { size: sizeId, productVarId: productVar.prodVarId, stock: productVar.stock })
        })
    }

    handleColorChange(colorCode){
        // if(colorCode === this.state.selected.color) return;
        let color = this.state.uniqueColors.find(c => c.colorCode === colorCode);

        this.color = colorCode;

        this.setState({
            selected: Object.assign({}, this.state.selected, { color: colorCode })
        })

        let productVar = color.sizes.find(s => s.id === this.refs.size.value);
        if(!productVar) return this.setState({available: false});
        
        let product = Object.assign({}, 
                        this.state.product, 
                        {
                            productCode: productVar.prodVarProductCode, 
                            price: productVar.price, 
                            oldPrice: productVar.oldPrice,
                            salePercent: productVar.salePercent,
                            hasGift: productVar.hasGift
                        });

        this.setState({
            product: product,
            available: true,
            selected: Object.assign({}, this.state.selected, { color: colorCode, productVarId: productVar.prodVarId, stock: productVar.stock })
        })
    }

    // Тухайн размер, өнгөтэй бараа байшаа эсэхийг шалгах
    isNotAvailableSize(size){
        return size.colors.filter(color => color.colorCode === this.color).length === 0;
    }

    onCarouselSelect(selectedIndex) {
        this.setState({
            activeIndex: selectedIndex
        })
    }

    onShareFB(){
        FB.ui({
          method: 'share',
          href: location.href,
        }, function(response){});
    }

    onTweet(product){
        let intentURL = `https://twitter.com/intent/tweet?original_referer=${encodeURIComponent(location.href)}
            &ref_src=twsrc%5Etfw
            &text=${encodeURIComponent(product.productName.replace(/\//g, '-'))}
            &tw_p=tweetbutton
            &url=${encodeURIComponent(location.href)}`

        window.open(intentURL, 'Share Link on Twitter', 'width=550,height=420')
    }

    /**
    * Render functions
    */

    renderCarousel(product){
        return (
            <div className="row">
                {                 
                    product.fileList && product.fileList.length ?
                    <div className="col-xs-12 col-sm-12 col-md-2">
                        <div className="carousel-indicator">
                            {
                                product.fileList.map((file, i) => (
                                    <a href="javascript:;" key={file.fileId} 
                                        className={i === this.state.activeIndex ? 'active' : ''}
                                        onClick={this.onCarouselSelect.bind(this, i)}>
                                        <img src={[config.cdnUrl, 'img/', file['fileId'], '?h=300'].join('')} alt={file.fileId}/>
                                    </a>
                                ))
                            }
                        </div>
                    </div>  : null
                }
                <div className="col-xs-12 col-sm-12 col-md-10">
                    <Carousel indicators={false}
                        controls={false}
                        interval={1000}
                        activeIndex={this.state.activeIndex}
                        onSelect={this.onCarouselSelect.bind(this)}>
                        { product.fileList && product.fileList.length ?
                            product.fileList.map(file => (<CarouselItem key={file.fileId} >
                                                            <ImageZoomer alt={file['fileId']}
                                                                src={[config.cdnUrl, 'img/', file['fileId'], '?h=500'].join('')}
                                                                zoomSrc={[config.cdnUrl, 'img/', file['fileId'], '?h=1500'].join('')}/>
                                                          </CarouselItem>)) :
                            <CarouselItem >
                                <img src={[config.baseUrl, '/images/empty.jpg'].join('')}/>
                            </CarouselItem>
                        }
                    </Carousel>
                </div>
            </div>            
        )
    }

    renderOptions(product){
        let colors = (this.state.uniqueColors || []).length ? 
                        <div className="row">
                            <div className="col-md-12">
                                <strong>Өнгө</strong>
                                <div className="colors">
                                    {
                                        (this.state.uniqueColors || []).map(color => 
                                            <div key={color.colorCode}
                                                 className={this.state.selected.color === color.colorCode ? 'selected' : ''}
                                                 onClick={this.handleColorChange.bind(this, color.colorCode)}>
                                                <span style={{backgroundColor: color.colorCode}}></span>
                                            </div>)
                                    }
                                </div>
                            </div>
                        </div> : null;
        let sizes = <div className="col-xs-12 col-sm-6 col-md-6">
                        <strong>Хэмжээ </strong>
                        {
                            false && <a href="#" data-toggle="modal" data-target="#sizeModal">Тайлбар</a>                            
                        }
                        <select className="form-control" ref="size" onChange={this.handleSizeChange.bind(this)}>
                            {
                                (this.state.uniqueSizes || []).map((size) => <option key={size.id} value={size.id} disabled={this.isNotAvailableSize(size)}>{size.text}</option>)
                            }
                        </select>
                    </div>;
        if(!this.state.uniqueSizes 
            || !this.state.uniqueSizes.length 
            || (this.state.uniqueSizes && this.state.uniqueSizes.length === 1 && (this.state.uniqueSizes[0].text === 'ширхэг' || !this.state.uniqueSizes[0].text.trim().length)))
            sizes = null;

        // Номын мэдээлэл бол өнгөний сонголтыг харуулахгүй
        if((product.fieldList && product.fieldList.length) || (this.state.uniqueColors && !this.state.uniqueColors.length)) 
            colors = null;

        return (
            <div className="product-options">
                { colors } 
                <div className="row">
                    { sizes } 
                    <div className="col-xs-12 col-sm-6 col-md-6">
                        <strong>Тоо</strong>
                        <input type="text" className="form-control text-right" 
                            defaultValue="1" ref="qty" 
                            maxLength="3" 
                            onKeyPress={this.handleKeyPress.bind(this)}
                            onChange={this.onQtyChange.bind(this)}/>
                    </div>
                </div>
            </div>
        )
    }

    renderBookInfo(product){
        if(!product.fieldList || !product.fieldList.length) return null;
        return (
            <div className="product-options">
                {
                    product.fieldList.map(field => (
                        <div key={field.fieldId} className="row">
                            <div className="col-md-12">
                                <strong>{field.fieldName}</strong>
                                <div>{field.fieldValue === 'true' ? 'Тийм' : field.fieldValue === 'false' ? 'Үгүй' : field.fieldValue}</div>
                            </div>
                        </div>
                    ))
                }
            </div>
        )
    }

    renderProductInfo(product, options){
        options = options || {};
        return (
            <div className="row">
                <div className="col-md-8">
                    <div className="product-carousel">
                        { this.renderCarousel(product) }
                        <div className="action">
                            {
                                product['video'] && product['video'].length ? 
                                <div className="wvideo" data-toggle="modal" data-target="#videoModal"> <span className="play"></span>Видео үзэх </div> : null
                            }
                        </div>
                        <div className="product-labels">
                        { 
                            product['salePercent'] && product['salePercent'] != 0 ?
                                <div className="product-label product-label-sale"><span>{formatCurrency(product.salePercent, 0)}</span>{`%`}</div> : null                                                       
                        }
                        { 
                            product['hasGift'] ?
                                <label><span className="fa fa-gift"></span></label> : null                                                       
                        }
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="product-info">
                        <h1>{product['productName']}</h1>
                        <div className="row">
                            <div className="col-md-12">                                
                                <h4>{`${product['vendorName']} ${product['productCode']}` }</h4>
                            </div>
                        </div>
                        <div className="row">
                        <div className="col-md-12">
                            <div className="product-rating text-left">
                                    <Rating initialRate={product['averageRate'] || 0} 
                                        readonly={true} 
                                        step={1}
                                        max={5}
                                        fractions={2}
                                        empty="zmdi zmdi-star"
                                        placeholder="zmdi zmdi-star rated"
                                        full="zmdi zmdi-star rated" />
                                </div>
                            </div>
                        </div>
                        <h3 className="product-price">
                            {
                                product['oldPrice'] && product['oldPrice'] != 0 ? 
                                    <del>{`${formatCurrency(product['oldPrice'])}₮`}</del> : null
                            }
                            {`${formatCurrency(product['price'])}₮`}
                        </h3>
                        { this.renderOptions(product) }                        
                        { this.renderBookInfo(product) }                     
                        <div className="product-actions text-center">
                            <button className="btn btn-large btn-primary btn-lg btn-block" onClick={this.handleAddToCart.bind(this)} disabled={!this.state.available}>
                                <span className="zmdi zmdi-shopping-cart"></span> Сагсанд нэмэх
                            </button>
                            <div className="clearfix">
                                <a href="javascript:;" onClick={this.handleSave.bind(this)} className="product-action">
                                    <span className={'heart' + (product.isSaved? ' hearted' : '')}></span>
                                </a>
                                <a href="javascript:;" className="product-action" onClick={this.onShareFB.bind(this)}>
                                    <span className='zmdi zmdi-facebook'></span>
                                </a>
                                <a href="javascript:;" className="product-action" onClick={this.onTweet.bind(this, product)}>
                                    <span className='zmdi zmdi-twitter'></span>
                                </a>
                            </div>
                            {
                                options.renderMoreButton ?
                                <Link to={`${config.context}product/${product.productVarId}`} 
                                    className="btn btn-large btn-default btn-lg btn-block">
                                    Дэлгэрэнгүй
                                </Link> : null
                            }
                            
                        </div>
                        {
                            !this.state.available && <div className="alert alert-danger">Таны сонгосон бүтээгдэхүүний нөөц хүрэлцэхгүй байна!</div>
                        }
                    </div>
                </div>
            </div>
        )
    }

    render(){
        let {product} = this.state,
        options = {};

        if(!product || product === {} || this.state.loading)
            return <Preloader/>;

        if(this.props.quickView) 
            options = { renderMoreButton: true }

        return (
            <div className="product-detail margin-bottom">
                { this.renderProductInfo(product, options) }
            </div>
        )
    }
}


ProductInfo.displayName = 'ProductInfo';

ProductInfo.contextTypes = {
    isLogged: PropTypes.bool.isRequired,
    id: PropTypes.string
}

export default ProductInfo;
