import React, { Component, PropTypes } from 'react';
import ReactDOM from 'react-dom';
import moment from 'moment';
import Helmet from 'react-helmet';
import { Link } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Rating from 'react-rating';
import Masonry from 'react-masonry-component';
let InfiniteScroll = require('../components/react-infinite-scroll')(React, ReactDOM);

import * as contentActions from '../actions/bcontent';
import * as bannerActions from '../actions/banner';
import {uniqueArray} from '../helpers';
import config from '../config';
import Preloader from '../components/Preloader';

class ContentListContainer extends Component {
    constructor(props){
        super(props);

        this.state = {
            page: 0,
            contents: [],
            hasMore: true,

            mostRated: [],
            mostCommented: []
        }
    }

    componentWillMount(){
        this.props.loadSideContents();
        this.props.loadBanners('BABY-B1');
    }

    componentWillReceiveProps(nextProps) {
        let contents = this.state.contents;
        this.setState({
            contents: uniqueArray(contents.concat(nextProps.contents), 'bcontentId'),
            hasMore: nextProps.contents.length === this.props.fetchCount
        });
    }

    fetchContents(page,  fetchCount, callback){
        this.props.loadContents(page - 1, fetchCount, callback);
    }

    loadContents(page){
        this.fetchContents(page, this.props.fetchCount, this.onCallback.bind(this));
    }

    onCallback(page){
        this.setState({
            page: page + 1
        })
    }

    getImageURL(contentItem){
        return contentItem ? [config.cdnUrl, 'img/', contentItem].join('') : [config.baseUrl, '/images/empty.jpg'].join('')
    }

    /**
    * Их үнэлгээтэй, Их сэтгэгдэлтэй контентийн жагсаалтыг харуулах
    */
    renderNavList(headerText, contents){
        if(!contents.length) return null

        return (
            <div className="content-nav-list">
                <h2>{headerText}</h2>
                <ul>
                    {
                        contents.map(content => (
                                <li key={content.bcontentId}>
                                    <Link to={{pathname: `${config.context}bcontent/${content.bcontentId}/${encodeURIComponent(content.bcontentTitle.replace(/\//g, '-'))}`}}
                                        className="clearfix">
                                        <figure>
                                            <img src={this.getImageURL(content.contentItem)} alt={content.bcontentTitle}/>
                                        </figure>
                                        <h3>{content.bcontentTitle}</h3>
                                        <span>{content.createdDate ? moment(content.createdDate).format('YYYY/MM/DD'): null}</span>
                                    </Link>
                                </li>   
                            )
                        )
                    }
                </ul>
            </div>
        );
    }

    /**
    * Banner-уудыг харуулах
    */
    renderBanners(){
        return (
            <div className="banner-b1"> 
                {
                    this.props.banners.map(banner => (
                            <a href={banner.bannerUrl && banner.bannerUrl.length ? banner.bannerUrl : 'javascript:;'} key={banner.bannerId}>
                                <img src={`${config.cdnUrl}img/${banner.fileId}`}/>
                            </a>  
                        )
                    )
                }
            </div>
        );
    }
    

    /**
    * Render contents to jsx
    */
    renderContents(){
        let {contents} = this.state;
        return contents.map(content => (
                <div key={content.bcontentId} className="col-xs-12 col-sm-6 col-md-4">
                    <Link to={{pathname: `${config.context}bcontent/${content.bcontentId}/${encodeURIComponent(content.bcontentTitle.replace(/\//g, '-'))}`}}
                        className="bcontent">
                        <img src={this.getImageURL(content.contentItem)} alt={content.bcontentTitle} />
                        <div className="row">
                            <div className="col-sm-8 product-rating text-left">
                                <Rating initialRate={content['averageRate'] || 0} 
                                        readonly={true} 
                                        step={1}
                                        max={5}
                                        fractions={2}
                                        empty="zmdi zmdi-star"
                                        placeholder="zmdi zmdi-star rated"
                                        full="zmdi zmdi-star rated" />
                            </div>
                            <div className="col-sm-4">
                                <div className="content-review-stat">
                                    <i className="zmdi zmdi-comment-outline"></i> {content.reviewCount || 0}
                                </div>
                            </div>
                        </div>
                        <h3 className="content-title">{content.bcontentTitle}</h3>
                    </Link>
               </div>
            )
        )
    }

    render() {
        let options = {
          itemSelector: '.col-md-4',
          gutter: 0,
          transitionDuration: 0
        }, title='BContent';

        let mostRated, mostCommented, {sideContents} = this.props;

        mostRated = sideContents[0] || [];
        mostCommented = sideContents[1] || [];


        return (
            <div className="margin-bottom">
                <Helmet title={title}
                    titleTemplate={config.titleTemplate}/>

                <div className="row">
                    <div className="col-md-3 hidden-sm hidden-xs">
                        <div className="content-side-nav">
                            { this.renderNavList('Их үнэлгээтэй', mostRated)}
                            { this.renderNavList('Их сэтгэгдэлтэй', mostCommented)}
                            { this.renderBanners() }
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-9">
                        <InfiniteScroll 
                            ref="infiniteScroll"
                            pageStart={this.state.page - 1} 
                            loadMore={this.props.isLoading ? (page) => {this.refs.infiniteScroll.pageLoaded = page - 1} : this.loadContents.bind(this)}
                            hasMore={this.state.hasMore}
                            loader={<Preloader/>}>
                            <Masonry className="row" options={options}>
                                {this.renderContents()}
                            </Masonry>
                        </InfiniteScroll>
                    </div>
                </div>

            </div>
        )
    }
}


ContentListContainer.displayName = "ContentListContainer";

ContentListContainer.propTypes = {
    fetchCount: PropTypes.number,
}

ContentListContainer.defaultProps = {
    fetchCount: 10
}


export default connect(
    (state, props) => {
        return {
            contents: typeof state.bcontent.contents === 'string' ? JSON.parse(state.bcontent.contents) : state.bcontent.contents,
            isLoading: state.bcontent.isLoading,
            sideContents: state.bcontent.sideContents,            
            banners: typeof state.banner.banners === 'string' ? JSON.parse(state.banner.banners) : state.banner.banners,
        } 
    },
    (dispatch) => bindActionCreators(Object.assign({}, contentActions, bannerActions), dispatch)
)(ContentListContainer);