import React, { Component } from 'react';
import { connect } from 'react-redux';
import toastr from 'toastr';
import CartItem from '../components/CartItem';
import CartItemSimple from '../components/CartItemSimple';
import { updateCartItem, deleteCartItem } from '../actions/cart';
import { loadProduct, saveProduct } from '../actions/product';

class CartItemContainer extends Component {
    componentWillMount() {
        if (!this.props.product) {
            this.props.loadProduct(this.props.item.productVarId, this.props.item.productCode);
        }
    }

    componentWillReceiveProps(props) {
        if (this.props.product !== props.product) {
            this.props.loadProduct(props.item.productVarId, props.item.productCode);
        }
    }

    updateItem(productVarId, productQuantity) {
        this.props.updateCartItem(this.props.item.orderDtlId, productVarId, productQuantity)
    }

    deleteItem() {
        this.props.deleteCartItem(this.props.item.orderDtlId);
    }

    addToFavorites(isFavorite) {
        if (global.user.id) {
            this.props.saveProduct(this.props.item.productVarId, isFavorite, function () {});
        } else {
            toastr.error('Нэвтэрсэн хэрэглэгч бүтээгдэхүүнийг хадгалах боломжтой!');
        }
    }

    render() {

        if(this.props.simple) {
            return <CartItemSimple item={this.props.item}
                      product={this.props.product} />
        }

        return (
            <CartItem item={this.props.item} 
                product={this.props.product} 
                delete={this.deleteItem.bind(this)}
                update={this.updateItem.bind(this)} 
                addToFavorites={this.addToFavorites.bind(this)}
                quickCart={this.props.quickCart} />
        );
    }
}

export default connect(
    (state, props) => {
        return {
            product: state.products.productCache.hasOwnProperty(props.item.productVarId)
                ? state.products.productCache[props.item.productVarId] : null
        };
    },
    { loadProduct, updateCartItem, deleteCartItem, saveProduct }
)(CartItemContainer);
