import {
    LOAD_BCONTENTS_REQUEST,
    LOAD_BCONTENTS_SUCCESS,
    LOAD_BCONTENT_REQUEST,
    LOAD_BCONTENT_SUCCESS,
    LOAD_SIDE_BCONTENTS_REQUEST,
    LOAD_SIDE_BCONTENTS_SUCCESS
} from '../constants/ActionTypes';

const initialState = {
    contents: [],
    content: undefined,
    isLoading: false,
    sideContents: []
};

function btag(state = initialState, action) {
    switch (action.type) {

        case LOAD_BCONTENTS_REQUEST:
        case LOAD_BCONTENT_REQUEST: 
            return Object.assign({}, state, {
                isLoading: true
            });

        case LOAD_SIDE_BCONTENTS_REQUEST: 
            return Object.assign({}, state, {
                sideContents: []
            }); 
        
        case LOAD_BCONTENTS_SUCCESS: 
            return Object.assign({}, state, {
                contents: action.contents,
                isLoading: false
            });

        case LOAD_BCONTENT_SUCCESS: 
            return Object.assign({}, state, {
                content: action.content,
                isLoading: false
            });        

        case LOAD_SIDE_BCONTENTS_SUCCESS: 
            return Object.assign({}, state, {
                sideContents: action.contents,
            });

        default:
            return state;
    }
}

export default btag;
