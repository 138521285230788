import React, { Component } from 'react';
import { Link } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as productActions from '../actions/product';
import * as accountActions from '../actions/account';
import {addCartItem} from '../actions/cart';
import config from '../config';

class AccountContainer extends Component {
    constructor(props){
        super(props);
    }

    componentDidMount() {
        this.props.loadUserPhoto();
    }

    render() {
        let { photo } = this.props,
            userPhoto;
        if (photo && photo.id != 'EMPTY_PROFILE_PIC')
            userPhoto = [config.cdnUrl, 'img/', photo.id].join('');
        else 
            userPhoto = [config.cdnUrl, 'img/101455674550930'].join('');        

        return (
            <div className="row">
                <div className="col-md-2">
                    <div className="info-left-container">
                        <div className="row">
                            <div className="col-xs-12 col-sm-6 col-md-12">
                                <div className="text-center">
                                    <img src={userPhoto} className="img-circle img-responsive"/>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-6 col-md-12">                                
                                <ul className="nav nav-pills nav-stacked">
                                    <li>
                                        <Link to={config.context + 'account'} activeClassName="active" onlyActiveOnIndex={true}>Миний мэдээлэл</Link>
                                    </li>
                                    <li>
                                        <Link to={config.context + 'account/liked-product'} activeClassName="active">Миний дуртай</Link>
                                    </li>
                                    <li>
                                        <Link to={config.context + 'account/orders'} activeClassName="active">Захиалга хянах</Link>
                                    </li>
                                    <li>
                                        <a href='https://accounts.minu.mn/user/changePassword'>Нууц үг солих</a>
                                    </li>
                                    <li>
                                        <a href={config.context + 'logout'}>Гарах</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-10">
                    {this.props.children && React.cloneElement(this.props.children, { ...this.props })}
                </div>
            </div>
        );
    }
}

export default connect(
    (state, props) => {
        return {
            products: typeof state.account.products === 'string' ? JSON.parse(state.account.products) : state.account.products,
            user: typeof state.account.user === 'string' ? JSON.parse(state.account.user) : state.account.user,
            photo: typeof state.account.photo === 'string' ? JSON.parse(state.account.photo) : state.account.photo,
            orders: typeof state.account.orders === 'string' ? JSON.parse(state.account.orders) : state.account.orders,
        } 
    },
    (dispatch) => bindActionCreators(Object.assign({}, productActions, accountActions, {addCartItem}), dispatch)
)(AccountContainer);
