import React, { Component } from 'react';
import { Tabs, Tab, Carousel, CarouselItem, Modal } from 'react-bootstrap';
import Helmet from 'react-helmet';
import toastr from 'toastr';
import Rating from 'react-rating';
import Slider from 'react-slick';
import { formatCurrency, uniqueArray } from '../helpers';
import config from '../config';
import ProductReview from './ProductReview';
import Product from './Product';
import CouponModal from './CouponModal';
import Preloader from './Preloader';
import PathMenu from './PathMenu';
import ProductInfo from './product/ProductInfo';

class ProductDetail extends Component {
    constructor(props) {
        super(props);

        this.state = {
            product: undefined,
            loading: true,
            available: true,
            selected: {
                productVarId : undefined,
                color: undefined,
                size: undefined,
                stock: 0
            },
            showDiscountModal: false,
            showCouponModal: false,
            activeIndex: 0
        }
    }

    componentDidMount(){
        this.props.viewProduct(this.props.params.id);
        $('.back-to-top').trigger('click');
    }

    componentWillReceiveProps(nextProps) {  
        this.setState({
            product: nextProps.product,
            loading: false
        });
    }

    componentWillUpdate(nextProps){
        if(this.props.params.id && nextProps.params.id && this.props.params.id !== nextProps.params.id)
        {
            this.setState({
                loading: true,
                product: undefined
            })
            this.props.viewProduct(nextProps.params.id);
        }
    }

    /**
    * Actions
    */

    // Тухайн размер, өнгөтэй бараа байшаа эсэхийг шалгах
    isNotAvailableSize(size){
        return size.colors.filter(color => color.colorCode === this.color).length === 0;
    }

    showCouponModal(){
        this.setState({ showCouponModal: true });
    }

    showDiscountModal(){
        this.setState({ showDiscountModal: true });
    }

    hideCouponModal(){
        this.setState({ showCouponModal: false });
    }

    hideDiscountModal(){
        this.setState({ showDiscountModal: false });
    }

    onDiscountClick(){
        this.props.sendDiscount(this.state.product.productVarId, (resp) => {
            if(resp && resp.hasOwnProperty('error'))
                return toastr.error(resp['error']);
            else if(resp && !resp.success)
                return toastr.error('B-Discount илгээх үед алдаа гарлаа!');
            else {
                this.hideDiscountModal();
                toastr.success('B-Discount амжилттай илгээлээ.');
                $('#toast-container').css('z-index', '2099');
            }
        });
    }

    /**
    * Render functions
    */

    renderCoupon(){
        return <div className="row">
                    <div className="col-md-12">
                        <a href="javascript:;" onClick={this.showCouponModal.bind(this)}>
                            <img style={{width: '100%'}} src={[config.baseUrl, '/images/b-coupon.png'].join('')} />
                        </a>
                    </div>
                </div>
    }

    renderDiscount(){
        return <div className="row">
                    <div className="col-md-12">
                        <a href="javascript:;" onClick={this.showDiscountModal.bind(this)}>
                            <img style={{width: '100%'}} className="img-responsive" src={[config.baseUrl, '/images/b-discount.png'].join('')} />
                        </a>
                    </div>
                </div>
    }

    renderDiscountModal(){
        return <Modal show={this.state.showDiscountModal}
                    onHide={this.hideDiscountModal.bind(this)}
                    className="customModal over-zoom">
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <div className="modal-content">
                        <div className="modal-body minu-coupon-header">
                            <h1>[  <span className="light-text">Хайлтын шинэ хувьсгал</span>  ] B-Discount Card</h1>
                        </div>
                        <div className="modal-body minu-coupon-body has-info-mark">
                            <div className="row">
                                <div className="col-md-12">
                                    Та энэхүү сонгосон бараагаа <span className="minu-coupon-highlight">{formatCurrency(this.state.product.salePercentB, 2)}%</span> хувийн хөнгөлөлттэйгээр 
                                    <span className="minu-coupon-highlight">{formatCurrency(this.state.product.priceB)}</span>
                                    төгрөгөөр худалдан авахыг хүсвэл Илгээх товчийг дарна уу. Таны бүртгэлтэй мэйл хаяг руу мөн бүртгэлтэй гар утасны дугаар руу B-Discount Card илгээх бөгөөд Та дэлгүүрт хүрэлцэн очиж B-Discount Card 
                                    үзүүлснээр бараагаа хөнгөлөлттэй худалдан авах боломжтой болно.
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-2 col-md-offset-10"><button onClick={this.onDiscountClick.bind(this)}>Илгээх</button></div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
    }

    /**
    * Ижил төстэй, санал болгож бүтээгдэхүүнийг харуулах
    */
    renderCarouselItems(products){
        return products.map(item => (
            <div className="col-xs-1 col-sm-2 col-md-3" key={item.id}>
                <Product 
                    product={item} 
                    addCartItem={this.props.addCartItem}
                    saveProduct={this.props.saveProduct}
                    viewProduct={this.props.quickViewProduct}
                    returnTo={this.props.location.pathname} />
            </div>
        ))

    }

    renderQuickView(product) {        
        let options = {
            renderMoreButton: true
        }
        return (
            <div className="product-detail margin-bottom">
                { this.renderProductInfo(product, options) }
            </div>
        )
    }

    render(){
        let {product} = this.state;

        if(!product || product === {} || this.state.loading)
            return <Preloader/>;

        let options = {
            arrows: true,
            dots: false,
            infinite: false,
            slidesToShow: 3,
            slidesToScroll: 1,
            className: 'product-carousel',
            responsive: [
                {
                  breakpoint: 940,
                  settings: {
                    slidesToShow: 2
                  }
                },
                {
                  breakpoint: 768,
                  settings: {
                    slidesToShow: 2,
                    arrows: false
                  }
                },
                {
                  breakpoint: 480,
                  settings: {
                    slidesToShow: 1,
                    arrows: false
                  }
                }
            ]
        };

        let title = product.productName,
        paths = [title]

        /*if(localStorage.pathName) {
            paths = JSON.parse(localStorage.pathName);
            paths.push(product.productName)
        }*/

        if(product.brandId && product.brandName) {
            paths = JSON.parse('[{"text":"'+product.brandName+'","href":"/brand/'+product.brandId+'?name='+product.brandName+'"}]');
            paths.push(product.productName)
        }

        return (
            <div>
                <Helmet title={title}
                        titleTemplate={config.titleTemplate}/>
                <PathMenu paths={paths}/>
                <div className="product-detail margin-bottom">
                    <ProductInfo {...this.props} />
                    { product.hasCoupon ? this.renderCoupon() : null }           
                    { product.priceB && product.salePercentB ? this.renderDiscount() : null }  
                    <div className="row">
                        <div className="col-md-12">
                            <Tabs defaultActiveKey='main' className="product-description product-spacing">
                                <Tab eventKey='main' title="Дэлгэрэнгүй">
                                    { 
                                        product.description ? 
                                            <div dangerouslySetInnerHTML={{__html: product.description}}/> : 
                                            <ul><li><em>Бүтээгдэхүүний мэдээлэл оруулаагүй байна!</em></li></ul>
                                    }
                                </Tab>
                                <Tab eventKey='comment' title={`Хэрэглэгчийн сэтгэгдэл (${(product['reviewList'] || []).length })`}>
                                    <ProductReview id={product.id} 
                                                   reviews={product.reviewList} 
                                                   reviewFields={product.reviewFieldList} 
                                                   saveReview={this.props.saveReview} 
                                                   type="product"/>
                                </Tab>
                                <Tab eventKey='deliver' title="Хүргэлт/Буцаалт">
                                    <div className="table-responsive">
                                        <h3>Хүргэлтийн ерөнхий нөхцөл</h3>
                                        <ol style={{paddingLeft: 40}}>
                                            <li>"Монгол Айдия Групп ХХК"-ий Эх үрсийн онлайн дэлгүүр нь ТэгБэ хүргэлтийн үйлчилгээ үзүүлэгч компанитай хамтарч ажиллаж байна.</li>
                                            <li>KGB компани нь найдвартай шуурхай, зориулалтын тоног төхөөрөмж, тээврийн хэрэгслээр найдвартай тээвэрлэлт бүхий ТэгБэ мэргэжлийн хүргэлтийн цогц үйлчилгээ үзүүлдэг юм.</li>
                                            <li>Хэрэглэгч захиалга хийхдээ хамгийн багадаа 10 000 төгрөг болон түүнээс дээш үнийн дүн бүхий бараа бүтээгдэхүүн захиалсан тохиолдол хүргэлт хийлгүүлэх боломжтой болно.</li>
                                            <li>Хүргэлтийн үнэ тухайн захиалгын үнийн дүнгээс хамаарч өөр байна.</li>
                                        </ol>
                                        <table className="table table-hover">
                                            <thead>
                                                <tr>
                                                    <th>Нэг захиалгын дүн</th>
                                                    <th>Хүргэлтийн төрөл/ хүргэлтийн үнэ (24 цаг)</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>10,000-49,999 төгрөг</td>
                                                    <td>5,000 төгрөг</td>
                                                </tr>
                                                <tr>
                                                    <td>50,000 төгрөгөөс дээш</td>
                                                    <td>Үнэгүй</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div className="tableFooter">
                                            Хүргэлтийн ерөнхий нөхцөлийн талаар дэлгэрэнгүй мэдээллийг <a href={config.baseUrl + '/help/101455785360313'}>Энд</a> дарж авна уу.
                                        </div>
                                        <div className="tableAttention"><b>Та уг барааг 24 цагийн дотор буцаах боломжтой.</b> Буцаалтын нөхцөлтэй танилцах бол <a href={config.baseUrl + '/help/101455785360453'}>Энд</a> дарна уу.</div>
                                    </div>
                                </Tab>
                            </Tabs>
                        </div>
                    </div>
                    {
                        product['customerBannerImageId'] ? 
                            <div className="row">
                                <div className="col-md-12 text-center">
                                    <a href={[config.baseUrl, '/shop/', product['customerId']].join('')} className="customer-banner">
                                        <img src={[config.cdnUrl, 'img/', product['customerBannerImageId']].join('')} />
                                    </a>
                                </div>
                            </div> : null
                    }
                    <div className="product-spacing">
                        {
                            product.simProducts && product.simProducts.length ?
                            <div>
                                <h2 className="highlight-red">Санал болгох Бүтээгдэхүүн</h2>
                                <div className="margin-bottom">
                                    <Slider {...options}>
                                        { this.renderCarouselItems(product.simProducts) }
                                    </Slider>
                                </div>
                            </div> : null
                        }
                        {
                            product.recProducts && product.recProducts.length ?
                            <div>
                                <h2 className="highlight-teal">Ижил төстэй Бүтээгдэхүүн</h2>
                                <div className="margin-bottom">
                                    <Slider {...options}>
                                        { this.renderCarouselItems(product.recProducts) }
                                    </Slider>
                                </div>
                            </div> : null
                        }
                    </div>
                    { this.state.showCouponModal && <CouponModal prodVarId={this.state.selected.productVarId} 
                                                        addToCart={this.addToCart.bind(this)} 
                                                        hide={this.hideCouponModal.bind(this)} 
                                                        show={this.state.showCouponModal}/> }
                    { this.state.showDiscountModal && this.renderDiscountModal() }
                </div>
            </div>
        )
    }
}

ProductDetail.displayName = 'ProductDetail';

export default ProductDetail;
