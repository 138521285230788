import React, { Component } from 'react';
import { Link } from 'react-router';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import { bindActionCreators } from 'redux';
import * as companyActions from '../actions/company';
import config from '../config';
import {formatCurrency} from '../helpers';
import PathMenu from '../components/PathMenu';

import Masonry from 'react-masonry-component';

class ShopListContainer extends Component {
    constructor(props){
        super(props);
    }
    componentWillMount() {
        this.props.loadCompanies();  
    }

    renderShops(){
        let {companies} = this.props;
        return companies.map(company => {
            let imgURL = [config.cdnUrl, 'img/', company.profilePhoto].join('');

            if(!company.profilePhoto){
                imgURL = [config.baseUrl, '/images/empty.jpg'].join('')
            }

            return (
                <div className="col-xs-6 col-sm-4 col-md-3 shop-list-item" key={company.pageId}>
                    <div className="product">
                        <Link to={{pathname: `${config.context}shop/${company.customerId}/${encodeURIComponent(company.companyOfficialName.replace(/\//g, '-'))}`}}
                            className="product-front">
                            <img src={imgURL} alt={company.companyOfficialName} />
                            <div className="product-stats text-center">
                                <span className="product-name">{company.companyOfficialName}</span>
                                {
                                    company.shortDesc && company.shortDesc.length ?
                                    <span className="product-desc">{company.shortDesc}</span> : null
                                }
                                <span className="product-price">
                                  {`${formatCurrency(company.itemCount)}`}
                                </span>
                                <span>бүтээгдэхүүн борлуулж байна</span>
                            </div>
                        </Link>
                   </div>
                </div>
            )
        })
    }

    render() {
        let options = {
          itemSelector: '.shop-list-item',
          gutter: 0,
          transitionDuration: 0
        }, paths = ['Дэлгүүр'];

        return (
            <div className="margin-bottom">
                <Helmet title='Дэлгүүр'
                    titleTemplate={config.titleTemplate} />
                
                <div className="row">
                    <div className="col-xs-12 col-md-12">
                        <PathMenu paths={paths}/>
                    </div>
                </div>
                <Masonry className="row" options={options}>
                  {this.renderShops()}
                </Masonry>
            </div>
        )
    }
}

export default connect(
    (state, props) => {
        return {
            companies: typeof state.company.companies === 'string' ? JSON.parse(state.company.companies) : state.company.companies
        } 
    },
    (dispatch) => bindActionCreators(companyActions, dispatch)
)(ShopListContainer);