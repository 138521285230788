import React, { Component, PropTypes } from 'react';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import { Modal } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import * as productActions from '../actions/product';
import * as companyActions from '../actions/company';
import {addCartItem} from '../actions/cart';
import config from '../config';
import ShopHeader from '../components/ShopHeader';
import ShopProductList from '../components/ShopProductList';
import PathMenu from '../components/PathMenu';
import ProductDetail from '../components/ProductDetail';

class ShopContainer extends Component {
    constructor(props){
        super(props);
    }

    componentDidMount() {
        let {id} = this.props.params;
        this.props.loadCompany(id);
    }

    render() {
        let title = this.props.company.companyName || '',
        paths = [{
            href: config.context + 'shop',
            text: 'Дэлгүүр'
        }, title];

        localStorage.pathName = JSON.stringify([{href: config.context + 'shop', text: 'Дэлгүүр'}, {text: title, href: this.props.location.pathname + '/' + encodeURIComponent(title.replace(/\//g, '-'))}])
        return (
            <div className="shop-container">
                <Helmet title={this.props.company.companyName || ''}
                    titleTemplate={config.titleTemplate}/>
                <ShopHeader {...this.props} />                
                <div className="row">
                    <div className="col-md-12">
                        <PathMenu paths={paths}/>
                    </div>
                </div>
                <ShopProductList {...this.props}/>
            </div>
        );
    }
}

ShopContainer.propTypes = {
    company: PropTypes.object
}

ShopContainer.defaultProps = {
    company: {}
}

export default connect(
    (state, props) => {
        return {
            company: typeof state.company.company === 'string' ? JSON.parse(state.company.company) : state.company.company,
            products: typeof state.company.products === 'string' ? JSON.parse(state.company.products) : state.company.products,
            error: typeof state.company.error === 'string' ? JSON.parse(state.company.error) : state.company.error,
        } 
    },
    (dispatch) => bindActionCreators(Object.assign({}, productActions, companyActions, {addCartItem}), dispatch)
)(ShopContainer);