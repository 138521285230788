import React, { Component } from 'react';
import config from '../../config';

class AccountInfo extends Component {
	
	constructor(props){
		super(props);
        this.state = {
           user: undefined
        }
	}

    componentDidMount(){
        this.props.loadUser();
    }

    componentWillReceiveProps(nextProps) {
        this.setState({user : nextProps.user})     
    }

	render(){
        let { user } = this.state;
        if(!user) return null;
        return (
        <div className = "info-container info-main-container">
            <div className="row">
            	<div className="col-md-8 text-left">
            		<h3>Миний мэдээлэл</h3>
            	</div>
            	<div className="col-md-4 text-right">
            		<a href="https://accounts.minu.mn">Засах</a>
            	</div>
            </div>
            <hr/>
            <div className="row">
                 <div className="col-md-8">
                      <table className="table">
                           <tbody>
                                <tr>
                                     <td>Монгол ID</td>
                                     <td>
                                          <strong>{user.mongolId}</strong>
                                     </td>
                                </tr>
                                <tr>
                                     <td>Эцэг/эх-ийн нэр</td>
                                     <td>
                                          <strong>{user.lastName}</strong>
                                     </td>
                                </tr>
                                <tr>
                                     <td>Өөрийн нэр</td>
                                     <td>
                                          <strong>{user.firstName}</strong>
                                     </td>
                                </tr>
                                <tr>
                                     <td>Бүртгэлтэй и-мэйл хаяг</td>
                                     <td>
                                          <strong>{user.email}</strong>
                                     </td>
                                </tr>
                                <tr>
                                     <td>Бүртгэлтэй гар утас</td>
                                     <td>
                                          <strong>{user.userPhoneNumber}</strong>
                                     </td>
                                </tr>
                                <tr>
                                     <td>Регистрийн дугаар</td>
                                     <td>
                                          <strong>{user.registrationNumber}</strong>
                                     </td>
                                </tr>
                                <tr>
                                     <td>Төрсөн өдөр</td>
                                     <td>
                                          <strong>{user.userBirthday}</strong>
                                     </td>
                                </tr>
                                <tr>
                                     <td>Хүйс</td>
                                     <td>
                                          <strong>{user.userGender != null &&
                                               (user.userGender == '0' ? 'Эрэгтэй' : 'Эмэгтэй')}</strong>
                                     </td>
                                </tr>
                           </tbody>
                      </table>
                 </div>
            </div>
        </div>
        )
    }
}

export default AccountInfo;
