import React, { Component } from 'react';
import {strCompare, productStatusToText, formatCurrency} from '../helpers';
import config from '../config';
import Preloader from './Preloader';

class CartItem extends Component {
    constructor(props) {
        super(props);

        this.state = {
            quantity: props.item.productQuantity.toString(),
            quantityInputMode: props.item.productQuantity > 10
        };
    }

    componentWillReceiveProps(props) {
        if (props.item) {
            this.setState({
                quantity: props.item.productQuantity.toString(),
                quantityInputMode: props.item.productQuantity > 10
            });
        }
    }

    save(quantity) {
        this.props.update(this.props.item.productVarId, quantity);
    }

    handleQuantityChange(event) {
        let rawQuantity = event.target.value;
        if (this.state.quantityInputMode) {
            let quantity = rawQuantity.replace(/[^0-9]+/g, '');
            if (quantity !== '') {
                let inNumeric = parseInt(quantity, 10);
                if (inNumeric < 1) {
                    quantity = '1';
                }
            }
            this.setState({quantity});
        } else {
            if (rawQuantity === '10+') {
                this.setState({quantityInputMode: true});
            } else {
                this.save(parseInt(rawQuantity, 10));
            }
        }
    }

    handleQuantitySubmit(event) {
        event.preventDefault();
        if (this.state.quantity) {
            this.save(parseInt(this.state.quantity, 10));
        }
    }

    /**
    * Quick view -с тоо хэмжээг өөрчлөхөд ашиглана
    */
    handleQuantityChanger(type) {
        let qty = this.state.quantity;
        if(type === 'minus') {
            qty--
            if(qty < 1)
                qty = 1            
        }
        else {
            qty++
            if(qty >= this.props.item.stock)
                qty = this.props.item.stock
        }

        this.setState({
            quantity: qty
        });

        this.save(qty);
    }

    renderDiscount(item){
        if (item.discountTypeCode === 'BCOUPON' && item.barcode != null) {
            return (
                <div className="cart-barcode">
                    <img src={config.baseUrl + '/images/cart-barcode.jpg'}/>
                    <span>{item.barcode}</span>
                </div>
            )
        } else if (item.discountTypeCode === 'SALE') {
            return (
                <div className="cart-sale">
                    <span>
                        {item.unitDiscountPercentage * item.unitPrice / 100 >= item.unitDiscountAmount
                            ? -item.unitDiscountPercentage + '%'
                            : formatCurrency(-item.unitDiscountAmount) + '₮'}
                    </span>
                </div>
            );
        }

        return null;
    }

    renderQuantityChooser(item) {
        let range = function (begin, end) {
            let range = [];
            for (let i = begin; i <= end; ++i) {
                range.push(i);
            }
            return range;
        };

        if (this.state.quantityInputMode) {
            return (
                <form onSubmit={this.handleQuantitySubmit.bind(this)}>
                    <input className="form-control" value={this.state.quantity}
                            onChange={this.handleQuantityChange.bind(this)} />
                    {this.state.quantity != item.productQuantity &&
                        <div className="">
                            <input type="submit" value="Өөрчлөх" className="btn btn-default submit-quantity" />
                        </div>}
                </form>
            );
        } else {
            return (
                <select className="form-control" value={this.state.quantity}
                        onChange={this.handleQuantityChange.bind(this)}>
                    {range(1, Math.max(1, Math.min(10, item.stock))).map((value) =>
                        <option value={value} key={value}>{value}</option>)}
                    {item.stock > 10 &&
                        <option disabled>──────────</option>}
                    {item.stock > 10 &&
                        <option value="10+" key="10+">10+</option>}
                </select>
            );
        }
    }

    render() {
        const {item, product} = this.props;

        if (item.updating || !product) {
            if(this.props.quickCart)
                return <Preloader className="sm"/>

            return (
                <tr>
                    <td colSpan="5">
                        <Preloader/>
                    </td>
                </tr>
            );
        } else {

            let discountAmount = item.unitPriceSold - item.unitPrice;

            if(this.props.quickCart)
            {
                return (
                    <li>
                        <figure>
                          <img src={config.cdnUrl + 'img/' + product.productPhoto + '?w=100'} alt={product.productName}/>
                        </figure>
                        <h4><a href={`${config.context}product/${product.productVarId}/${product.productName}`}>{product.productName}</a></h4>
                        <span className="product-price">{formatCurrency(item.unitPriceSold) + '₮'}</span>
                        <div className="input-group mini-cart-qty">
                          <span className="input-group-btn">
                            <button className="btn btn-default btn-sm" 
                                type="button"
                                onClick={this.handleQuantityChanger.bind(this, 'minus')}>
                              <i className="zmdi zmdi-minus"></i>
                            </button>
                          </span>
                          <input type="text" className="form-control text-center input-sm" value={this.state.quantity} 
                            readOnly/>
                          <span className="input-group-btn">
                            <button className="btn btn-default btn-sm" 
                                type="button"
                                onClick={this.handleQuantityChanger.bind(this, 'plus')}>
                              <i className="zmdi zmdi-plus"></i>
                            </button>
                          </span>
                        </div>
                        <div className="product-total-price">
                          = {formatCurrency(item.unitPriceSold * this.state.quantity) + '₮'}
                        </div>
                        <a href="javascript:;" className="product-delete" title="Сагснаас устгах" onClick={this.props.delete}>
                          <i className="zmdi zmdi-delete"></i>
                        </a>
                    </li>
                )
            }

            return (
                <tr className="cart-item">
                    <td className="media-container">
                        <img src={config.cdnUrl + 'img/' + product.productPhoto + '?w=150'} alt=""/>
                    </td>
                    <td className="vertical-middle">
                        <h3 className="product-name"><a href={`${config.context}product/${product.productVarId}/${product.productName}`}>{product.productName}</a></h3>
                        <div className="details-container">
                            <div className="responsive-title">Дэлгэрэнгүй</div>
                            <ul className="info">
                                <li><span className="field-name">Код:</span> {item.productCode}</li>
                            </ul>
                        </div>
                    </td>
                    <td className="vertical-middle">
                        <div className="details-container">
                            <ul className="info">
                                {item.colorName != null &&
                                <li><span className="field-name">Өнгө:</span>{item.colorName}</li>}
                                {item.sizeText != null && item.sizeText.indexOf("ширхэг") == -1 &&
                                <li><span className="field-name">Хэмжээ:</span> {item.sizeText}</li>}
                                {item.sizeText == null && item.colorName == null && item.sizeText.indexOf("ширхэг") == -1 &&
                                <li><span className="field-name">-</span></li>}
                            </ul>
                        </div>
                    </td>
                    <td className="vertical-middle">
                        <div className="item-quantity editable form-inline">
                            <div className="responsive-title">Тоо</div>
                            <div className="change clearfix">
                                { this.renderQuantityChooser(item) }
                            </div>
                        </div>
                    </td>
                    <td className="vertical-middle">
                        <div className="discount-column">
                            <div className="responsive-title">Хямдрал</div>
                            { this.renderDiscount(item) }
                        </div>
                    </td>
                    <td className="vertical-middle">
                        <div className="price-info text-right price-column">
                            <div className="responsive-title">Нийт үнэ</div>
                            <span className="product-price">{formatCurrency(item.unitPrice * item.productQuantity) + '₮'}</span>
                            {discountAmount !== 0 &&
                                <p className="discount-amount">{formatCurrency(discountAmount * item.productQuantity) + '₮'}</p>}
                            {discountAmount !== 0 &&
                                <p>{formatCurrency(item.unitPriceSold * item.productQuantity) + '₮'}</p>}
                        </div>
                    </td>
                    <td className="vertical-middle text-right">
                        <a href="javascript:;" className="product-delete" 
                           onClick={this.props.delete}>
                          <i className="zmdi zmdi-close"></i>
                        </a>
                    </td>
                </tr>
            );
        }
    }
}

export default CartItem;
