import {
    ADD_PRODUCTS,
    LOAD_PRODUCTS_SUCCESS,
    VIEW_PRODUCT_SUCCESS,
    LOAD_CATEGORIES_SUCCESS,
    SAVE_PRODUCT_SUCCESS,
    QUICK_VIEW_PRODUCT_SUCCESS,
    REQUEST_START,
    VIEW_PRODUCT_START,
    QUICK_VIEW_PRODUCT_START,
    SORT_CHANGED,
    FILTER_CHANGED,
    LOAD_PRODUCT_BRANDS_SUCCESS,
    LOAD_PRICE_FILTER_SUCCESS,
    LOAD_CATEGORY_FILTER_SUCCESS
} from '../constants/ActionTypes';

const initialState = {
    categories: [],
    products: [],
    total: 0,
    product: undefined,
    company: undefined,
    openCartOnClose: false,
    error: null,
    productCache: {},
    isQuickModal: false,
    isLoading: false
};

function product(state = initialState, action) {
    switch (action.type) {
        case SORT_CHANGED: {
            return Object.assign({}, state, {
                products: [],
                isQuickModal: false
            });
        }

        case FILTER_CHANGED: {
            return Object.assign({}, state, {
                products: [],
                isQuickModal: false
            });
        }

        case REQUEST_START: {
            return Object.assign({}, state, {
                isLoading: true,
                isQuickModal: false
            });
        }

        case VIEW_PRODUCT_START: {
            return Object.assign({}, state, {
                product: undefined,
                isQuickModal: false
            });
        }

        case QUICK_VIEW_PRODUCT_START: {
            return Object.assign({}, state, {
                productQ: undefined,
                isQuickModal: true
            });
        }

        case ADD_PRODUCTS: {
            let newState = {...state, productCache: {...state.productCache}};
            for (let product of action.products) {
                if (Object.keys(product).length > 0) {
                    newState.productCache[product.productVarId] = product;
                }
            }
            return newState;
        }

        case LOAD_PRODUCTS_SUCCESS: {
            return Object.assign({}, state, {
                products: action.products.data,
                total: action.products.recordsTotal,
                isLoading: false
            });
        }

        case LOAD_CATEGORIES_SUCCESS: {
            return Object.assign({}, state, {
                categories: action.categories
            });
        }

        case LOAD_CATEGORY_FILTER_SUCCESS: {
            return Object.assign({}, state, {
                categoryFilter: action.categoryFilter
            });
        }

        case LOAD_PRODUCT_BRANDS_SUCCESS: {
            return Object.assign({}, state, {
                brands: action.brands
            });
        }

        case LOAD_PRICE_FILTER_SUCCESS: {
            return Object.assign({}, state, {
                prices: action.prices
            });
        }

        case VIEW_PRODUCT_SUCCESS: {
            return Object.assign({}, state, {
                product: action.product,
                isQuickModal: false
            });
        }  

        case QUICK_VIEW_PRODUCT_SUCCESS: {
            return Object.assign({}, state, {
                productQ: action.product,
                isQuickModal: true
            });
        }        

        case SAVE_PRODUCT_SUCCESS:
            if (state.productCache.hasOwnProperty(action.productVarId)) {
                return {
                    ...state,
                    productCache: {
                        ...state.productCache,
                        [action.productVarId]: {
                            ...state.productCache[action.productVarId],
                            isSaved: action.isFavorite
                        }
                    }
                };
            } else {
                return state;
            }

        default:
            return state;
    }
}

export default product;
