import React, {Component} from 'react';
import config from '../config';

class BankChooser extends Component {
    render() {
        const {banks, onChange, value} = this.props;

        let getImplementedBanks = function () {
            let implementedBanks = ['KHN', 'STB', 'TDB'];
            if (window.baby.userId === '101445929089566') {
                implementedBanks.push('RBS');
            }
            return banks.filter(bank => implementedBanks.indexOf(bank.bankCode) !== -1);
        };

        return (
            <div className="bank-chooser clearfix">
                <label className="control-label" style={{marginRight: '10px'}}>Банк сонгох:</label>
                <ul>
                    {getImplementedBanks().map(bank =>
                        <li onClick={() => onChange(bank.bankId)} key={bank.bankId} title={bank.bankName}
                            className={value === bank.bankId && 'active'} >
                            <img src={config.baseUrl + '/images/bank/' + bank.bankCode.toLowerCase() + '.png'}
                                 alt={bank.bankName} />
                            <div className="bank-name">{bank.bankName}</div>
                        </li>)}
                </ul>
            </div>
        );
    }
}

export default BankChooser;
