import React, { Component, PropTypes } from 'react';
import ReactDOM from 'react-dom';
import toastr from 'toastr';
import config from '../config';
import {uniqueArray} from '../helpers';
import Product from './Product';
import Category from './Category';
import Preloader from './Preloader';
import Masonry from 'react-masonry-component';
let InfiniteScroll = require('./react-infinite-scroll')(React, ReactDOM);

class ShopProductList extends Component {
    constructor(props) {
        super(props);
        this.state = {
          companyId: undefined,
          products: [],
          page: 0,
          hasMore: true,
          sort: {
              field: undefined,
              dir: ''
          }
        }
    }

    componentDidMount() {
      this.setState({
        companyId: this.props.params.id
      })
    }

    fetchProducts(page, fetchCount, callback){        
        this.props.loadCompanyProducts(page - 1, fetchCount, this.state.companyId, this.state.sort, callback, this.onError.bind(this));
    }

    loadMoreProducts(page){
        this.fetchProducts(page, this.props.fetchCount, this.onCallback.bind(this));
    }

    onCallback(products, page){
        this.setState({
            page: page + 1,
            products: uniqueArray(this.state.products.concat(products), 'prodVarId'),
            hasMore: products.length === this.props.fetchCount
        })
    }

    onError(){
      // toastr.error('Бүтээгдэхүүний мэдээллийг унших үед алдаа гарлаа!');
      this.setState({
          hasMore: false
      })
    }
    
    renderProducts(){
       return this.state.products.map(product => 
          <div className="col-xs-6 col-sm-4 col-md-4 product-list-item" key={product.id}>
            <Product product={product} 
              saveProduct={this.props.saveProduct} 
              viewProduct={this.props.quickViewProduct}
              checkIsLogged={this.props.checkIsLogged} 
              addCartItem={this.props.addCartItem}
              returnTo={this.props.location.pathname}/>
          </div>)
    }

    renderLoaderElement(){
        return <Preloader/>
    }

    renderCategories() {
        return (this.props.company.categories || []).map((category, i) => (
            <li key={category}>
                <a href="javascript:;">
                    {category.split(':')[0]}
                </a>
            </li>
        ))
    }

    render(){
        let options = {
          itemSelector: '.product-list-item',
          gutter: 0,
          transitionDuration: 0
        };

        return (
          <div className="margin-top row">
            <div className="col-md-3">
              <div className="app-side-filter">
                <ul>
                    {this.renderCategories()}
                </ul>
              </div>
            </div>
            <div className="col-md-9">
              <InfiniteScroll 
                  ref="infiniteScroll"
                  pageStart={this.state.page - 1} 
                  loadMore={this.loadMoreProducts.bind(this)}
                  hasMore={this.state.hasMore}
                  loader={this.renderLoaderElement()}>
                  <Masonry className="row" options={options}>
                    {this.renderProducts()}
                  </Masonry>
              </InfiniteScroll>
            </div>
          </div>
        )
    }
}

ShopProductList.displayName = "ShopProductList";
ShopProductList.propTypes = {
    fetchCount: PropTypes.number
}

ShopProductList.defaultProps = {
    fetchCount: 9
}

export default ShopProductList;