var React = require('react');
import CartItemContainer from '../../containers/CartItemContainer';
import {formatCurrency} from '../../helpers';
import toastr from 'toastr';

var Cart = React.createClass({
    render: function() {
        return (
            <div className="cart-table">
                <h3>1. Захиалга</h3>
                <table className="table">
                    <thead>
                    <tr>
                        <th colSpan="2" className="text-center">Бүтээгдэхүүн</th>
                        <th className="text-center">Өнгө/Хэмжээ</th>
                        <th style={{width: 200}}>Тоо</th>
                        <th>Хямдрал</th>
                        <th className="text-right" style={{width: 120}}>Нийт үнэ</th>
                        <th style={{width: 70}}></th>
                    </tr>
                    </thead>
                    <tbody>
                    { this.renderCartItems(this.props.cart) }
                    { this.renderCartTotal(this.props.cart) }
                    </tbody>
                </table>
                <div className="col-md-12 margin-bottom text-center">
                    <button type="submit" className="btn btn-primary" onClick={this.nextStep}>
                        Үргэлжлүүлэх
                    </button>
                </div>
            </div>
        )
    },

    renderCartItems: function(cart) {
        if(!cart.items.length)
            return (
                <tr className="no-items">
                    <td colSpan="5" className="text-center">
                        <h5>Таны сагс хоосон байна</h5>
                    </td>
                </tr>
            )
        return cart.items.map((item, index) => <CartItemContainer key={item.orderDtlId} item={item} />)
    },

    renderCartTotal: function(cart) {
        if(!cart.items.length)
            return false;
        return (
            <tr className="items-total">
                <td colSpan="5" className="text-right">
                    <b>Нийт:</b>
                </td>
                <td>
                    <div className="price-info text-right price-column">
                        <span className="product-price">{formatCurrency(cart.totalPayAmount) + '₮'}</span>
                    </div>
                </td>
                <td>
                    &nbsp;
                </td>
            </tr>
        )
    },

    nextStep: function(e) {
        e.preventDefault();

        if(typeof this.props.cart.ordersId != 'undefined'
            && this.props.cart.ordersId != '') {
            this.props.nextStep(e)
        } else {
            toastr.info('Та худалдан авах бараагаа сагсанд хийнэ үү.', null, {"positionClass": "toast-top-center"});
        }
    }
})

module.exports = Cart