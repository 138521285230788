import React, { Component } from 'react';
import ReactDOM from "react-dom";
import Slider from "bootstrap-slider";
import es6BindAll from "es6bindall";
import config from '../config';

class PriceFilter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            prices: null,
            step: 100,
            value: [0,1],
            categoryId: props.params.id
        };

        let queryParams = this.props.location.query;
        if (typeof queryParams.lp != 'undefined') {
            this.state.value[0] = queryParams.lp;
        }
        if (typeof queryParams.hp != 'undefined') {
            this.state.value[1] = queryParams.hp;
        }

        es6BindAll(this, ["updateSliderValues"]);
    }

    componentDidMount(){
        if(this.state.categoryId) {
            this.props.loadPriceFilter(this.state.categoryId, this.props.params.query);
        }
    }

    componentDidUpdate() {
        if(this.state.prices != null && this.state.prices.min != null) {
            this.updateSliderValues();
        }
    }

    componentWillReceiveProps(nextProps){
        let nextState = {
            prices: nextProps.prices,
            categoryId: nextProps.params.id
        };

        let queryParams = this.props.location.query;
        if (typeof queryParams.brand_id != 'undefined') {
            nextState.brandId = queryParams.brand_id;
        }
        if(typeof nextProps.prices != 'undefined' && nextProps.prices != null) {
            nextState.value = [parseInt(nextProps.prices.min), parseInt(nextProps.prices.max)];

            if (typeof queryParams.lp != 'undefined') {
                nextState.value[0] = parseInt(queryParams.lp);
            }
            if (typeof queryParams.hp != 'undefined') {
                nextState.value[1] = parseInt(queryParams.hp);
            }
        }

        this.setState(nextState);
    }

    shouldComponentUpdate(nextProps, nextState){
        return this.props.prices != nextProps.prices;
    }

    updateSliderValues() {

        if( typeof this.mySlider == 'undefined' || this.mySlider == null) {
            var that = this;
            this.mySlider = new Slider(ReactDOM.findDOMNode(this.refs.priceFilter), {
                "tooltip": "always",
                "tooltip_split": true,
                "range": true
            });
            this.updateSliderValues();
            this.mySlider.on("slideStop", function (e) {
                var fakeEvent = {
                    target: {}
                };
                fakeEvent.target.value = e;
                that.props.handleChange(fakeEvent);
            });
        }

        this.mySlider.setAttribute("min", this.state.prices.min);
        this.mySlider.setAttribute("max", this.state.prices.max);
        this.mySlider.setAttribute("step", this.state.step);
        this.mySlider.setValue(this.state.value);

        var currentlyEnabled = this.mySlider.isEnabled();
        if (!currentlyEnabled) {
            this.mySlider.enable();
        }
    }

    renderPriceFilters() {
        let path = this.props.location.pathname.indexOf('/search/') == 0
            ? 'search/:id/' + this.props.params.query
            : 'category/:id';
        if (this.props.location.pathname.indexOf('/search/') == 0) {
            path = path.replace(':id', this.props.params.id );
        }
        else {
            if (this.props.params.id === 'all') {
                // all category
                path = path.replace(':id', 'all');
            } else {
                // single category
                if(typeof this.props.categoryFilter != 'undefined' && this.props.categoryFilter.length > 0) {
                    var currentCategory = null;
                    this.props.categoryFilter.forEach(parent => {
                        if(parent.id === this.state.categoryId) {
                            currentCategory = parent;
                        }
                    });
                    if (typeof currentCategory != 'undefined' && currentCategory != null) {
                        path = path.replace(':id', currentCategory.id) + '/' + currentCategory.name;
                    } else {
                        path = path.replace(':id', this.props.params.id );
                    }
                }
            }
        }
        let queryParams = this.props.location.query;

        let href = config.context + path;

        return <input ref="priceFilter"/>;
    }

    render(){
        if (typeof this.state.prices != 'undefined' && this.state.prices != null && this.state.prices.min != null) {
            return (
                <div className="app-side-filter">
                    <h4>Үнэ</h4>
                    <div className="col-sm-10 col-sm-push-1 price-filter">
                        {this.renderPriceFilters()}
                    </div>
                </div>
            )
        }

        return false;
    }
}

export default PriceFilter;