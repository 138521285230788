import config from '../config';
import * as ActionTypes from '../constants/ActionTypes';
import { httpRequest } from '../helpers';

export function loadCompany(id) {
    return dispatch => {
        httpRequest({
            url: config.baseUrl + '/api/shop/' + id,
            method: 'GET'
        }).then(function (company) {
            dispatch({type: ActionTypes.LOAD_COMPANY_SUCCESS, company: company});
        }, function (error) {
            dispatch({type: ActionTypes.LOAD_COMPANY_ERROR, error: error});
        });
    };
}

export function loadCompanyProducts(page = 0, fetchCount = 10, id, sortField, callback, onError) {
    let requestURL = config.baseUrl + '/api/shop/products/' + id,
        data = {
            page: page,
            fetchCount: fetchCount
        };

    if(sortField && sortField.hasOwnProperty('field')){
        data = Object.assign({}, data, {
            sortField: sortField.field,
            sortDirection: sortField.dir
        })     
    }
    return dispatch => {
        if(!id) return;

        httpRequest({
            url: requestURL,
            method: 'GET',
            data: data
        }).then(function (products) {
            dispatch({type: ActionTypes.LOAD_COMPANY_PRODUCTS_SUCCESS, products: products});
            callback && callback(products, page);  
        },function (error) {
            dispatch({type: ActionTypes.LOAD_COMPANY_PRODUCTS_ERROR, error: error});
            onError && onError()
        });
    };
}

export function loadCompanies() {
    return dispatch => {
        httpRequest({
            url: config.baseUrl + '/api/shops',
            method: 'GET'
        }).then(function (companies) {
            dispatch({type: ActionTypes.LOAD_COMPANYS_SUCCESS, companies: companies});
        },function (error) {
            dispatch({type: ActionTypes.LOAD_COMPANYS_ERROR, error: error});
        });
    };
}

export function loadBrands() {
    return dispatch => {
        httpRequest({
            url: config.baseUrl + '/api/brands',
            method: 'GET'
        }).then(function (brands) {
            dispatch({type: ActionTypes.LOAD_BRANDS_SUCCESS, brands: brands});
        }, function (error) {
            dispatch({type: ActionTypes.LOAD_BRANDS_ERROR, error: error});
        });
    };
}

export function loadBrandProducts(page = 0, fetchCount = 10, id, sortField, callback) {
    let requestURL = config.baseUrl + '/api/brand/products/' + id,
        data = {
            page: page,
            fetchCount: fetchCount
        };

    if(sortField && sortField.hasOwnProperty('field')){
        data = Object.assign({}, data, {
            sortField: sortField.field,
            sortDirection: sortField.dir
        })     
    }

    return dispatch => {
        dispatch({type: ActionTypes.LOAD_BRAND_PRODUCTS});
        httpRequest({
            url: requestURL,
            method: 'GET',
            data: data
        }).then(function (products) {
            dispatch({type: ActionTypes.LOAD_BRAND_PRODUCTS_SUCCESS, products: products});
            callback && callback(page);
        }, function (error) {
            dispatch({type: ActionTypes.LOAD_BRAND_PRODUCTS_ERROR, error: error});
        });
    };
}

export function brandSortChanged() {    
    return dispatch => {
        dispatch({type: ActionTypes.BRAND_SORT_CHANGED});
    };
}

export function brandChanged() {    
    return dispatch => {
        dispatch({type: ActionTypes.BRAND_CHANGED});
    };
}