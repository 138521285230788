import React, { Component } from 'react';
import { Link } from 'react-router';
import { connect } from 'react-redux';
import Slider from 'react-slick';
import Helmet from 'react-helmet';
import { bindActionCreators } from 'redux';
import config from '../config';

import * as btagActions from '../actions/btag';
import {addCartItem} from '../actions/cart';
import * as productActions from '../actions/product';
import * as bannerActions from '../actions/banner';

import Product from '../components/Product';
import ProductList from '../components/ProductList';

let defaultOptions = {
    arrows: true,
    dots: false,
    infinite: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    pauseOnHover: true,
    swipe: true,
    swipeToSlide: true,
    touchMove: true,
};

class HomeContainer extends Component {
    constructor(props){
        super(props);

        // this.state = {
            // btags: []
        // }
    }

    componentDidMount(){
        // this.props.loadBtags();
        // this.props.loadBtagSpecials();
        this.props.loadBanners('BABY-A1');
        this.props.loadHomepageBrandWeekBanners();
        this.props.loadHomepageCategorizedBanners();
    }

    componentWillReceiveProps(nextProps){
        // this.setState({
        //     btags: nextProps.btags
        // })
    }

    renderSlide(){
        let options = Object.assign({}, defaultOptions, {slidesToShow: 1, dots: true, autoplay: true, autoplaySpeed: 7000, infinite: true})
        return (
            <Slider {...options} className="main-slide margin-bottom">
                {
                    this.props.banners.map(banner => (
                        <div className="slide-item" key={banner.bannerId}>
                            <a href={banner.bannerUrl && banner.bannerUrl.length ? banner.bannerUrl : 'javascript:;'}>
                                <img src={`${config.cdnUrl}img/${banner.fileId}`}/>
                            </a>
                        </div>
                    ))
                }
            </Slider>
        )
    }

    renderCategorizedBanners(){
        return (
            <section className="cb-container">
                {
                    this.props.categorizedBanners.map(category => (
                        category.name != 'Хямдралтай брэндүүд' ?
                        <section className="cb-category col-md-12" key={category.id}>
                            <div className="cb-category-header">
                                <p className="cb-category-title">{category.name}</p>
                            </div>
                            <div className="cb-banners row">
                                {
                                    category.banners.map(banner => (
                                        <article key={banner.id} className="col-md-4 col-sm-4 col-xs-12 cb-banner">
                                            <a href={config.context + 'category/' + banner.id + '/' + banner.name}>
                                                <div className="cb-banner-detail">
                                                    <p className="cb-banner-name">{banner.name}</p>
                                                </div>
                                                <figure>
                                                    <img src={config.cdnUrl+'/img/'+banner.photo+"?w=300&h=300&c=true"}
                                                               alt={banner.name} className="cb-image"/>
                                                </figure>
                                            </a>
                                        </article>
                                    ))
                                }
                            </div>
                        </section>
                            : null
                    ))
                }
            </section>
        )
    }

    renderBrandWeekBanners(){
        return (
            <section className="cb-container">
                {
                    this.props.brandWeekBanners.map(category => (
                        <section className="cb-category col-md-12" key={category.id}>
                            <div className="cb-category-header">
                                <p className="cb-category-title">{category.name}</p>
                            </div>
                            <div className="cb-banners row">
                                {
                                    category.banners.map(banner => (
                                        <article key={banner.id} className="col-md-4 col-sm-4 col-xs-12 cb-banner">
                                            <a href={ banner.is_active ? config.context + 'brandweek/201463043560904/' + banner.name + '?brand_id=' + banner.id : "javascript:;"}
                                                className={banner.is_active ? '' : 'disabled'}>
                                                {
                                                    banner.is_active ? '' : <span className="disabled"></span>
                                                }
                                                <div className="cb-banner-detail">
                                                    <p className="cb-banner-name">{banner.name}</p>
                                                    <p className="cb-banner-info">{banner.start_date} - {banner.end_date}</p>
                                                </div>
                                                <figure>
                                                    <img src={config.cdnUrl+'/img/'+banner.photo+"?w=300&h=300&c=true"}
                                                               alt={banner.name} className="cb-image"/>
                                                </figure>
                                            </a>
                                        </article>
                                    ))
                                }
                            </div>
                        </section>
                    ))
                }
            </section>
        )
    }

    renderProductList(){
        return (
            <section className="highlight-btag margin-bottom">
                <div className="hp-category-header">
                    <p className="hp-category-title">Бүх бүтээгдэхүүн</p>
                </div>
                <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12">
                        <ProductList {...this.props}
                            sort={false}
                            showBanner={false}
                            fetchCount={36}
                            column={"col-xs-6 col-sm-4 col-md-3"}/>
                    </div>
                </div>
            </section>
        )
    }

    /*renderTopBtags(){
        let top3 = this.state.btags.slice(0, 3);
        return (
            <section className="highlight-btag margin-bottom">
                <div className="row">
                    {
                        top3.map(btag => (
                            <div className="col-xs-12 col-sm-4 col-md-4 margin-bottom"
                                key={btag.btagId}>
                                <Link to={{pathname: `${config.context}btag/products/${btag.btagId}/${encodeURIComponent(btag['btag'].replace(/\//g, '-'))}`}}>
                                    <img src={`${config.cdnUrl}img/${btag['btagImageId']}`} alt={btag['btag']}/>
                                </Link>
                            </div>
                        ))
                    }
                </div>
            </section>
        )
    }*/

    /*renderBtags(){
        let options = Object.assign({}, defaultOptions, {slidesToShow: 4}),
            btags = this.state.btags.slice(3);

            options.responsive = [
                {
                  breakpoint: 940,
                  settings: {
                    slidesToShow: 2
                  }
                },
                {
                  breakpoint: 768,
                  settings: {
                    slidesToShow: 2,
                    arrows: false
                  }
                },
                {
                  breakpoint: 480,
                  settings: {
                    slidesToShow: 1,
                    arrows: false
                  }
                }
              ]
        return (
            <Slider {...options} className="btag-slide margin-bottom">
                {
                    btags.map(btag => (
                        <div className="slide-item" key={btag.btagId}>
                            <Link to={{pathname: `${config.context}btag/products/${btag.btagId}/${encodeURIComponent(btag['btag'].replace(/\//g, '-'))}`}}>
                                <img src={`${config.cdnUrl}img/${btag['btagImageId']}`} alt={btag['btag']}/>
                            </Link>
                        </div>
                    ))
                }
            </Slider>
        )
    }*/

    /*renderProducts(products){
        let options = {
            arrows: true,
            dots: false,
            infinite: false,
            slidesToShow: 3,
            slidesToScroll: 1,
            className: 'product-carousel',
            responsive : [
                {
                  breakpoint: 940,
                  settings: {
                    slidesToShow: 2
                  }
                },
                {
                  breakpoint: 768,
                  settings: {
                    slidesToShow: 2,
                    arrows: false
                  }
                },
                {
                  breakpoint: 480,
                  settings: {
                    slidesToShow: 1,
                    arrows: false
                  }
                }
            ]
        };
        return (
            <Slider {...options}>
                { 
                    products.map(product => 
                      <div className="col-xs-6 col-sm-6 col-md-4" key={product.id}>
                        <Product product={product} 
                          saveProduct={this.props.saveProduct} 
                          checkIsLogged={this.props.checkIsLogged} 
                          addCartItem={this.props.addCartItem}
                          returnTo={this.props.location.pathname}/>
                      </div>
                  ) 
                }
            </Slider>
        )
    }*/

    /*renderSpecails(){
        return (
            <section className="margin-bottom special-products">
                {
                    this.props.specials.map(btag => (
                        <div key={btag.id}>
                            <h1>{btag.name}</h1>
                            {
                                btag.desc && btag.desc.length ?
                                <h4>{btag.desc}</h4> : null                                
                            }
                            { this.renderProducts(btag.productList) }
                        </div>
                    ))
                }
            </section>
        )
    }*/

    render() {
        let title = 'Нүүр хуудас';
        
        return (
            <div className="margin-bottom">
                <Helmet title={title}
                    titleTemplate={config.titleTemplate}/>
                { this.props.banners.length ? this.renderSlide() : null }
                { typeof this.props.brandWeekBanners != 'undefined' && this.props.brandWeekBanners.length ? this.renderBrandWeekBanners() : null }
                { typeof this.props.categorizedBanners != 'undefined' && this.props.categorizedBanners.length ? this.renderCategorizedBanners() : null }
                { this.renderProductList() }
                { /*this.state.btags.length > 3 && this.renderBtags()*/ }
                { /*this.props.specials.length ? this.renderSpecails() : null*/ }
            </div>
        )
    }
}

export default connect(
    (state, props) => {
        return {
            /*btags: typeof state.btag.btags === 'string' ? JSON.parse(state.btag.btags) : state.btag.btags,*/
            products: typeof state.products.products === 'string' ? JSON.parse(state.products.products) : state.products.products,
            /*specials: typeof state.btag.specials === 'string' ? JSON.parse(state.btag.specials) : state.btag.specials,*/
            banners: typeof state.banner.banners === 'string' ? JSON.parse(state.banner.banners) : state.banner.banners,
            brandWeekBanners: typeof state.banner.brandWeekBanners === 'string' ? JSON.parse(state.banner.brandWeekBanners) : state.banner.brandWeekBanners,
            categorizedBanners: typeof state.banner.categorizedBanners === 'string' ? JSON.parse(state.banner.categorizedBanners) : state.banner.categorizedBanners,
            params: {id:'homepage',query:null},
            isLoading: state.products.isLoading
        } 
    },
    (dispatch) => bindActionCreators(Object.assign({}, productActions, bannerActions, { addCartItem }), dispatch)
)(HomeContainer);