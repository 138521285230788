import React, {Component} from 'react';
import Leaflet from 'leaflet';
import config from '../config';
import {httpRequest} from '../helpers';

class AddressChooser extends Component {
    componentDidMount() {
        this.map = Leaflet.map(this.props.id, {
            center: [47.918845, 106.917578],
            zoom: 15,
            maxBounds: L.latLngBounds([47.755021, 106.565666] , [47.995895, 107.229652]),
            layers: [
                L.tileLayer.wms('https://api.minu.mn/wms', {
                    layers: 'mongolid:mimap',
                    format: 'image/png',
                    transparent: true
                })
            ],
            zoomControl: true,
            attributionControl: false
        });

        let lastRequest;
        this.map.on('click', (e) => {
            let requestId = {};
            lastRequest = requestId;

            this.setMarker(e.latlng.lat, e.latlng.lng, 'Түр хүлээнэ үү&hellip;');

            httpRequest({
                url: config.baseUrl + '/api/cart/get-point-info',
                method: 'GET',
                data: {
                    lat: e.latlng.lng,
                    lng: e.latlng.lat
                }
            })
                .then((data) => {
                    if (requestId !== lastRequest) {
                        return;
                    }

                    let building = null;
                    if (data.building) {
                        building = {
                            lat: e.latlng.lat,
                            lng: e.latlng.lng,
                            miCode: data.building.micode,
                            buildingId: data.building.buildingId,
                            districtId: data.building.districtId,
                            khorooId: data.building.khorooId,
                            address: data.building.companyOfficialAddress,
                            delivery: {
                                scopeName: undefined,
                                scopeId: undefined,
                                prices: []
                            }
                        };

                        // Hope that the first element is always there
                        building.delivery.scopeName = data.deliveryPrice[0].deliveryScopeName;
                        building.delivery.scopeId = data.deliveryPrice[0].deliveryScopeId;

                        for (let tmp of data.deliveryPrice) {
                            building.delivery.prices.push({
                                deliveryTypeId: tmp.deliveryTypeId,
                                startPrice: tmp.startPrice,
                                endPrice: tmp.endPrice,
                                price: tmp.price
                            });
                        }
                    } else {
                        this.setMarker(e.latlng.lat, e.latlng.lng, '<strong>Бүртгэлтэй барилга олдсонгүй!</strong>');
                    }

                    if (this.map && this.props.buildingChosen) {
                        this.props.buildingChosen(building);
                    }
                });
        });

        if (this.props.building) {
            this.setMarker(this.props.building.lat, this.props.building.lng, this.props.building.address);
        } else {
            this.setMarker(
                47.918845,
                106.917578,
                '<em style="text-align: center">Та захиалга хүргүүлэх гэж байгаа барилга дээрээ дарж идэвхижүүлнэ үү.</em>',
                {
                    icon: L.icon({
                        iconUrl: config.baseUrl + '/images/hand-click.png',
                        iconSize: [50, 50],
                        iconAnchor: [22, 14],
                        popupAnchor: [0, -10]
                    })
                }
            );
        }
    }

    componentWillUnmount() {
        this.setMarker();
        this.map.remove();
        this.map = null;
    }

    componentWillReceiveProps(props) {
        const building = props.building;
        if (building && this.props.building !== building) {
            this.setMarker(building.lat, building.lng, building.address);
        }
    }

    setMarker(lat, lng, html, options) {
        if(lat != null && lng != null && !isNaN(lat) && !isNaN(lng) && lat && lng) {
            if (this.marker) {
                this.map.removeLayer(this.marker);
                this.marker = null;
            }
            if (lat != null && lng != null && !isNaN(lat) && !isNaN(lng)) {
                this.marker = Leaflet.marker([lat, lng], options)
                    .addTo(this.map)
                    .bindPopup(html)
                    .openPopup();
                this.map.setView([lat, lng]);
            }
        }
    }

    render() {
        const {id} = this.props;

        return (
            <div id={id}></div>
        );
    }
}

export default AddressChooser;
