import React, {Component} from 'react';
import toastr from 'toastr';
import { Modal } from 'react-bootstrap';
import {httpRequest, formatCurrency} from '../helpers';
import config from '../config';

class CouponModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            barcode: undefined,
            percent: 0,
            amount: 0,
            isReady: false
        };
    }

    componentDidMount() {
        httpRequest({
            url: config.baseUrl + '/api/cart/generate-coupon',
            method: 'POST',
            data: {
                prodVarId: this.props.prodVarId
            }
        }).then((data) => {
            this.setState({barcode: data.generatedCode, amount: data.amount, percent: data.percent, isReady: true});
        }, function () {
            toastr.error('Coupon үүсгэж чадсангүй.');
        })
    }

    componentWillUnmount() {
        this.setState({
            barcode: undefined
        })
    }

    addToCart(event) {
        event.preventDefault();

        this.props.addToCart(this.state.barcode);
        this.props.hide();
    }

    render() {
        return (<Modal show={this.props.show}
                    onHide={this.props.hide}
                    className="customModal over-zoom">
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <div className="modal-body minu-coupon-header">
                        <h1>[  <span className="light-text">Хайлтын шинэ хувьсгал</span>  ] B-Coupon</h1>
                    </div>
                    {
                        this.state.isReady ? 
                        <div className="modal-body minu-coupon-body has-info-mark">
                            <div className="row">
                                <div className="col-md-9">
                                    Та энэхүү сонгосон бараагаа <span className="minu-coupon-highlight">{`${formatCurrency(this.state.percent, 0)}%`}</span> хувийн хөнгөлөлттэйгээр 
                                    <span className="minu-coupon-highlight">{formatCurrency(this.state.amount)}</span>
                                    төгрөгөөр худалдан авахыг хүсвэл САГСАНД нэмэх товчийг дарна уу. Таны бүртгэлтэй мэйл хаяг руу мөн бүртгэлтэй гар утасны дугаар руу B-Coupon илгээх бөгөөд Та дэлгүүрт хүрэлцэн очиж B-Coupon
                                    үзүүлснээр бараагаа хүлээн авна.
                                </div>
                                <div className="col-md-3 text-center">
                                    <span className="barcode"></span>
                                    <span className="minu-coupon-highlight">{this.state.barcode ? this.state.barcode : '...'}</span>
                                    <button className="addcart" disabled={this.state.barcode === undefined} onClick={this.addToCart.bind(this)}>
                                        <span className="fa fa-shopping-cart"></span> Сагсанд нэмэх</button>
                                </div>
                            </div>
                        </div> :
                        <div className="minu-loading">Уншиж байна... <span className="fa fa-spin fa-circle-o-notch"></span></div>
                    }
                </Modal.Body>
            </Modal>
        );
    }
}

export default CouponModal;
