import React, { Component } from 'react';
import { Link } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../actions/btag';
import config from '../config';

class BtagListContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            btags: []
        }
    }

    componentWillReceiveProps(nextProps){
        let nextState = {
                btags: nextProps.btags
            }
        this.setState(nextState);
    }

    componentWillMount() {
        this.props.loadBtags();
    }

    componentDidUpdate(){
        let options = {
            'cell_height': 200,
            'vertical_margin': 15,
            'static_grid' : true,
            float: true
        };
        
        $('.grid-stack').gridstack(options);
    }

    renderItem(btag){
        let imgURL = [config.cdnUrl, 'img/', btag['btagImageId']].join('');

        return <div key={btag.btagId} className="grid-stack-item" data-gs-x={btag['x']} data-gs-y={btag['y']} data-gs-width={btag['width']} data-gs-height={btag['height']}>
                    <Link to={{pathname: [config.context, 'btag/products/', btag.btagId].join(''), query: {name : btag['btag']} }} className="tile-container">
                        <img src={imgURL} alt={btag['btag']}/>
                    </Link>
                </div>
    }

    render() {
        return (
            <div>
                <div className="grid-stack grid-stack-12" ref="gridstack">
                    {
                        this.state.btags.map(this.renderItem)
                    }
                </div>
            </div>
        );
    }
}

export default connect(
    (state, props) => {
        return {
            btags: typeof state.btag.btags === 'string' ? JSON.parse(state.btag.btags) : state.btag.btags,
            products: typeof state.btag.products === 'string' ? JSON.parse(state.btag.products) : state.btag.products,
            product: typeof state.btag.product === 'string' ? JSON.parse(state.btag.product) : state.btag.product,
            isViewProduct: state.btag.isViewProduct,
        } 
    },
    (dispatch) => bindActionCreators(actions, dispatch)
)(BtagListContainer);